export default {
    "home": {
        "sec1": {
            "title": "Gelirinizi artırın. Zahmetsizce.",
            "cta": "Ücretsiz indir",
            "col1": {
                "title": "senin artırmak <br\/> gelir",
                "desc": "feed yapınızı optimize edin ve gelirinizi% 5-30 artırın"
            },
            "col2": {
                "title": "yeni satışlar açın <br\/> kanallar",
                "desc": "yeni satış kanallarıyla daha fazla satış yapın"
            },
            "col3": {
                "title": "<br\/> zaman sınırı olmadan ücretsiz dene",
                "desc": "fiyatlandırma yöntemi olarak kullandığımız ödememiz size piyasadaki en esnek çözümü sunar"
            },
            "col4": {
                "title": "senin kaydet <br\/> zaman",
                "desc": "danışmanlarımız ürün özet akışlarınızı oluşturur ve optimize eder"
            }
        },
        "sec2": {
            "title": "kolayca ithalat ve ihracat",
            "col1": {
                "title": "Tedarikçi feed'i <br\/> Yönetimi",
                "desc": "Feed Studio, farklı ürün özet akışlarını tek bir içe aktarılabilir özet akışında birleştirir"
            },
            "col2": {
                "title": "100'den fazla kullanmak <br\/> diğer biçimlere",
                "desc": "Farklı Google Alışveriş ve Instangram tanımlarına mı ihtiyacınız var? Problem değil"
            },
            "col3": {
                "title": "Ürününüzü platforma göre optimize edin <br\/>",
                "desc": "Instagram mağazasından biraz farklı Google alışveriş açıklamasına mı ihtiyacınız var? Sorun değil"
            },
            "col4": {
                "title": "Tarihi değiştir",
                "desc": "Neden yanlış fiyat reklamı yapıyorsunuz? Ürün güncellemeniz ne zaman başarısız oldu? Cevabı biliyoruz!"
            },
            "col5": {
                "title": "SEO optimizasyonu",
                "desc": "Otomatik SEO meta etiketi oluşturma ve optimizasyonu."
            },
            "col6": {
                "title": "yönetilen hizmet",
                "desc": "Feed Studio, farklı ürün özet akışlarını tek bir içe aktarılabilir özet akışında birleştirir"
            }
        },
        "sec3": {
            "title": "Mücadele etmeden işinizi büyütmek mi istiyorsunuz?"
        },
        "sec4": {
            "item2": {
                "subtitle": "Yem Yönetimi"
            },
            "item4": {
                "subtitle": "Arama konsolu"
            }
        },
        "sec5": {
            "title": "Güzel otomatik Google Ads kampanyaları",
            "col1": {
                "title": "Marka Reklamları",
                "desc": "Müşterilerinizin% 40'ının arama sonucu sayfasında sitenizi tıklamadığını biliyor muydunuz? Marka kampanyalarıyla onlara ulaşın!"
            },
            "col2": {
                "title": "Dinamik Arama Ağı Reklamları"
            },
            "col3": {
                "title": "Alışveriş Reklamları"
            },
            "col4": {
                "title": "Görüntülü Reklamlar",
                "desc": "Duyarlı yeniden pazarlama, dinamik yeniden pazarlama ve akıllı reklamlarla Google Görüntülü Reklam Ağı'ndaki hedef kitlenize ulaşın."
            }
        },
        "sec6": {
            "title": "Reklamınızı otomatikleştirmeye hazır mısınız?",
            "cta": "Ücretsiz edin"
        },
        "sec7": {
            "title": "",
            "subtitle": "Ücretsiz planda bile kapsamlı hizmetler"
        },
        "premier-partner-desc": "Google tarafından, en iyi performans gösteren bir ajans olarak ayırt edildiğimiz için Google Ads stratejinizin emin ellerde olduğundan emin olabilirsiniz.",
        "sec8": {
            "title": "",
            "cta": "ÜCRETSİZ İNDİR"
        },
        "main": {
            "title": "<b> BESLEME <\/b> .ME.BABY",
            "description": "Son google yem denemek için erken bir kuş hesap isteğinde <br\/> profesyoneller ve ajanslar için tasarlanmış yöneticisi yazılım. <br\/> Sınırlı bir süre için <s> 300 $ <\/s> 1.000 $ karşılama kredisi alacaksınız.",
            "button": "1.000 $ kredi kazanın"
        },
        "cta1": {
            "title": "ERKEN KUŞ TANITIMI",
            "description": "ilk kullanıcılarımız Feed Studio'yu test etmek için 1000 dolar karşılama kredisi alıyor",
            "button": "1.000 $ KREDİ AL",
            "subtitle": "KREDİ KARTI GEREK YOK!"
        },
        "secFeeds": {
            "title": "yayınlarınızda ne düzeltmelisiniz?",
            "header": {
                "channel": "kanal",
                "export": "ihracat",
                "description": "açıklama",
                "result": "sonuç"
            },
            "google": {
                "title": "Google",
                "feeds": {
                    "merchant": {
                        "name": "Merchant Center özet akışı",
                        "desc": "Google Alışveriş'te ürün satma",
                        "result": "optimize edilmiş saha yapısıyla geliri artırmak"
                    },
                    "promotions": {
                        "name": "Promosyonlar feed'i",
                        "desc": "müşterinize promosyonlar gösterin",
                        "result": "tıklama oranını artırın ve Dönüşümleri artırın"
                    },
                    "localInventory": {
                        "name": "Yerel Envanter Reklamları feed'i",
                        "desc": "ürünlerinizi Google Haritalar'da gösterin",
                        "result": "çevrimdışı geliri artırın, mağazanıza yeni müşteriler kazandırın"
                    },
                    "productRatings": {
                        "name": "Ürün Derecelendirmeleri feed'i",
                        "desc": "ürün puanlarınızı ve yorumlarınızı Google kullanıcıları için kullanılabilir hale getirme",
                        "result": "daha yüksek tıklama oranı"
                    },
                    "dynamicRemarketing": {
                        "name": "Dinamik Yeniden Pazarlama özet akışları",
                        "desc": "daha önce web sitenizi ziyaret etmiş kullanıcılara reklam gösterin",
                        "result": "geliri artırmak"
                    },
                    "page": {
                        "name": "Sayfa feed'i",
                        "desc": "Dinamik Arama Ağı Reklamları için sayfa feed'i",
                        "result": "arama kampanyanızın performansını artırın"
                    },
                    "sitemap": {
                        "name": "Site Haritası feed'i",
                        "desc": "Arama motoru optimizasyonu için URL feed'i",
                        "result": "Google Arama'daki görünürlüğünüzü artırın"
                    },
                    "manufacturer": {
                        "name": "Üretici feed'i",
                        "desc": "Google'a ürünleriniz hakkında daha iyi ve daha doğru bilgi verin",
                        "result": "kapsamlı analizler alın ve Google'daki varlığınızı iyileştirin"
                    }
                }
            },
            "facebook": {
                "title": "Facebook",
                "feeds": {
                    "dynamicAds": {
                        "name": "Dinamik Reklamlar yayını",
                        "desc": "daha önce web sitenizi ziyaret etmiş kullanıcılara reklam gösterin",
                        "result": "geliri artırmak"
                    },
                    "pageShop": {
                        "name": "Sayfa Dükkanı feed'i",
                        "desc": "Facebook'taki kişilere ürün gösterme ve satma",
                        "result": "geliri artırmak"
                    },
                    "marketplace": {
                        "name": "Pazar yeri feed'i",
                        "desc": "Facebook Marketplace'teki kişilere ürün gösterme ve satma",
                        "result": "geliri artırmak"
                    }
                }
            },
            "instagram": {
                "title": "instagram",
                "feeds": {
                    "shopping": {
                        "name": "Alışveriş akışı",
                        "desc": "Instagram'daki kişilere ürün gösterme ve satma",
                        "result": "geliri artırmak"
                    }
                }
            },
            "amazon": {
                "title": "Amazon",
                "feeds": {
                    "product": {
                        "name": "Ürün özet akışı",
                        "desc": "Amazon'daki kişilere ürün gösterme ve satma",
                        "result": "geliri artırmak"
                    }
                }
            },
            "ebay": {
                "title": "ebay",
                "feeds": {
                    "product": {
                        "name": "Ürün özet akışı",
                        "desc": "Ebay'deki kişilere ürün gösterme ve satma",
                        "result": "geliri artırmak"
                    }
                }
            },
            "criteo": {
                "title": "Criteo",
                "feeds": {
                    "product": {
                        "name": "Ürün özet akışı",
                        "desc": "online ürün satmak",
                        "result": "geliri artırmak"
                    }
                }
            }
        },
        "cta2": {
            "title": "FEED.STUDIO İLE SATIŞLARINIZI ARTIRIN",
            "button": "1.000 $ KREDİ AL",
            "subtitle": "KREDİ KARTI GEREK YOK!"
        },
        "meta": {
            "title": "Feed Studio »XML Feed Yöneticisi Aracı - XML Ürün Feed Generator - Google Alışveriş Feed",
            "desc": {
                "short": "Google Alışveriş Feed'i oluşturma aracı.",
                "long": "Google Alışveriş Feed'i oluşturucu"
            }
        }
    },
    "pricing": {
        "plugin": {
            "col1": {
                "header": "ÖZELLİKLER \/ PLANLAR",
                "row1": "Sınırsız ürün",
                "row2": "Google Ads - Alışveriş Yayını",
                "row3": "Google Ads - Dinamik Görüntülü Reklam Feed'i",
                "row4": "Google Ads - DSA Sayfa Beslemesi",
                "row5": "Facebook Ürün Feed'i",
                "row6": "Arukereso.hu Ürün Feed'i",
                "row7": "Criteo Ürün Feed'i",
                "row8": "GZT Ev Ürün Feed'i",
                "row9": "Google Ads Otomasyonu",
                "row10": "Gümrük ithalat ve ihracatı",
                "row11": "Web Seminerleri",
                "row12": "Özel Hesap Yöneticisi",
                "row13": "Karşılama Kuponu",
                "row14": "Aylık ücret",
                "row15": "10.000 işlem başına fiyat"
            },
            "agency": "Beklenen sonuçları 3 ay içinde teslim etmezsek tüm paranızı geri alın.",
            "col2": {
                "header": "YAŞAM BOYU ÜCRETSİZ",
                "row6": "Haftalık",
                "cta": "kayıt",
                "row1": "Dahili gösterge tablosu",
                "row14": "9 ABD doları",
                "row15": "na"
            },
            "col3": {
                "header": "ÖDÜL",
                "row6": "Günlük",
                "cta": "kayıt",
                "row1": "Premium gösterge tablosu",
                "row13": "300 dolar",
                "row14": "9 ABD doları",
                "row15": "$ 1"
            },
            "col4": {
                "row6": "Gerçek zaman",
                "cta": "kayıt",
                "row1": "VIP gösterge tablosu",
                "row10": "Özel web seminerleri",
                "header": "Erkenci Kuş Premium",
                "row13": "1000 dolar",
                "row14": "9 ABD doları",
                "row15": "$ 1"
            }
        },
        "agency": {
            "allin": {
                "li6": "chatbot",
                "li9": "Para iade garantisi*"
            }
        },
        "title": "Tümünüze uyan planlar \n Pazarlama bütçesi",
        "sec2": {
            "title": "Çevrimiçi Büyümenize Hükmedin",
            "cta": "ÜCRETSİZ EDİN"
        },
        "sec6": {
            "title": "Teknik desteğe ihtiyacınız varsa destek kontrol panelimizi ziyaret edin.",
            "cta": "DESTEK"
        },
        "header": {
            "title": "FİYATLANDIRMA",
            "sub": "kullandığın kadar öde"
        }
    },
    "testimonials": {
        "title": "Kullanıcılarımızın söyledikleri",
        "1": {
            "desc": "Neredeyse bir yıldır Google Reklam kampanyalarında birlikte çalışıyoruz. Ajanstan her zaman hızlı ve profesyonel çalışmalar yaşadık, çok yetkin, işbirlikçi ve ihtiyaçlarımızı tam olarak karşılamaya çalışıyorlar. Yenilikçi çözümler getirmenin yanı sıra, karmaşık sorunların çözümlerini ve kampanyaların sonuçlarını derinlemesine açıklayacak kadar sakindirler.",
            "name": "Adam Dallos",
            "pos": "PwC, Müdür Yardımcısı, Pazarlama, İletişim İş Geliştirme"
        }
    },
    "footer": {
        "description": ""
    },
    "menu": {
        "navigation": "SEYİR",
        "signin": "Oturum aç",
        "features": "Özellikleri",
        "pricing": "Fiyatlandırma",
        "blog": "Blog",
        "terms-conditions": "şartlar ve koşullar",
        "partners": "ORTAKLIK",
        "affiliate": "bağlı şirket",
        "whitelabel": "Beyaz etiket",
        "introducing": "Tanıtıcı Ortak",
        "findus": "Bizi Bulun",
        "home": "Ev",
        "dashboard": "Gösterge Paneli",
        "contact": "İletişim"
    },
    "features": {
        "title": "Google'daki reklam <br\/> her zamankinden daha kolay",
        "subtitle": "",
        "sec1": {
            "title": "Neden Google'da reklam vermelisiniz?",
            "col1": {
                "subtitle": "Rekabetinizi Yenin",
                "desc": "Her gün insanlar markanızı veya ürünlerinizi arıyor, Google size işletmenizi rakiplerinizin önüne geçirme olanağı sunuyor."
            },
            "col2": {
                "subtitle": "İdeal Müşterilere Ulaşın",
                "desc": "Google, tüm etkileşim düzeylerinde doğru kullanıcıları doğru zamanda hedeflemek için en iyi reklamcılık platformlarından biridir."
            },
            "col3": {
                "subtitle": "Satışlarınızı Artırın",
                "desc": "Kolayca müşteriye dönüşebilen, satış ve çevrimiçi işinizi büyüten ziyaretçilerle web sitenize gelen trafik büyük ölçüde artacaktır."
            }
        },
        "sec2": {
            "title": "Çevrimiçi Büyümenize Hükmedin",
            "cta": "ÜCRETSİZ DENEYİN"
        }
    },
    "getstarted": {
        "title": "3 kolay adımla başlayın",
        "col1": {
            "subtitle": "1. İndir",
            "desc": ""
        },
        "col2": {
            "subtitle": "2. Etkinleştir",
            "desc": ""
        },
        "col3": {
            "subtitle": "3. Parça",
            "desc": ""
        }
    },
    "dashboard": {
        "title": "Gösterge Paneli",
        "domainsettings": {
            "title": "ALAN AYARLARI",
            "subtitle1": "Yeni alan adı ekle",
            "input1": "Yeni alan adınız",
            "cta1": "Ekle",
            "subtitle2": "Mevcut alan adlarınız",
            "currentdomains1": "Alan adı",
            "currentdomains2": "API Anahtarı"
        },
        "subscription": {
            "title": "ABONELİĞİ",
            "subtitle1": "Denge",
            "remaining": "kalan"
        },
        "tab2": {
            "desc1": "Şu anda bizim",
            "desc2": "abone",
            "cta": "ANAHTAR ABONELİĞİ"
        },
        "billing": {
            "title": "Fatura Detayları",
            "input": {
                "name": "Fatura adı",
                "vat": "KDV numarası",
                "address": "Adres",
                "city": "Kent",
                "zip": "posta kodu",
                "country": "ülke"
            }
        },
        "user": {
            "title": "KULLANICI AYARLARI",
            "subtitle": "Giriş bilgileriniz",
            "lastlogin": "Son giriş:"
        }
    },
    "cta": {
        "update": "GÜNCELLEME"
    },
    "input": {
        "email": "E",
        "password": "Parola",
        "passwordrepeat": "Şifreyi tekrar girin"
    },
    "partners": {
        "affiliate": {
            "subtitle": "Özel Bonuslar ve Promosyonlar <br> Bağlı artırmak için",
            "substitle2": "Karınızı artırmak için bağlı kuruluş pazarlama araçları ve ödeme planlarının birleşimi!"
        },
        "affilieate": {
            "description": ""
        },
        "whitelabel": {
            "title": "Beyaz Etiket Ortaklığı"
        },
        "introducing": {
            "subtitle": "",
            "desc2": "Daha fazla bilgi için lütfen şu adresteki uzman ekibimizle iletişime geçin:"
        }
    },
    "url": {
        "features": "özellikleri",
        "pricing": "fiyatlandırma",
        "partnership": {
            "affiliate": "patnership \/ ortaklık",
            "whitelabel": "patnership \/ Whitelabel",
            "introductory": "patnership \/ tanıtım"
        },
        "contact": "İletişim",
        "login": "oturum aç"
    },
    "b2c": {
        "main": {
            "title": "<b> YEM <\/b> YÖNETİM"
        },
        "cta1": {
            "title": "ERKEN KUŞ TANITIMI",
            "description": "Sınırlı bir süre için her erken kuş kullanıcısı Feed Studio Premium paketini test etmek için 1000 $ 'lık karşılama kredisi kazanın. <br\/> <br\/> Deneme süresi yok, kredi kartı gerekmiyor.",
            "button": "1.000 $ KREDİ AL"
        },
        "sec1": {
            "title": "feed.studio'yu nasıl kullanabilirsiniz",
            "col1": {
                "title": "kaydolun ve ham <br\/> bize verin",
                "desc": "hesabınızı ayarlamak için bize iletişim ve feed verilerinizi verin"
            },
            "col2": {
                "title": "Yöneticilerimiz ihracat feed'inizi kurdu <br\/>",
                "desc": "ham feed'inizden ihracat feed'inizi oluşturuyoruz"
            },
            "col3": {
                "title": "Keyfini çıkarın (sonuçlar)",
                "desc": "Uzmanlarımızın size ihtiyacınız olan en iyi beslemeyi sunmak için çok çalıştığını bilerek arkanıza yaslanın."
            }
        },
        "sec2": {
            "title": "Erkenci Kuş Test Cihazları için kaydolun",
            "infoList": {
                "item1": "Hoş geldin kredisi için zaman sınırı yok",
                "item2": "karşılama süresi sonunda sizden ücret \/ fatura almayız",
                "item3": "Ürün veya ihracat limiti yok",
                "item4": "Yönetilen hizmet - platformu öğrenmek zorunda değilsiniz",
                "item5": "Hesabınızı istediğiniz zaman silebilirsiniz",
                "item6": "Özel dışa aktarma biçimleri isteyebilirsiniz"
            },
            "form": {
                "name": "ad",
                "email": "E-posta",
                "phone": "Telefon",
                "feedUrl": "Besleme URL'si",
                "policyLabel": "Ben şartları ve koşulları kabul ediyorum",
                "marketingLabel": "Feed.Studio bültenine abone olmak istiyorum",
                "button": "erken erişim sağla",
                "feedType": ""
            }
        },
        "meta": {
            "title": "XML Ürün Feed'i Oluşturucu, Google Alışveriş ve Facebook Feed'i",
            "desc": {
                "short": "Google ve Facebook özet akışlarınızı kolayca oluşturun ve optimize edin.",
                "long": "Google Alışveriş Feed'i, Ürün Feed'i Optimizasyonu."
            }
        }
    },
    "b2b": {
        "main": {
            "title": "<b> BESLEME <\/b> .",
            "description": "Feed. E-ticaret profesyonelleri ve ajansları için tasarlanmış ses. Başarılı bir gelecek inşa etmek için bölgesel ortaklar arıyoruz. <\/br> artırın, Feed.Studio ile daha fazla satış yapın!",
            "button": "DAHA FAZLA SAT"
        },
        "sec1": {
            "title": "Neden bizimle çalışıyorsunuz?",
            "col1": {
                "title": "daha fazla sat",
                "desc": "daha fazla sat"
            },
            "col2": {
                "title": "senin yendi <\/br> rakipleri",
                "desc": "birinci ol"
            },
            "col3": {
                "title": "<\/br> müşterilerinizi memnun edin",
                "desc": "kalite kontrol"
            },
            "col4": {
                "title": "Zamandan tasarruf",
                "desc": "tamir servisimizle"
            }
        },
        "secPartner": {
            "title": "ortaklıklar",
            "header": {
                "affiliate": "bağlı şirket",
                "gold": "Altın",
                "exclusive": "seçkin",
                "custom": "görenek"
            },
            "requirements": {
                "title": "Gereksinimler",
                "newClient": "yeni müşteriler \/ yıl",
                "certifiedusers": "sertifikalı aktif dahili kullanıcı"
            },
            "recognition": {
                "title": "Tanıma",
                "visible": "feed.studio'da görünür",
                "trademark": "\"feed studio\" ticari markasını kullanma hakları"
            },
            "training": {
                "title": "Eğitim avantajları",
                "biweekly": "iki haftada bir yapılan toplantılar",
                "salesSupport": "satış destek ve rehberlik"
            },
            "marketing": {
                "title": "pazarlama avantajları",
                "brand": "yerel kampanyalarda marka",
                "customerStories": "müşteri başarı öyküleri"
            },
            "sales": {
                "title": "satış avantajları",
                "materials": "satış malzemeleri",
                "accountManagers": "özel hesap yöneticisi",
                "leads": "yerel potansiyel müşteriler",
                "affiliateBonus": "BAĞLI ORTAKLIKLARDA İLAVE",
                "feedStudioBonus": "Studio'daki bonus"
            },
            "contactUs": "Bize Ulaşın",
            "signUp": "Kaydol"
        },
        "sec3": {
            "title": "nasıl iş ortağımız olun?",
            "col1": {
                "title": "kaydol",
                "desc": ""
            },
            "col2": {
                "title": "satış ve platform eğitimlerimize katılın",
                "desc": ""
            },
            "col3": {
                "title": "olası satışlarımızı ve müşterilerimizi yönetin",
                "desc": ""
            }
        },
        "sec4": {
            "title": "PARTNER PROGRAM'a kaydolun",
            "infoList": {
                "item1": "Feed Studio'ya erişim stratejileri, satış ve hizmet sorunlarını tartışır",
                "item2": "Feed Studio iş ortakları sayfasında resmi iş ortağı olarak listelenerek görünürlük ve tanınma",
                "item3": "Satış kontrol paneline erişim"
            },
            "form": {
                "plan": {
                    "affiliate": "bağlı şirket",
                    "gold": "Altın",
                    "custom": "görenek"
                },
                "name": "ad",
                "email": "E-posta",
                "phone": "Telefon",
                "message": "İleti",
                "policyLabel": "Ben şartları ve koşulları kabul ediyorum",
                "marketingLabel": "Feed.Studio bültenine abone olmak istiyorum",
                "button": "Erken Erişim Sağlayın"
            }
        }
    },
    "registration": {
        "meta": {
            "title": "Feed Studio'ya Ücretsiz Kayıt Olun",
            "desc": {
                "short": "Kaydolun ve Feed Studio için 1000 $ kredi kazanın.",
                "long": "Kayıt olun ve Feed Studio'yu kullanmak için 1000 dolar karşılama kredisi alın."
            }
        }
    },
    "earlybirdreg": {
        "form": {
            "infoList": {
                "desc1": "",
                "desc2": "",
                "item1": "",
                "item2": "",
                "item3": "",
                "item4": "",
                "item5": "",
                "item6": "",
                "item7": ""
            }
        }
    },
    "login": {
        "title": "",
        "subtitle": "",
        "subtitle2": "",
        "googleButton": ""
    }
};