export default {
    "home": {
        "sec1": {
            "title": "Zwiększ swoje przychody. Bez wysiłku.",
            "cta": "Pobierz za darmo",
            "col1": {
                "title": "zwiększ swoje <br\/> przychody",
                "desc": "zoptymalizuj strukturę pliku danych i zwiększ swoje przychody o 5-30%"
            },
            "col2": {
                "title": "otwieraj nowe kanały sprzedaży <br\/>",
                "desc": "sprzedawaj więcej dzięki nowym kanałom sprzedaży"
            },
            "col3": {
                "title": "wypróbuj za darmo bez <br\/> limitu czasu",
                "desc": "Nasza metoda wyceny płatności na bieżąco zapewnia najbardziej elastyczne rozwiązanie na rynku"
            },
            "col4": {
                "title": "oszczędzaj swój czas <br\/>",
                "desc": "nasi konsultanci tworzą i optymalizują Twoje kanały produktów"
            }
        },
        "sec2": {
            "title": "z łatwością importuj i eksportuj",
            "col1": {
                "title": "kanał dostawcy <br\/> zarządzanie",
                "desc": "Feed Studio łączy różne pliki produktów w jeden plik danych do importu"
            },
            "col2": {
                "title": "użyj ponad 100 <br\/> innych formatów",
                "desc": "Potrzebujesz różnych opisów Zakupów Google i Instangram? Żaden problem"
            },
            "col3": {
                "title": "zoptymalizuj swój produkt <br\/> dane według platformy",
                "desc": "Czy potrzebujesz trochę innego opisu zakupów Google niż sklep na Instagramie? Nie ma problemu"
            },
            "col4": {
                "title": "zmieniać historię",
                "desc": "Dlaczego reklamuje się złą cenę? Kiedy aktualizacja produktu się nie powiodła? Znamy odpowiedź!"
            },
            "col5": {
                "title": "Optymalizacja SEO",
                "desc": "Zautomatyzowane tworzenie i optymalizacja metatagów SEO."
            },
            "col6": {
                "title": "usługa zarządzana",
                "desc": "Feed Studio łączy różne pliki produktów w jeden plik danych do importu"
            }
        },
        "sec3": {
            "title": "Chcesz rozwijać swój biznes bez walki?"
        },
        "sec4": {
            "item2": {
                "subtitle": "Zarządzanie paszami"
            },
            "item4": {
                "subtitle": "Wyszukaj konsolę"
            }
        },
        "sec5": {
            "title": "Pięknie zautomatyzowane kampanie Google Ads",
            "col1": {
                "title": "Reklamy marki",
                "desc": "Czy wiesz, że 40% klientów nie klika Twojej witryny na stronie wyników wyszukiwania? Dotrzyj do nich dzięki kampaniom promującym markę!"
            },
            "col2": {
                "title": "Dynamiczne reklamy w wyszukiwarce"
            },
            "col3": {
                "title": "Reklamy zakupowe"
            },
            "col4": {
                "title": "Wyświetlaj reklamy",
                "desc": "Docieraj do docelowych odbiorców w sieci reklamowej Google dzięki elastycznemu remarketingowi, remarketingowi dynamicznemu i inteligentnym reklamom."
            }
        },
        "sec6": {
            "title": "Gotowy do zautomatyzowania swojej reklamy?",
            "cta": "Uwolnić się"
        },
        "sec7": {
            "title": "",
            "subtitle": "Kompleksowe usługi nawet w bezpłatnym abonamencie"
        },
        "premier-partner-desc": "Google wyróżnia nas jako agencja o najwyższej skuteczności, dzięki czemu możesz mieć pewność, że Twoja strategia Google Ads jest w dobrych rękach.",
        "sec8": {
            "title": "",
            "cta": "POBIERZ ZA DARMO"
        },
        "main": {
            "title": "<b> FEED <\/b> .ME.BABY",
            "description": "Poproś o konto wczesnego ptaka, aby wypróbować najnowszy kanał Google <br\/> oprogramowanie menedżera przeznaczone dla profesjonalistów i agencji. <br\/> Przez ograniczony czas otrzymasz <s> 300 $ <\/s> 1 000 $ na powitanie.",
            "button": "ZDOBĄDŹ 1000 USD"
        },
        "cta1": {
            "title": "PROMOCJA WCZESNEGO PTAKA",
            "description": "nasi pierwsi użytkownicy otrzymują kredyt powitalny w wysokości 1000 USD na przetestowanie Feed Studio",
            "button": "UZYSKAJ KREDYT 1000 USD",
            "subtitle": "NIE POTRZEBNA KARTA KREDYTOWA!"
        },
        "secFeeds": {
            "title": "co powinieneś naprawić w swoich kanałach?",
            "header": {
                "channel": "kanał",
                "export": "eksport",
                "description": "opis",
                "result": "wynik"
            },
            "google": {
                "title": "Google",
                "feeds": {
                    "merchant": {
                        "name": "Kanał Merchant Center",
                        "desc": "sprzedawać produkty w Zakupach Google",
                        "result": "zwiększyć przychody dzięki zoptymalizowanej strukturze pola"
                    },
                    "promotions": {
                        "name": "Kanał promocji",
                        "desc": "pokaż promocje swojemu klientowi",
                        "result": "zwiększyć współczynnik klikalności i zwiększyć konwersje"
                    },
                    "localInventory": {
                        "name": "Kanał lokalnych reklam asortymentowych",
                        "desc": "pokaż swoje produkty w Mapach Google",
                        "result": "zwiększ przychody offline, zdobądź nowych klientów w swoim sklepie"
                    },
                    "productRatings": {
                        "name": "Kanał ocen produktów",
                        "desc": "udostępniaj oceny i recenzje produktów użytkownikom Google",
                        "result": "wyższy współczynnik klikalności"
                    },
                    "dynamicRemarketing": {
                        "name": "Kanały remarketingu dynamicznego",
                        "desc": "wyświetlać reklamy osobom, które wcześniej odwiedziły Twoją witrynę",
                        "result": "zwiększyć przychody"
                    },
                    "page": {
                        "name": "Kanał strony",
                        "desc": "Kanał strony dla dynamicznych reklam w wyszukiwarce",
                        "result": "zwiększ skuteczność swojej kampanii w sieci wyszukiwania"
                    },
                    "sitemap": {
                        "name": "Kanał mapy witryny",
                        "desc": "Kanał URL do optymalizacji pod kątem wyszukiwarek",
                        "result": "zwiększyć widoczność w wyszukiwarce Google"
                    },
                    "manufacturer": {
                        "name": "Kanał producenta",
                        "desc": "dostarczać Google lepsze i dokładniejsze informacje o swoich produktach",
                        "result": "uzyskaj kompleksową analizę i popraw swoją obecność w Google"
                    }
                }
            },
            "facebook": {
                "title": "Facebook",
                "feeds": {
                    "dynamicAds": {
                        "name": "Kanał reklam dynamicznych",
                        "desc": "wyświetlać reklamy osobom, które wcześniej odwiedziły Twoją witrynę",
                        "result": "zwiększyć przychody"
                    },
                    "pageShop": {
                        "name": "Strona Kanał informacyjny",
                        "desc": "pokazywać i sprzedawać produkty osobom na Facebooku",
                        "result": "zwiększyć przychody"
                    },
                    "marketplace": {
                        "name": "Kanał na rynku",
                        "desc": "pokazywać i sprzedawać produkty osobom na Facebooku Marketplace",
                        "result": "zwiększyć przychody"
                    }
                }
            },
            "instagram": {
                "title": "instagram",
                "feeds": {
                    "shopping": {
                        "name": "Kanał zakupów",
                        "desc": "pokazywać i sprzedawać produkty osobom na Instagramie",
                        "result": "zwiększyć przychody"
                    }
                }
            },
            "amazon": {
                "title": "amazonka",
                "feeds": {
                    "product": {
                        "name": "Kanał produktu",
                        "desc": "pokazywać i sprzedawać produkty ludziom w Amazon",
                        "result": "zwiększyć przychody"
                    }
                }
            },
            "ebay": {
                "title": "eBay",
                "feeds": {
                    "product": {
                        "name": "Kanał produktu",
                        "desc": "pokazywać i sprzedawać produkty osobom w serwisie eBay",
                        "result": "zwiększyć przychody"
                    }
                }
            },
            "criteo": {
                "title": "criteo",
                "feeds": {
                    "product": {
                        "name": "Kanał produktu",
                        "desc": "sprzedawać produkty online",
                        "result": "zwiększyć przychody"
                    }
                }
            }
        },
        "cta2": {
            "title": "ZWIĘKSZ SWOJĄ SPRZEDAŻ Z FEED.STUDIO",
            "button": "UZYSKAJ KREDYT 1000 USD",
            "subtitle": "NIE POTRZEBNA KARTA KREDYTOWA!"
        },
        "meta": {
            "title": "Feed Studio »Narzędzie XML Feed Manager - Generator kanału produktu XML - Kanał zakupów Google",
            "desc": {
                "short": "Narzędzie do generowania kanałów Google Shopping Feed.",
                "long": "Generator kanału zakupów Google"
            }
        }
    },
    "pricing": {
        "plugin": {
            "col1": {
                "header": "CECHY \/ PLANY",
                "row1": "Nieograniczone produkty",
                "row2": "Google Ads - kanał zakupów",
                "row3": "Google Ads - dynamiczny kanał reklam displayowych",
                "row4": "Google Ads - DSA Pagefeed",
                "row5": "Kanał produktów Facebook",
                "row6": "Arukereso.hu Kanał produktu",
                "row7": "Kanał produktu Criteo",
                "row8": "Kanał produktu RTB House",
                "row9": "Google Ads Automation",
                "row10": "Niestandardowy import i eksport",
                "row11": "Seminaria internetowe",
                "row12": "Dedykowany menedżer konta",
                "row13": "Kupon powitalny",
                "row14": "Miesięczna opłata",
                "row15": "Cena za 10 000 operacji"
            },
            "agency": "Wszystkie pieniądze zwrócone, jeśli nie dostarczymy oczekiwanych rezultatów w ciągu 3 miesięcy.",
            "col2": {
                "header": "BEZ ŻYCIA",
                "row6": "Co tydzień",
                "cta": "Rejestracja",
                "row1": "Wbudowany pulpit nawigacyjny",
                "row14": "9 USD",
                "row15": "na"
            },
            "col3": {
                "header": "PREMIA",
                "row6": "Codziennie",
                "cta": "Rejestracja",
                "row1": "Deska rozdzielcza Premium",
                "row13": "300 USD",
                "row14": "9 USD",
                "row15": "1 USD"
            },
            "col4": {
                "row6": "Czas rzeczywisty",
                "cta": "Rejestracja",
                "row1": "Pulpit VIP",
                "row10": "Prywatne seminaria internetowe",
                "header": "Early Bird Premium",
                "row13": "1000 USD",
                "row14": "9 USD",
                "row15": "1 USD"
            }
        },
        "agency": {
            "allin": {
                "li6": "Chatbot",
                "li9": "Gwarancja zwrotu pieniędzy*"
            }
        },
        "title": "Plany, które pasują do całości \n budżet marketingowy",
        "sec2": {
            "title": "Zdominuj swój rozwój online",
            "cta": "UWOLNIĆ SIĘ"
        },
        "sec6": {
            "title": "Jeśli potrzebujesz pomocy technicznej, odwiedź nasz pulpit pomocy technicznej.",
            "cta": "WSPARCIE"
        },
        "header": {
            "title": "CENNIK",
            "sub": "płać jak idziesz"
        }
    },
    "testimonials": {
        "title": "Co mówią nasi użytkownicy",
        "1": {
            "desc": "Pracujemy razem nad kampaniami reklam Google od prawie roku. Agencja zawsze doświadczała szybkiej i profesjonalnej pracy, są bardzo kompetentni, współpracują i starają się w pełni zaspokoić nasze potrzeby. Poza wprowadzaniem innowacyjnych rozwiązań są na tyle spokojni, aby głęboko wyjaśnić rozwiązania złożonych problemów i wyniki kampanii.",
            "name": "Adam Dallos",
            "pos": "PwC, asystent menedżera, marketing, komunikacja Rozwój biznesu"
        }
    },
    "footer": {
        "description": ""
    },
    "menu": {
        "navigation": "NAWIGACJA",
        "signin": "Zaloguj się",
        "features": "funkcje",
        "pricing": "cennik",
        "blog": "Blog",
        "terms-conditions": "Zasady i Warunki",
        "partners": "WSPÓŁPRACA",
        "affiliate": "Przyłączać",
        "whitelabel": "Biała etykieta",
        "introducing": "Przedstawiamy partnera",
        "findus": "Znajdź nas na",
        "home": "Dom",
        "dashboard": "Deska rozdzielcza",
        "contact": "Kontakt"
    },
    "features": {
        "title": "Reklama w Google <br\/> łatwiejsza niż kiedykolwiek",
        "subtitle": "",
        "sec1": {
            "title": "Dlaczego warto reklamować się w Google?",
            "col1": {
                "subtitle": "Pokonaj konkurencję",
                "desc": "Każdego dnia ludzie szukają Twojej marki lub produktów, Google daje Ci możliwość zaprezentowania swojej firmy przed konkurencją."
            },
            "col2": {
                "subtitle": "Docieraj do idealnych klientów",
                "desc": "Google jest jedną z najlepszych platform reklamowych do kierowania reklam do właściwych użytkowników we właściwym czasie na wszystkich poziomach zaangażowania."
            },
            "col3": {
                "subtitle": "Zwiększ swoją sprzedaż",
                "desc": "Ruch do Twojej witryny znacznie wzrośnie dzięki Google, dzięki odwiedzającym, którzy z łatwością mogą stać się klientami, zwiększając sprzedaż i działalność online."
            }
        },
        "sec2": {
            "title": "Zdominuj swój rozwój online",
            "cta": "SPRÓBUJ ZA DARMO"
        }
    },
    "getstarted": {
        "title": "Zacznij od 3 prostych kroków",
        "col1": {
            "subtitle": "1. Pobierz",
            "desc": ""
        },
        "col2": {
            "subtitle": "2. Aktywuj",
            "desc": ""
        },
        "col3": {
            "subtitle": "3. Śledź",
            "desc": ""
        }
    },
    "dashboard": {
        "title": "Deska rozdzielcza",
        "domainsettings": {
            "title": "USTAWIENIA DOMENY",
            "subtitle1": "Dodaj nową domenę",
            "input1": "Twoja nowa domena",
            "cta1": "Dodaj",
            "subtitle2": "Twoje obecne domeny",
            "currentdomains1": "Domena",
            "currentdomains2": "Klucz API"
        },
        "subscription": {
            "title": "SUBSKRYPCJA",
            "subtitle1": "Saldo",
            "remaining": "pozostały"
        },
        "tab2": {
            "desc1": "Aktualnie korzystasz z naszego",
            "desc2": "Subskrypcja",
            "cta": "SUBSKRYPCJA PRZEŁĄCZNIKA"
        },
        "billing": {
            "title": "Szczegóły płatności",
            "input": {
                "name": "Nazwa do faktury",
                "vat": "Numer VAT",
                "address": "Adres",
                "city": "Miasto",
                "zip": "kod pocztowy",
                "country": "Kraj"
            }
        },
        "user": {
            "title": "USTAWIENIA UŻYTKOWNIKA",
            "subtitle": "Twoje dane logowania",
            "lastlogin": "Ostatnie logowanie:"
        }
    },
    "cta": {
        "update": "AKTUALIZACJA"
    },
    "input": {
        "email": "Adres e-mail",
        "password": "Hasło",
        "passwordrepeat": "Powtórz hasło"
    },
    "partners": {
        "affiliate": {
            "subtitle": "Ekskluzywne bonusy i promocje <br> celu zwiększenia liczby partnerów",
            "substitle2": "Połączenie narzędzi marketingu afiliacyjnego i planów płatności w celu zwiększenia zysków!"
        },
        "affilieate": {
            "description": ""
        },
        "whitelabel": {
            "title": "Partnerstwo White Label"
        },
        "introducing": {
            "subtitle": "",
            "desc2": "Aby uzyskać więcej informacji prosimy o kontakt z naszym specjalistycznym zespołem:"
        }
    },
    "url": {
        "features": "funkcje",
        "pricing": "cennik",
        "partnership": {
            "affiliate": "patnerstwo \/ partner",
            "whitelabel": "patnership \/ whitelabel",
            "introductory": "patership \/ wprowadzenie"
        },
        "contact": "kontakt",
        "login": "Zaloguj sie"
    },
    "b2c": {
        "main": {
            "title": "<b> FEED <\/b> .ZARZĄDZANIE"
        },
        "cta1": {
            "title": "PROMOCJA WCZESNEGO PTAKA",
            "description": "Przez ograniczony czas każdy początkujący użytkownik otrzyma 1000 USD kredytu powitalnego na przetestowanie pakietu Feed Studio Premium. <br\/> <br\/> Bez okresu próbnego, karta kredytowa nie jest wymagana.",
            "button": "UZYSKAJ KREDYT 1000 USD"
        },
        "sec1": {
            "title": "jak korzystać z feed.studio",
            "col1": {
                "title": "zarejestruj się i daj nam <br\/> swój surowy kanał",
                "desc": "podaj nam swoje dane kontaktowe i paszowe, aby założyć konto"
            },
            "col2": {
                "title": "nasi menedżerowie skonfigurowali <br\/> Twój plik eksportu",
                "desc": "z Twojego surowego pliku danych tworzymy Twój plik eksportu"
            },
            "col3": {
                "title": "Ciesz się (wyniki)",
                "desc": "Usiądź wygodnie, wiedząc, że nasi eksperci ciężko pracują, aby dostarczyć Ci najlepsze potrzebne pasze."
            }
        },
        "sec2": {
            "title": "Zapisz się na wczesny tester ptaków",
            "infoList": {
                "item1": "Brak limitu czasowego na powitanie",
                "item2": "nie obciążamy Cię \/ nie obciążamy rachunku pod koniec okresu powitalnego",
                "item3": "Brak limitu produktu lub eksportu",
                "item4": "Usługa zarządzana - nie musisz uczyć się platformy",
                "item5": "Możesz usunąć swoje konto w dowolnym momencie",
                "item6": "Możesz poprosić o niestandardowe formaty eksportu"
            },
            "form": {
                "name": "Imię",
                "email": "E-mail",
                "phone": "Telefon",
                "feedUrl": "Adres URL kanału",
                "policyLabel": "Akceptuję zasady i warunki",
                "marketingLabel": "Chciałbym zapisać się do newslettera Feed.Studio",
                "button": "uzyskaj wczesny dostęp",
                "feedType": ""
            }
        },
        "meta": {
            "title": "Generator kanału produktu XML, Zakupy Google i kanał Facebook",
            "desc": {
                "short": "Łatwo twórz i optymalizuj kanały Google i Facebook.",
                "long": "Kanał zakupów Google, Optymalizacja kanałów produktów."
            }
        }
    },
    "b2b": {
        "main": {
            "title": "<b> PODAJ <\/b> .PARTNERZY",
            "description": "Feed.Studio przeznaczony dla portali e-commerce i agencji. Poszukujemy regionalnych partnerów do budowania udanej przyszłości. <\/br> Zwiększ swoje przychody, sprzedaj więcej dzięki Feed.Studio!",
            "button": "SPRZEDAĆ WIĘCEJ"
        },
        "sec1": {
            "title": "Dlaczego warto z nami współpracować?",
            "col1": {
                "title": "sprzedać więcej",
                "desc": "sprzedać więcej"
            },
            "col2": {
                "title": "pokonać swoich <\/br> konkurentów",
                "desc": "bądź pierwszy"
            },
            "col3": {
                "title": "zadowolić swoich <\/br> klientów",
                "desc": "kontrola jakości"
            },
            "col4": {
                "title": "Oszczędzaj czas",
                "desc": "dzięki naszej usłudze dla mamaged"
            }
        },
        "secPartner": {
            "title": "Związki partnerskie",
            "header": {
                "affiliate": "Przyłączać",
                "gold": "Złoto",
                "exclusive": "Ekskluzywny",
                "custom": "Zwyczaj"
            },
            "requirements": {
                "title": "Wymagania",
                "newClient": "nowi klienci \/ rok",
                "certifiedusers": "certyfikowany aktywny użytkownik wewnętrzny"
            },
            "recognition": {
                "title": "Uznanie",
                "visible": "widoczne na feed.studio",
                "trademark": "prawa do używania znaku towarowego „feed studio”"
            },
            "training": {
                "title": "Korzyści ze szkolenia",
                "biweekly": "spotkania dwutygodniowe",
                "salesSupport": "wsparcie sprzedaży i wskazówki"
            },
            "marketing": {
                "title": "korzyści marketingowe",
                "brand": "marka w lokalnych kampaniach",
                "customerStories": "historie sukcesu klientów"
            },
            "sales": {
                "title": "korzyści ze sprzedaży",
                "materials": "materiały sprzedażowe",
                "accountManagers": "dedykowany menedżer konta",
                "leads": "lokalne kontakty",
                "affiliateBonus": "BONUS ZA KLIENTÓW POWIĄZANYCH",
                "feedStudioBonus": "bonus w Feed Studio"
            },
            "contactUs": "Skontaktuj się z nami",
            "signUp": "Zapisz się"
        },
        "sec3": {
            "title": "jak zostać naszym partnerem?",
            "col1": {
                "title": "Zapisz się",
                "desc": ""
            },
            "col2": {
                "title": "weź udział w naszych szkoleniach dotyczących sprzedaży i platform",
                "desc": ""
            },
            "col3": {
                "title": "zarządzaj naszymi potencjalnymi klientami i klientami",
                "desc": ""
            }
        },
        "sec4": {
            "title": "Zarejestruj się w PROGRAMIE PARTNERSKIM",
            "infoList": {
                "item1": "dostęp do Feed Studio omawia kwestie strategii, sprzedaży i usług",
                "item2": "widoczność i rozpoznawalność dzięki byciu oficjalnym partnerem na stronie partnerów Feed Studio",
                "item3": "dostęp do pulpitu sprzedaży"
            },
            "form": {
                "plan": {
                    "affiliate": "Przyłączać",
                    "gold": "Złoto",
                    "custom": "Zwyczaj"
                },
                "name": "Imię",
                "email": "E-mail",
                "phone": "Telefon",
                "message": "Wiadomość",
                "policyLabel": "Akceptuję zasady i warunki",
                "marketingLabel": "Chciałbym zapisać się do newslettera Feed.Studio",
                "button": "Uzyskaj wczesny dostęp"
            }
        }
    },
    "registration": {
        "meta": {
            "title": "Zarejestruj się za darmo w Feed Studio",
            "desc": {
                "short": "Zarejestruj się i uzyskaj 1000 USD na Feed Studio.",
                "long": "Zarejestruj się, a otrzymasz 1000 USD kredytu powitalnego za korzystanie z Feed Studio."
            }
        }
    },
    "earlybirdreg": {
        "form": {
            "infoList": {
                "desc1": "",
                "desc2": "",
                "item1": "",
                "item2": "",
                "item3": "",
                "item4": "",
                "item5": "",
                "item6": "",
                "item7": ""
            }
        }
    },
    "login": {
        "title": "",
        "subtitle": "",
        "subtitle2": "",
        "googleButton": ""
    }
};