export default {
    "home": {
        "sec1": {
            "title": "Steigern Sie Ihren Umsatz. Mühelos.",
            "cta": "Gratis herunterladen",
            "col1": {
                "title": "<br\/> Ihren <br\/> Umsatz",
                "desc": "Optimieren Sie Ihre Feed-Struktur und steigern Sie Ihren Umsatz um 5-30%"
            },
            "col2": {
                "title": "neue Vertriebskanäle <br\/>",
                "desc": "Verkaufen Sie mehr mit neuen Vertriebskanälen"
            },
            "col3": {
                "title": "versuchen Sie es kostenlos ohne <br\/> Zeitlimit",
                "desc": "Unsere Pay-as-you-go-Preismethode bietet Ihnen die flexibelste Lösung auf dem Markt"
            },
            "col4": {
                "title": "Sparen Sie Ihre <br\/> Zeit",
                "desc": "Unsere Berater erstellen und optimieren Ihre Produkt-Feeds"
            }
        },
        "sec2": {
            "title": "mühelos importieren und exportieren",
            "col1": {
                "title": "Lieferantenfeed <br\/> Management",
                "desc": "Feed Studio kombiniert verschiedene Produkt-Feeds zu einem importierbaren Feed"
            },
            "col2": {
                "title": "Verwenden Sie mehr als 100 <br\/> andere Formate",
                "desc": "Benötigen Sie unterschiedliche Google Shopping- und Instangram-Beschreibungen? Kein Problem"
            },
            "col3": {
                "title": "optimieren Ihr Produkt <br\/> Daten von Plattform",
                "desc": "Benötigen Sie eine etwas andere Google-Einkaufsbeschreibung als den Instagram-Shop? Kein Problem"
            },
            "col4": {
                "title": "Geschichte verändern",
                "desc": "Warum für falschen Preis werben? Wann ist Ihr Produktupdate fehlgeschlagen? Wir kennen die Antwort!"
            },
            "col5": {
                "title": "SEO-Optimierung",
                "desc": "Automatisierte Erstellung und Optimierung von SEO-Meta-Tags."
            },
            "col6": {
                "title": "Managed Service",
                "desc": "Feed Studio kombiniert verschiedene Produkt-Feeds zu einem importierbaren Feed"
            }
        },
        "sec3": {
            "title": "Möchten Sie Ihr Geschäft ohne den Kampf ausbauen?"
        },
        "sec4": {
            "item2": {
                "subtitle": "Feed Management"
            },
            "item4": {
                "subtitle": "Suchkonsole"
            }
        },
        "sec5": {
            "title": "Wunderschön automatisierte Google Ads-Kampagnen",
            "col1": {
                "title": "Markenanzeigen",
                "desc": "Wussten Sie, dass 40% Ihrer Kunden auf der Suchergebnisseite nicht auf Ihre Website klicken? Erreichen Sie sie mit Markenkampagnen!"
            },
            "col2": {
                "title": "Dynamische Suchanzeigen"
            },
            "col3": {
                "title": "Shopping-Anzeigen"
            },
            "col4": {
                "title": "Zeige Werbung an",
                "desc": "Erreichen Sie Ihre Zielgruppe im Google Display-Netzwerk mit reaktionsschnellem Remarketing, dynamischem Remarketing und intelligenten Anzeigen."
            }
        },
        "sec6": {
            "title": "Bereit, Ihre Werbung zu automatisieren?",
            "cta": "Holen Sie sich kostenlos"
        },
        "sec7": {
            "title": "",
            "subtitle": "Umfassende Dienstleistungen auch im kostenlosen Plan"
        },
        "premier-partner-desc": "Wir zeichnen uns durch Google als leistungsstärkste Agentur aus, sodass Sie sicher sein können, dass Ihre Google Ads-Strategie in guten Händen ist.",
        "sec8": {
            "title": "",
            "cta": "GRATIS HERUNTERLADEN"
        },
        "main": {
            "title": "<b> FEED <\/b> .ME.BABY",
            "description": "Fordern Sie ein <br\/> an, um die neueste Google Feed <br\/> Manager-Software für Profis und Agenturen <br\/> . <br\/> Für eine begrenzte Zeit erhalten Sie <s> 300 $ <\/s> 1.000 $ Willkommensguthaben.",
            "button": "Erhalten Sie 1.000 $ Guthaben"
        },
        "cta1": {
            "title": "EARLY BIRD PROMOTION",
            "description": "Unsere ersten Benutzer erhalten ein Willkommensguthaben von 1000 USD, um Feed Studio zu testen",
            "button": "ERHALTEN SIE 1.000 USD KREDIT",
            "subtitle": "KEINE KREDITKARTE ERFORDERLICH!"
        },
        "secFeeds": {
            "title": "Was sollten Sie in Ihren Feeds korrigieren?",
            "header": {
                "channel": "Kanal",
                "export": "Export",
                "description": "Beschreibung",
                "result": "Ergebnis"
            },
            "google": {
                "title": "Google",
                "feeds": {
                    "merchant": {
                        "name": "Merchant Center-Feed",
                        "desc": "Produkte bei Google Shopping verkaufen",
                        "result": "Steigern Sie den Umsatz durch eine optimierte Feldstruktur"
                    },
                    "promotions": {
                        "name": "Promotions-Feed",
                        "desc": "Zeigen Sie Ihrem Kunden Werbeaktionen",
                        "result": "Steigern Sie die Klickrate und steigern Sie die Conversions"
                    },
                    "localInventory": {
                        "name": "Feed für lokale Inventaranzeigen",
                        "desc": "Zeigen Sie Ihre Produkte auf Google Maps",
                        "result": "Steigern Sie den Offline-Umsatz und bringen Sie neue Kunden in Ihr Geschäft"
                    },
                    "productRatings": {
                        "name": "Produktbewertungen füttern",
                        "desc": "Stellen Sie Ihre Produktbewertungen und -bewertungen Nutzern von Google zur Verfügung",
                        "result": "höhere Klickrate"
                    },
                    "dynamicRemarketing": {
                        "name": "Dynamische Remarketing-Feeds",
                        "desc": "Anzeigen für Personen schalten, die Ihre Website zuvor besucht haben",
                        "result": "Umsatz steigern"
                    },
                    "page": {
                        "name": "Seitenvorschub",
                        "desc": "Seiten-Feed für dynamische Suchanzeigen",
                        "result": "Steigern Sie die Leistung Ihrer Suchkampagne"
                    },
                    "sitemap": {
                        "name": "Sitemap-Feed",
                        "desc": "URL-Feed zur Suchmaschinenoptimierung",
                        "result": "Erhöhen Sie Ihre Sichtbarkeit in der Google-Suche"
                    },
                    "manufacturer": {
                        "name": "Hersteller Feed",
                        "desc": "Stellen Sie Google bessere und genauere Informationen zu Ihren Produkten zur Verfügung",
                        "result": "Erhalten Sie umfassende Analysen und verbessern Sie Ihre Präsenz bei Google"
                    }
                }
            },
            "facebook": {
                "title": "Facebook",
                "feeds": {
                    "dynamicAds": {
                        "name": "Feed für dynamische Anzeigen",
                        "desc": "Anzeigen für Personen schalten, die Ihre Website zuvor besucht haben",
                        "result": "Umsatz steigern"
                    },
                    "pageShop": {
                        "name": "Page Shop Feed",
                        "desc": "Produkte auf Facebook zeigen und verkaufen",
                        "result": "Umsatz steigern"
                    },
                    "marketplace": {
                        "name": "Marktplatz-Feed",
                        "desc": "Produkte auf dem Facebook-Marktplatz zeigen und verkaufen",
                        "result": "Umsatz steigern"
                    }
                }
            },
            "instagram": {
                "title": "instagram",
                "feeds": {
                    "shopping": {
                        "name": "Einkaufsfutter",
                        "desc": "Produkte auf Instagram zeigen und verkaufen",
                        "result": "Umsatz steigern"
                    }
                }
            },
            "amazon": {
                "title": "Amazonas",
                "feeds": {
                    "product": {
                        "name": "Produkt-Feed",
                        "desc": "Produkte bei Amazon zeigen und verkaufen",
                        "result": "Umsatz steigern"
                    }
                }
            },
            "ebay": {
                "title": "Ebay",
                "feeds": {
                    "product": {
                        "name": "Produkt-Feed",
                        "desc": "Produkte bei Ebay zeigen und verkaufen",
                        "result": "Umsatz steigern"
                    }
                }
            },
            "criteo": {
                "title": "Criteo",
                "feeds": {
                    "product": {
                        "name": "Produkt-Feed",
                        "desc": "Produkte online verkaufen",
                        "result": "Umsatz steigern"
                    }
                }
            }
        },
        "cta2": {
            "title": "STEIGERN SIE IHREN VERKAUF MIT FEED.STUDIO",
            "button": "ERHALTEN SIE 1.000 USD KREDIT",
            "subtitle": "KEINE KREDITKARTE ERFORDERLICH!"
        },
        "meta": {
            "title": "Feed Studio »XML Feed Manager-Tool - XML-Produkt-Feed-Generator - Google Shopping Feed",
            "desc": {
                "short": "Google Shopping Feed Generator-Tool.",
                "long": "Google Shopping Feed Generator"
            }
        }
    },
    "pricing": {
        "plugin": {
            "col1": {
                "header": "EIGENSCHAFTEN \/ PLANE",
                "row1": "Unbegrenzte Produkte",
                "row2": "Google Ads - Shopping-Feed",
                "row3": "Google Ads - Dynamischer Display-Anzeigenfeed",
                "row4": "Google Ads - DSA Pagefeed",
                "row5": "Facebook-Produkt-Feed",
                "row6": "Arukereso.hu Produkt-Feed",
                "row7": "Criteo Produkt-Feed",
                "row8": "RTB House Produkt-Feed",
                "row9": "Google Ads Automation",
                "row10": "Kundenspezifische Importe und Exporte",
                "row11": "Webinare",
                "row12": "Engagierter Account Manager",
                "row13": "Willkommensgutschein",
                "row14": "Monatliche Gebühr",
                "row15": "Preis pro 10.000 Operation"
            },
            "agency": "Ihr gesamtes Geld zurück, wenn wir nicht innerhalb von 3 Monaten die erwarteten Ergebnisse liefern.",
            "col2": {
                "header": "LEBENSFREI",
                "row6": "Wöchentlich",
                "cta": "Anmeldung",
                "row1": "Eingebautes Armaturenbrett",
                "row14": "9 $",
                "row15": "n \/ a"
            },
            "col3": {
                "header": "PRÄMIE",
                "row6": "Täglich",
                "cta": "Anmeldung",
                "row1": "Premium-Dashboard",
                "row13": "300 Dollar",
                "row14": "9 $",
                "row15": "$ 1"
            },
            "col4": {
                "row6": "Echtzeit",
                "cta": "Anmeldung",
                "row1": "VIP-Dashboard",
                "row10": "Private Webinare",
                "header": "Frühbucher Premium",
                "row13": "1000 Dollar",
                "row14": "9 $",
                "row15": "$ 1"
            }
        },
        "agency": {
            "allin": {
                "li6": "Chatbot",
                "li9": "Geld-Zurück-Garantie*"
            }
        },
        "title": "Pläne, die zu Ihrem gesamten passen \n Werbehaushalt, Werbebudget",
        "sec2": {
            "title": "Beherrschen Sie Ihr Online-Wachstum",
            "cta": "KOSTENLOS ERHALTEN"
        },
        "sec6": {
            "title": "Wenn Sie technischen Support benötigen, besuchen Sie unser Support-Dashboard.",
            "cta": "UNTERSTÜTZUNG"
        },
        "header": {
            "title": "PREISGESTALTUNG",
            "sub": "Zahlen Sie wenn sie hinausgehen"
        }
    },
    "testimonials": {
        "title": "Was unsere Benutzer sagen",
        "1": {
            "desc": "Wir arbeiten seit fast einem Jahr gemeinsam an Google Ad-Kampagnen. Wir haben immer schnelle und professionelle Arbeit von der Agentur erfahren, sie sind sehr kompetent, kooperativ und bemühen sich, unsere Bedürfnisse voll zu erfüllen. Sie bringen nicht nur innovative Lösungen, sondern sind auch ruhig genug, um die Lösungen für komplexe Probleme und die Ergebnisse von Kampagnen ausführlich zu erläutern.",
            "name": "Adam Dallos",
            "pos": "PwC, Assistant Manager, Marketing, Kommunikation Geschäftsentwicklung"
        }
    },
    "footer": {
        "description": ""
    },
    "menu": {
        "navigation": "NAVIGATION",
        "signin": "Anmelden",
        "features": "Eigenschaften",
        "pricing": "Preisgestaltung",
        "blog": "Blog",
        "terms-conditions": "Terms & amp; Bedingungen",
        "partners": "PARTNERSCHAFT",
        "affiliate": "Affiliate",
        "whitelabel": "weißes Etikett",
        "introducing": "Einführender Partner",
        "findus": "Finden Sie uns auf",
        "home": "Zuhause",
        "dashboard": "Instrumententafel",
        "contact": "Kontakt"
    },
    "features": {
        "title": "Werbung bei Google <br\/> einfacher als je zuvor",
        "subtitle": "",
        "sec1": {
            "title": "Warum bei Google werben?",
            "col1": {
                "subtitle": "Schlage deine Konkurrenz",
                "desc": "Jeden Tag, wenn Menschen nach Ihrer Marke oder Ihren Produkten suchen, bietet Ihnen Google die Möglichkeit, Ihr Unternehmen von der Konkurrenz abzuheben."
            },
            "col2": {
                "subtitle": "Erreichen Sie ideale Kunden",
                "desc": "Google ist eine der besten Werbeplattformen, um die richtigen Nutzer zur richtigen Zeit auf allen Engagement-Ebenen anzusprechen."
            },
            "col3": {
                "subtitle": "Steigern Sie Ihren Umsatz",
                "desc": "Der Traffic auf Ihrer Website wird durch Google mit Besuchern, die leicht zu Kunden werden können, erheblich zunehmen und Ihren Umsatz und Ihr Online-Geschäft steigern."
            }
        },
        "sec2": {
            "title": "Beherrschen Sie Ihr Online-Wachstum",
            "cta": "KOSTENLOS AUSPROBIEREN"
        }
    },
    "getstarted": {
        "title": "Beginnen Sie mit 3 einfachen Schritten",
        "col1": {
            "subtitle": "1. Herunterladen",
            "desc": ""
        },
        "col2": {
            "subtitle": "2. Aktivieren",
            "desc": ""
        },
        "col3": {
            "subtitle": "3. Verfolgen",
            "desc": ""
        }
    },
    "dashboard": {
        "title": "Instrumententafel",
        "domainsettings": {
            "title": "DOMAIN EINSTELLUNGEN",
            "subtitle1": "Neue Domain hinzufügen",
            "input1": "Ihre neue Domain",
            "cta1": "Hinzufügen",
            "subtitle2": "Ihre aktuellen Domains",
            "currentdomains1": "Domain",
            "currentdomains2": "API-Schlüssel"
        },
        "subscription": {
            "title": "ABONNEMENT",
            "subtitle1": "Balance",
            "remaining": "verbleibend"
        },
        "tab2": {
            "desc1": "Sie nutzen derzeit unsere",
            "desc2": "Abonnement",
            "cta": "SCHALTERABONNEMENT"
        },
        "billing": {
            "title": "Rechnungsdetails",
            "input": {
                "name": "Abrechnungsname",
                "vat": "Umsatzsteuer-Identifikationsnummer",
                "address": "Adresse",
                "city": "Stadt",
                "zip": "Postleitzahl",
                "country": "Land"
            }
        },
        "user": {
            "title": "BENUTZEREINSTELLUNGEN",
            "subtitle": "Ihre Anmeldedaten",
            "lastlogin": "Letzte Anmeldung:"
        }
    },
    "cta": {
        "update": "AKTUALISIEREN"
    },
    "input": {
        "email": "E-Mail-Addresse",
        "password": "Passwort",
        "passwordrepeat": "Passwort wiederholen"
    },
    "partners": {
        "affiliate": {
            "subtitle": "Exklusive Boni & Promotions <br> zur Steigerung der Affiliates",
            "substitle2": "Eine Fusion von Affiliate-Marketing-Tools und Zahlungsplänen, um Ihre Gewinne zu steigern!"
        },
        "affilieate": {
            "description": ""
        },
        "whitelabel": {
            "title": "White-Label-Partnerschaft"
        },
        "introducing": {
            "subtitle": "",
            "desc2": "Für weitere Informationen wenden Sie sich bitte an unser spezialisiertes Team unter:"
        }
    },
    "url": {
        "features": "Eigenschaften",
        "pricing": "Preisgestaltung",
        "partnership": {
            "affiliate": "Patnership \/ Affiliate",
            "whitelabel": "Patnership \/ Whitelabel",
            "introductory": "Patnership \/ Einführung"
        },
        "contact": "Kontakt",
        "login": "Anmeldung"
    },
    "b2c": {
        "main": {
            "title": "<b> FEED <\/b> .MANAGEMENT"
        },
        "cta1": {
            "title": "EARLY BIRD PROMOTION",
            "description": "Für eine begrenzte Zeit erhält jeder Frühbucher 1000 USD Willkommensguthaben, um das Feed Studio Premium-Paket zu testen. <br\/> <br\/> Keine Probezeit, keine Kreditkarte erforderlich.",
            "button": "ERHALTEN SIE 1.000 USD KREDIT"
        },
        "sec1": {
            "title": "Wie können Sie feed.studio verwenden?",
            "col1": {
                "title": "<br\/> Sie sich an und geben Sie uns Ihr <br\/>",
                "desc": "Geben Sie uns Ihre Kontakt- und Feeddaten, um Ihr Konto einzurichten"
            },
            "col2": {
                "title": "Unsere Manager richten <br\/> Ihren Export-Feed ein",
                "desc": "Aus Ihrem Roh-Feed erstellen wir Ihren Export-Feed"
            },
            "col3": {
                "title": "Viel Spaß (die Ergebnisse)",
                "desc": "Lehnen Sie sich zurück und wissen Sie, dass unsere Experten hart daran arbeiten, Ihnen das beste Futter zu liefern, das Sie benötigen."
            }
        },
        "sec2": {
            "title": "Melden Sie sich für den Frühbuchertester an",
            "infoList": {
                "item1": "Keine zeitliche Begrenzung für Willkommensguthaben",
                "item2": "Wir berechnen \/ berechnen Ihnen keine Gebühren am Ende der Begrüßungsfrist",
                "item3": "Kein Produkt- oder Exportlimit",
                "item4": "Managed Service - Sie müssen die Plattform nicht lernen",
                "item5": "Sie können Ihr Konto jederzeit löschen",
                "item6": "Sie können benutzerdefinierte Exportformate anfordern"
            },
            "form": {
                "name": "Name",
                "email": "Email",
                "phone": "Telefon",
                "feedUrl": "Feed-URL",
                "policyLabel": "Ich akzeptiere die allgemeinen Geschäftsbedingungen",
                "marketingLabel": "Ich möchte den Feed.Studio-Newsletter abonnieren",
                "button": "frühzeitigen Zugang erhalten",
                "feedType": ""
            }
        },
        "meta": {
            "title": "XML Product Feed Generator, Google Shopping und Facebook Feed",
            "desc": {
                "short": "Erstellen und optimieren Sie ganz einfach Ihre Google- und Facebook-Feeds.",
                "long": "Google Shopping Feed, Optimierung von Produkt-Feeds."
            }
        }
    },
    "b2b": {
        "main": {
            "title": "<b> FEED <\/b> .PARTNER",
            "description": "Feed.Studio für E-Commerce-Experten und Agenturen. Wir suchen regionale Partner für eine erfolgreiche Zukunft. <\/br> Ihren Umsatz und verkaufen Sie mehr mit Feed.Studio!",
            "button": "MEHR VERKAUFEN"
        },
        "sec1": {
            "title": "Warum mit uns arbeiten?",
            "col1": {
                "title": "mehr verkaufen",
                "desc": "mehr verkaufen"
            },
            "col2": {
                "title": "<\/br> deine <\/br> Konkurrenten",
                "desc": "sei der Erste"
            },
            "col3": {
                "title": "<\/br> Ihre <\/br> Kunden zufrieden",
                "desc": "Qualitätskontrolle"
            },
            "col4": {
                "title": "Zeit sparen",
                "desc": "mit unserem mamaged Service"
            }
        },
        "secPartner": {
            "title": "Partnerschaften",
            "header": {
                "affiliate": "Affiliate",
                "gold": "Gold",
                "exclusive": "Exklusiv",
                "custom": "Benutzerdefiniert"
            },
            "requirements": {
                "title": "Bedarf",
                "newClient": "Neukunden \/ Jahr",
                "certifiedusers": "zertifizierter aktiver interner Benutzer"
            },
            "recognition": {
                "title": "Anerkennung",
                "visible": "sichtbar auf feed.studio",
                "trademark": "Nutzungsrechte für die Marke „feed studio“"
            },
            "training": {
                "title": "Schulungsvorteile",
                "biweekly": "zweiwöchentliche Treffen",
                "salesSupport": "Verkaufsunterstützung und -beratung"
            },
            "marketing": {
                "title": "Marketingvorteile",
                "brand": "Marke auf lokalen Kampagnen",
                "customerStories": "Kundenerfolgsgeschichten"
            },
            "sales": {
                "title": "Verkaufsvorteile",
                "materials": "Verkaufsunterlagen",
                "accountManagers": "dedizierter Account Manager",
                "leads": "lokale Leads",
                "affiliateBonus": "BONUS FÜR MITGLIEDER",
                "feedStudioBonus": "Bonus auf Feed Studio"
            },
            "contactUs": "Kontaktiere uns",
            "signUp": "Anmelden"
        },
        "sec3": {
            "title": "Wie kann man unser Partner sein?",
            "col1": {
                "title": "Anmelden",
                "desc": ""
            },
            "col2": {
                "title": "Nehmen Sie an unseren Verkaufs- und Plattformschulungen teil",
                "desc": ""
            },
            "col3": {
                "title": "Verwalten Sie unsere Leads und Kunden",
                "desc": ""
            }
        },
        "sec4": {
            "title": "Melden Sie sich für das PARTNERPROGRAMM an",
            "infoList": {
                "item1": "Zugriff auf Feed Studio Erörtern von Strategien, Vertriebs- und Serviceproblemen",
                "item2": "Sichtbarkeit und Anerkennung als offizieller Partner auf der Feed Studio-Partnerseite",
                "item3": "Zugriff auf das Verkaufs-Dashboard"
            },
            "form": {
                "plan": {
                    "affiliate": "Affiliate",
                    "gold": "Gold",
                    "custom": "Benutzerdefiniert"
                },
                "name": "Name",
                "email": "Email",
                "phone": "Telefon",
                "message": "Botschaft",
                "policyLabel": "Ich akzeptiere die allgemeinen Geschäftsbedingungen",
                "marketingLabel": "Ich möchte den Feed.Studio-Newsletter abonnieren",
                "button": "Frühzeitigen Zugang erhalten"
            }
        }
    },
    "registration": {
        "meta": {
            "title": "Melden Sie sich kostenlos an, um Studio zu füttern",
            "desc": {
                "short": "Melden Sie sich an und erhalten Sie ein Guthaben von 1000 USD für Feed Studio.",
                "long": "Melden Sie sich an und erhalten Sie ein Willkommensguthaben von 1000 USD für die Nutzung von Feed Studio."
            }
        }
    },
    "earlybirdreg": {
        "form": {
            "infoList": {
                "desc1": "",
                "desc2": "",
                "item1": "",
                "item2": "",
                "item3": "",
                "item4": "",
                "item5": "",
                "item6": "",
                "item7": ""
            }
        }
    },
    "login": {
        "title": "",
        "subtitle": "",
        "subtitle2": "",
        "googleButton": ""
    }
};