import ProjectService from "services/ProjectService"
import UserService from "services/UserService"

export const types = {
    REQUEST_SIGN_IN: "REQUEST_SIGN_IN",
    REQUEST_SIGN_IN_ACCESS_TOKEN: "REQUEST_SIGN_IN_ACCESS_TOKEN",
    REQUEST_USER_DATA: "REQUEST_USER_DATA",
    SIGN_IN_SUCCESSFUL: "SIGN_IN_SUCCESSFUL",
    SIGN_IN_FAILED: "SIGN_IN_FAILED",
    SELECT_PROJECT: "SELECT_PROJECT",
    REFRESH_USER: "REFRESH_USER",
    REFRESH_PROJECTS: "REFRESH_PROJECTS",
    FETCH_CAMPAIGNS: "FETCH_CAMPAIGNS",
    SELECT_CAMPAIGNS: "SELECT_CAMPAIGNS"
}

export function selectProject(project) {
    return {
        type: types.SELECT_PROJECT,
        project
    }
}

export function signInSuccessful(user) {
    return {
        type: types.SIGN_IN_SUCCESSFUL,
        user
    }
}

export function signInFailed() {
    return {
        type: types.SIGN_IN_FAILED
    }
}

export function refreshUser(user) {
    return {
        type: types.REFRESH_USER,
        user
    }
}

export function refreshProjects(projects) {
    return {
        type: types.REFRESH_PROJECTS,
        projects
    }
}

export function fetchCampaigns(campaigns) {
    return {
        type: types.FETCH_CAMPAIGNS,
        campaigns
    }
}

export function selectCampaign(campaign) {
    return {
        type: types.SELECT_CAMPAIGNS,
        campaign
    }
}

export function doFetchCampaigns(user, project) {
    return async (dispatch) => {
        const projectService = new ProjectService(user)
        let campaignsResponse = await projectService.getGoogleAdsCampaigns(project.id, 0, 999)

        if(campaignsResponse.data.data.length >= 1) {
            dispatch(fetchCampaigns(campaignsResponse.data.data))
        }
    }
}

export function doRefreshProjects(user) {
    return async (dispatch) => {
        const userService = new UserService(user)
        let projectsResponse = await userService.getProjects(user.id)

        dispatch(refreshProjects(projectsResponse.data.data))
    }
}

export function requestUserData(user) {
    return async (dispatch) => {
        dispatch({
            type: types.REQUEST_USER_DATA,
            user
        })

        const userService = new UserService(user)
        let userResponse = await userService.getUserData(user.id)
        let userdata = userResponse.data.data

        userdata.token = user.token

        return userdata
    }
}

export function doRefreshUser(user) {
    return async (dispatch) => {
        let userdata = await dispatch(requestUserData(user))

        dispatch(refreshUser(userdata))
    }
}

export function requestSignIn(authCode) {
    return async (dispatch) => {
        dispatch({
            type: types.REQUEST_SIGN_IN,
            authCode
        })

        const userService = new UserService(null)

        try {
            let response = await userService.googleAuth(authCode)
            let user = response.data.data
            

            let userdata = await dispatch(requestUserData(user))

            dispatch(signInSuccessful(userdata))

            return userdata
        }
        catch(e) {
            console.log(e)
        }
    }
}

export function requestSignInWithAccessToken(accessToken) {
    return async (dispatch) => {
        dispatch({
            type: types.REQUEST_SIGN_IN_ACCESS_TOKEN,
            accessToken
        })

        const userService = new UserService(null)

        try {
            let response = await userService.authWithAccessToken(accessToken)
            let user = response.data.data
            
            let userdata = await dispatch(requestUserData(user))

            dispatch(signInSuccessful(userdata))

            return userdata
        }
        catch(e) {
            console.log(e)
        }
    }
}