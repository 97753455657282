export default {
    "home": {
        "sec1": {
            "title": "अपने राजस्व को बढ़ाएँ। सहजता से।",
            "cta": "मुफ्त में डाउनलोड करें",
            "col1": {
                "title": "अपने <br\/> राजस्व को बढ़ावा दें",
                "desc": "अपनी फ़ीड संरचना का अनुकूलन करें और अपना राजस्व 5-30% बढ़ाएँ"
            },
            "col2": {
                "title": "खुली नई बिक्री <br\/> चैनल",
                "desc": "नए बिक्री चैनल के साथ अधिक बिक्री करें"
            },
            "col3": {
                "title": "<br\/> समय सीमा के बिना निःशुल्क प्रयास करें",
                "desc": "मूल्य निर्धारण विधि के रूप में आप बाजार पर सबसे लचीला समाधान देता है"
            },
            "col4": {
                "title": "अपना <br\/> समय बचाएं",
                "desc": "हमारे सलाहकार आपके उत्पाद फ़ीड का निर्माण और अनुकूलन करते हैं"
            }
        },
        "sec2": {
            "title": "आयात और आसानी के साथ निर्यात",
            "col1": {
                "title": "आपूर्तिकर्ता फ़ीड <br\/> प्रबंधन",
                "desc": "फ़ीड स्टूडियो एक आयात योग्य फ़ीड में विभिन्न उत्पाद फ़ीड को जोड़ता है"
            },
            "col2": {
                "title": "100 से अधिक <br\/> अन्य प्रारूपों का उपयोग करें",
                "desc": "अलग Google शॉपिंग और इंस्टाग्राम विवरणों की आवश्यकता है? एक समस्या नहीं है"
            },
            "col3": {
                "title": "प्लेटफ़ॉर्म द्वारा अपने उत्पाद <br\/> डेटा का अनुकूलन करें",
                "desc": "क्या आपको इंस्टाग्राम की दुकान की तुलना में थोड़ा अलग Google खरीदारी विवरण की आवश्यकता है? कोई दिक्कत नहीं है"
            },
            "col4": {
                "title": "इतिहास बदलें",
                "desc": "गलत मूल्य का विज्ञापन क्यों करते हैं? जब आपका उत्पाद अपडेट विफल हो गया? हमें जवाब पता है!"
            },
            "col5": {
                "title": "एसईओ अनुकूलन",
                "desc": "स्वचालित एसईओ मेटा टैग निर्माण और अनुकूलन।"
            },
            "col6": {
                "title": "प्रबंधित सेवा",
                "desc": "फ़ीड स्टूडियो एक आयात योग्य फ़ीड में विभिन्न उत्पाद फ़ीड को जोड़ता है"
            }
        },
        "sec3": {
            "title": "संघर्ष के बिना अपना व्यवसाय बढ़ाना चाहते हैं?"
        },
        "sec4": {
            "item2": {
                "subtitle": "फ़ीड प्रबंधन"
            },
            "item4": {
                "subtitle": "सांत्वना खोजें"
            }
        },
        "sec5": {
            "title": "Google विज्ञापन अभियानों को खूबसूरती से स्वचालित किया गया",
            "col1": {
                "title": "ब्रांड विज्ञापन",
                "desc": "क्या आप जानते हैं कि आपके 40% ग्राहक खोज परिणाम पृष्ठ पर आपकी साइट पर क्लिक नहीं करते हैं? ब्रांड अभियानों के साथ उन तक पहुंचें!"
            },
            "col2": {
                "title": "डायनामिक खोज विज्ञापन"
            },
            "col3": {
                "title": "शॉपिंग विज्ञापन"
            },
            "col4": {
                "title": "विज्ञापन प्रदर्शित करें",
                "desc": "उत्तरदायी प्रदर्शन, गतिशील रीमार्केटिंग और स्मार्ट विज्ञापनों के साथ Google प्रदर्शन नेटवर्क पर अपने लक्षित दर्शकों तक पहुंचें।"
            }
        },
        "sec6": {
            "title": "अपने विज्ञापन को स्वचालित करने के लिए तैयार हैं?",
            "cta": "मुक्त हो जाओ"
        },
        "sec7": {
            "title": "",
            "subtitle": "मुफ्त योजना में भी व्यापक सेवाएं"
        },
        "premier-partner-desc": "हम Google द्वारा एक शीर्ष प्रदर्शन करने वाली एजेंसी के रूप में प्रतिष्ठित हैं ताकि आप सुनिश्चित कर सकें कि आपकी Google विज्ञापन रणनीति अच्छे हाथों में है।",
        "sec8": {
            "title": "",
            "cta": "मुफ्त में डाउनलोड करें"
        },
        "main": {
            "title": "<b> FEED <\/b> .ME.BABY",
            "description": "पेशेवरों और एजेंसियों के लिए डिज़ाइन किए गए नवीनतम Google फ़ीड <br\/> प्रबंधक सॉफ़्टवेयर को आज़माने के लिए एक प्रारंभिक पक्षी खाते का अनुरोध करें। <br\/> एक सीमित समय के लिए आप मिल जाएगा <s> $ 300 <\/s> $ 1000 स्वागत क्रेडिट।",
            "button": "$ 1,000 क्रेडिट प्राप्त करें"
        },
        "cta1": {
            "title": "पूरी तरह से पदोन्नति",
            "description": "हमारे पहले उपयोगकर्ताओं को फीड स्टूडियो का परीक्षण करने के लिए $ 1000 का स्वागत क्रेडिट मिलता है",
            "button": "$ 1,000 क्रेडिट प्राप्त करें",
            "subtitle": "कोई क्रेडिट कार्ड की जरूरत है!"
        },
        "secFeeds": {
            "title": "आपको अपने फ़ीड में क्या तय करना चाहिए?",
            "header": {
                "channel": "चैनल",
                "export": "निर्यात",
                "description": "विवरण",
                "result": "परिणाम"
            },
            "google": {
                "title": "गूगल",
                "feeds": {
                    "merchant": {
                        "name": "व्यापारी केंद्र फ़ीड",
                        "desc": "Google शॉपिंग पर उत्पाद बेचते हैं",
                        "result": "अनुकूलित क्षेत्र संरचना द्वारा राजस्व में वृद्धि"
                    },
                    "promotions": {
                        "name": "प्रचार खिलाते हैं",
                        "desc": "अपने ग्राहक को पदोन्नति दिखाएं",
                        "result": "क्लिकथ्रू दर बढ़ाएँ और रूपांतरण बढ़ाएँ"
                    },
                    "localInventory": {
                        "name": "स्थानीय इन्वेंटरी विज्ञापन फ़ीड",
                        "desc": "Google मानचित्र पर अपने उत्पाद दिखाएं",
                        "result": "ऑफ़लाइन राजस्व बढ़ाएं, अपने स्टोर में नए ग्राहक प्राप्त करें"
                    },
                    "productRatings": {
                        "name": "उत्पाद रेटिंग फ़ीड",
                        "desc": "Google के उपयोगकर्ताओं के लिए अपने उत्पाद की रेटिंग और समीक्षाएं उपलब्ध कराएं",
                        "result": "उच्च क्लिकथ्रू दर"
                    },
                    "dynamicRemarketing": {
                        "name": "डायनामिक रीमार्केटिंग फ़ीड",
                        "desc": "उन लोगों को विज्ञापन दिखाएं जो पहले आपकी वेबसाइट पर गए हैं",
                        "result": "राजस्व बढ़ाएं"
                    },
                    "page": {
                        "name": "पेज फ़ीड",
                        "desc": "डायनामिक खोज विज्ञापनों के लिए पृष्ठ फ़ीड",
                        "result": "अपने अभियान के प्रदर्शन को बढ़ाएँ"
                    },
                    "sitemap": {
                        "name": "साइटमैप फ़ीड",
                        "desc": "खोज इंजन अनुकूलन के लिए URL फ़ीड",
                        "result": "Google खोज में अपनी दृश्यता बढ़ाएं"
                    },
                    "manufacturer": {
                        "name": "निर्माता फ़ीड",
                        "desc": "Google को अपने उत्पादों के बारे में बेहतर और सटीक जानकारी प्रदान करें",
                        "result": "व्यापक विश्लेषण प्राप्त करें और Google पर अपनी उपस्थिति सुधारें"
                    }
                }
            },
            "facebook": {
                "title": "फेसबुक",
                "feeds": {
                    "dynamicAds": {
                        "name": "डायनामिक विज्ञापन फ़ीड",
                        "desc": "उन लोगों को विज्ञापन दिखाएं जो पहले आपकी वेबसाइट पर गए हैं",
                        "result": "राजस्व बढ़ाएं"
                    },
                    "pageShop": {
                        "name": "पृष्ठ दुकान फ़ीड",
                        "desc": "फेसबुक पर लोगों को उत्पाद दिखाना और बेचना",
                        "result": "राजस्व बढ़ाएं"
                    },
                    "marketplace": {
                        "name": "मार्केटप्लेस फ़ीड",
                        "desc": "फेसबुक मार्केटप्लेस पर लोगों को उत्पाद दिखाना और बेचना",
                        "result": "राजस्व बढ़ाएं"
                    }
                }
            },
            "instagram": {
                "title": "instagram",
                "feeds": {
                    "shopping": {
                        "name": "खरीदारी फ़ीड",
                        "desc": "इंस्टाग्राम पर लोगों को उत्पाद दिखाना और बेचना",
                        "result": "राजस्व बढ़ाएं"
                    }
                }
            },
            "amazon": {
                "title": "वीरांगना",
                "feeds": {
                    "product": {
                        "name": "उत्पाद फ़ीड",
                        "desc": "अमेज़न पर लोगों को उत्पाद दिखाएं और बेचें",
                        "result": "राजस्व बढ़ाएं"
                    }
                }
            },
            "ebay": {
                "title": "ईबे",
                "feeds": {
                    "product": {
                        "name": "उत्पाद फ़ीड",
                        "desc": "Ebay पर लोगों को उत्पाद दिखाना और बेचना",
                        "result": "राजस्व बढ़ाएं"
                    }
                }
            },
            "criteo": {
                "title": "Criteo",
                "feeds": {
                    "product": {
                        "name": "उत्पाद फ़ीड",
                        "desc": "ऑनलाइन उत्पाद बेचते हैं",
                        "result": "राजस्व बढ़ाएं"
                    }
                }
            }
        },
        "cta2": {
            "title": "FEED.STUDIO के साथ अपनी बिक्री पर ध्यान दें",
            "button": "$ 1,000 क्रेडिट प्राप्त करें",
            "subtitle": "कोई क्रेडिट कार्ड की जरूरत है!"
        },
        "meta": {
            "title": "फीड स्टूडियो »एक्सएमएल फीड मैनेजर टूल - एक्सएमएल प्रोडक्ट फीड जनरेटर - गूगल शॉपिंग फीड",
            "desc": {
                "short": "Google शॉपिंग फ़ीड जनरेटर उपकरण।",
                "long": "Google शॉपिंग फ़ीड जनरेटर"
            }
        }
    },
    "pricing": {
        "plugin": {
            "col1": {
                "header": "फीचर्स \/ PLANS",
                "row1": "असीमित उत्पादों",
                "row2": "Google विज्ञापन - खरीदारी फ़ीड",
                "row3": "Google विज्ञापन - डायनामिक डिस्प्ले विज्ञापन फ़ीड",
                "row4": "Google विज्ञापन - DSA पेजफीड",
                "row5": "फेसबुक उत्पाद फ़ीड",
                "row6": "Arukereso.hu उत्पाद फ़ीड",
                "row7": "क्रिटो उत्पाद फ़ीड",
                "row8": "RTB हाउस उत्पाद फ़ीड",
                "row9": "Google विज्ञापन स्वचालन",
                "row10": "कस्टम आयात और निर्यात",
                "row11": "वेबिनार",
                "row12": "समर्पित खाता प्रबंधक",
                "row13": "आपका स्वागत है वाउचर",
                "row14": "मासिक शुल्क",
                "row15": "10,000 ऑपरेशन प्रति मूल्य"
            },
            "agency": "अगर हम 3 महीने में अपेक्षित परिणाम नहीं देते हैं तो आपके सारे पैसे वापस मिल जाएंगे।",
            "col2": {
                "header": "लाइफटाइम फ्री",
                "row6": "साप्ताहिक",
                "cta": "पंजीकरण",
                "row1": "बिल्ट-इन डैशबोर्ड",
                "row14": "$ 9",
                "row15": "na"
            },
            "col3": {
                "header": "प्रीमियम",
                "row6": "रोज",
                "cta": "पंजीकरण",
                "row1": "प्रीमियम डैशबोर्ड",
                "row13": "$ 300",
                "row14": "$ 9",
                "row15": "$ 1"
            },
            "col4": {
                "row6": "रियल टाइम",
                "cta": "पंजीकरण",
                "row1": "वीआईपी डैशबोर्ड",
                "row10": "निजी वेबिनार",
                "header": "प्रारंभिक पक्षी प्रीमियम",
                "row13": "$ 1000",
                "row14": "$ 9",
                "row15": "$ 1"
            }
        },
        "agency": {
            "allin": {
                "li6": "chatbot",
                "li9": "पैसे वापिस करने की गारंटी*"
            }
        },
        "title": "ऐसी योजनाएँ जो आपके पूरे लायक हों \n विपणन बजट",
        "sec2": {
            "title": "अपनी ऑनलाइन ग्रोथ को डोमिनेट करें",
            "cta": "मुक्त हो जाओ"
        },
        "sec6": {
            "title": "यदि आपको तकनीकी सहायता की आवश्यकता है, तो हमारे समर्थन डैशबोर्ड पर जाएं।",
            "cta": "सहयोग"
        },
        "header": {
            "title": "मूल्य निर्धारण",
            "sub": "उपयोगानुसार भुगतान करो"
        }
    },
    "testimonials": {
        "title": "हमारे उपयोगकर्ता क्या कहते हैं",
        "1": {
            "desc": "अब हम लगभग एक साल से Google विज्ञापन अभियानों पर एक साथ काम कर रहे हैं। हमने हमेशा एजेंसी से त्वरित और पेशेवर काम का अनुभव किया है, वे बहुत सक्षम हैं, सहकारी हैं और हमारी जरूरतों को पूरी तरह से पूरा करने के लिए देख रहे हैं। नवीन समाधान लाने के अलावा, वे जटिल समस्याओं और अभियानों के परिणामों के लिए गहराई से समझाने के लिए पर्याप्त रूप से शांत हैं।",
            "name": "एडम डेलोस",
            "pos": "पीडब्ल्यूसी, सहायक प्रबंधक, विपणन, संचार व्यवसाय विकास"
        }
    },
    "footer": {
        "description": ""
    },
    "menu": {
        "navigation": "पथ प्रदर्शन",
        "signin": "साइन इन करें",
        "features": "विशेषताएं",
        "pricing": "मूल्य निर्धारण",
        "blog": "ब्लॉग",
        "terms-conditions": "नियम एवं शर्तें",
        "partners": "साझेदारी",
        "affiliate": "संबद्ध",
        "whitelabel": "सफेद उपनाम",
        "introducing": "पेश है साथी",
        "findus": "हमें यहाँ तलाशें",
        "home": "घर",
        "dashboard": "डैशबोर्ड",
        "contact": "संपर्क करें"
    },
    "features": {
        "title": "Google <br\/> पर विज्ञापन को पहले से आसान बना दिया गया",
        "subtitle": "",
        "sec1": {
            "title": "Google पर विज्ञापन क्यों?",
            "col1": {
                "subtitle": "अपनी प्रतियोगिता को हराया",
                "desc": "हर दिन लोग आपके ब्रांड या उत्पादों को खोज रहे हैं, Google आपको अपनी प्रतियोगिता के सामने अपना व्यवसाय प्राप्त करने की क्षमता देता है।"
            },
            "col2": {
                "subtitle": "आदर्श ग्राहकों तक पहुंचें",
                "desc": "Google सभी सगाई स्तरों पर सही उपयोगकर्ताओं को सही समय पर लक्षित करने के लिए सबसे अच्छा विज्ञापन प्लेटफार्मों में से एक है।"
            },
            "col3": {
                "subtitle": "अपनी बिक्री बढ़ाएँ",
                "desc": "आपकी वेबसाइट पर ट्रैफ़िक Google के माध्यम से आगंतुकों के साथ बहुत बढ़ जाएगा जो आसानी से ग्राहकों में बदल सकते हैं, आपकी बिक्री और ऑनलाइन व्यवसाय को बढ़ा सकते हैं।"
            }
        },
        "sec2": {
            "title": "अपनी ऑनलाइन ग्रोथ को डोमिनेट करें",
            "cta": "निःशुल्क आजमाएं"
        }
    },
    "getstarted": {
        "title": "3 आसान चरणों के साथ आरंभ करें",
        "col1": {
            "subtitle": "1. डाउनलोड करें",
            "desc": ""
        },
        "col2": {
            "subtitle": "2. सक्रिय करें",
            "desc": ""
        },
        "col3": {
            "subtitle": "3. ट्रैक",
            "desc": ""
        }
    },
    "dashboard": {
        "title": "डैशबोर्ड",
        "domainsettings": {
            "title": "DOMAIN SETTINGS",
            "subtitle1": "नया डोमेन जोड़ें",
            "input1": "आपका नया डोमेन",
            "cta1": "जोड़ना",
            "subtitle2": "आपके वर्तमान डोमेन",
            "currentdomains1": "डोमेन",
            "currentdomains2": "एपीआई कुंजी"
        },
        "subscription": {
            "title": "अंशदान",
            "subtitle1": "संतुलन",
            "remaining": "शेष"
        },
        "tab2": {
            "desc1": "आप वर्तमान में हमारे उपयोग कर रहे हैं",
            "desc2": "अंशदान",
            "cta": "स्विच सदस्यता लें"
        },
        "billing": {
            "title": "बिलिंग विवरण",
            "input": {
                "name": "बिलिंग नाम",
                "vat": "वैट क्रमांक",
                "address": "पता",
                "city": "Faridabad",
                "zip": "पिन कोड",
                "country": "देश"
            }
        },
        "user": {
            "title": "उपयोगकर्ता सेटिंग",
            "subtitle": "आपका लॉगिन विवरण",
            "lastlogin": "अंतिम लॉगइन:"
        }
    },
    "cta": {
        "update": "अपडेट करें"
    },
    "input": {
        "email": "ईमेल पता",
        "password": "कुंजिका",
        "passwordrepeat": "पासवर्ड दोहराएं"
    },
    "partners": {
        "affiliate": {
            "subtitle": "विशेष बोनस और प्रचार <br> सहबद्धों बढ़ावा देने के लिए",
            "substitle2": "अपने लाभ को बढ़ाने के लिए सहबद्ध विपणन उपकरण और भुगतान योजनाओं का एक संलयन!"
        },
        "affilieate": {
            "description": ""
        },
        "whitelabel": {
            "title": "सफेद लेबल भागीदारी"
        },
        "introducing": {
            "subtitle": "",
            "desc2": "अधिक जानकारी के लिए कृपया हमारी विशेष टीम से संपर्क करें:"
        }
    },
    "url": {
        "features": "विशेषताएं",
        "pricing": "मूल्य निर्धारण",
        "partnership": {
            "affiliate": "patnership \/ सहबद्ध",
            "whitelabel": "patnership \/ Whitelabel",
            "introductory": "patnership \/ परिचयात्मक"
        },
        "contact": "संपर्क करें",
        "login": "लॉग इन करें"
    },
    "b2c": {
        "main": {
            "title": "<b> फ़ीड <\/b> प्रबंधन"
        },
        "cta1": {
            "title": "पूरी तरह से पदोन्नति",
            "description": "सीमित समय के लिए हर शुरुआती पक्षी उपयोगकर्ता को फीड स्टूडियो प्रीमियम पैकेज का परीक्षण करने के लिए $ 1000 का स्वागत क्रेडिट मिलता है। <br\/> <br\/> कोई परीक्षण अवधि नहीं, क्रेडिट कार्ड की आवश्यकता नहीं है।",
            "button": "$ 1,000 क्रेडिट प्राप्त करें"
        },
        "sec1": {
            "title": "आप feed.studio का उपयोग कैसे कर सकते हैं",
            "col1": {
                "title": "साइन अप करें और हमें अपना कच्चा फ़ीड <br\/> दें",
                "desc": "अपना खाता सेट करने के लिए हमें अपना संपर्क और फ़ीड डेटा दें"
            },
            "col2": {
                "title": "हमारे प्रबंधकों ने आपका निर्यात फ़ीड <br\/> सेट किया",
                "desc": "आपके कच्चे फ़ीड से हम आपका निर्यात फ़ीड बनाते हैं"
            },
            "col3": {
                "title": "आनंद लें (परिणाम)",
                "desc": "हमारे विशेषज्ञों को यह जानकर बैठें कि आप अपनी जरूरत का सर्वश्रेष्ठ चारा देने के लिए कड़ी मेहनत कर रहे हैं।"
            }
        },
        "sec2": {
            "title": "प्रारंभिक पक्षी परीक्षक के लिए साइन अप करें",
            "infoList": {
                "item1": "स्वागत क्रेडिट के लिए कोई समय सीमा नहीं",
                "item2": "स्वागत अवधि के अंत में हम आपसे शुल्क \/ बिल नहीं लेते हैं",
                "item3": "कोई उत्पाद या निर्यात सीमा नहीं",
                "item4": "प्रबंधित सेवा - आपको मंच सीखना नहीं है",
                "item5": "आप अपना खाता कभी भी हटा सकते हैं",
                "item6": "आप कस्टम निर्यात प्रारूप पूछ सकते हैं"
            },
            "form": {
                "name": "नाम",
                "email": "ईमेल",
                "phone": "फ़ोन",
                "feedUrl": "फ़ीड यूआरएल",
                "policyLabel": "मुझे नियम व शर्तें मंजूर हैं",
                "marketingLabel": "मैं Feed.Studio न्यूज़लेटर की सदस्यता लेना चाहूंगा",
                "button": "जल्दी पहुंच जाओ",
                "feedType": ""
            }
        },
        "meta": {
            "title": "XML उत्पाद फ़ीड जनरेटर, Google खरीदारी और फेसबुक फ़ीड",
            "desc": {
                "short": "अपना Google और Facebook फ़ीड आसानी से बनाएं और अनुकूलित करें।",
                "long": "Google शॉपिंग फ़ीड, उत्पाद फ़ीड ऑप्टिमाइज़ेशन।"
            }
        }
    },
    "b2b": {
        "main": {
            "title": "<b> फ़ीड <\/b> .PARTNERS",
            "description": "ई-कॉमर्स porfessionals और एजेंसियों के लिए डिज़ाइन किया गया Feed.Studio। हम एक सफल भविष्य के निर्माण के लिए क्षेत्रीय भागीदारों की तलाश कर रहे हैं। <\/br> अपने राजस्व को <\/br> , Feed.Studio के साथ अधिक बिक्री करें!",
            "button": "और बेचो"
        },
        "sec1": {
            "title": "हमारे साथ काम क्यों करें?",
            "col1": {
                "title": "और बेचो",
                "desc": "और बेचो"
            },
            "col2": {
                "title": "अपने <\/br> प्रतियोगियों को <\/br>",
                "desc": "पहले रहो"
            },
            "col3": {
                "title": "अपने <\/br> ग्राहकों को संतुष्ट करें",
                "desc": "गुणवत्ता नियंत्रण"
            },
            "col4": {
                "title": "समय बचाओ",
                "desc": "हमारी mamaged सेवा के साथ"
            }
        },
        "secPartner": {
            "title": "भागीदारी",
            "header": {
                "affiliate": "संबद्ध",
                "gold": "सोना",
                "exclusive": "अनन्य",
                "custom": "रिवाज"
            },
            "requirements": {
                "title": "आवश्यकताएँ",
                "newClient": "नए ग्राहक \/ वर्ष",
                "certifiedusers": "प्रमाणित सक्रिय आंतरिक उपयोगकर्ता"
            },
            "recognition": {
                "title": "मान्यता",
                "visible": "feed.studio पर दिखाई दे रहा है",
                "trademark": "\"फ़ीड स्टूडियो\" ट्रेडमार्क का उपयोग करने के अधिकार"
            },
            "training": {
                "title": "प्रशिक्षण लाभ",
                "biweekly": "द्वि-साप्ताहिक बैठकें",
                "salesSupport": "बिक्री समर्थन और मार्गदर्शन"
            },
            "marketing": {
                "title": "विपणन लाभ",
                "brand": "स्थानीय अभियानों पर ब्रांड",
                "customerStories": "ग्राहक सफलता की कहानियाँ"
            },
            "sales": {
                "title": "बिक्री लाभ",
                "materials": "बिक्री सामग्री",
                "accountManagers": "समर्पित खाता प्रबंधक",
                "leads": "स्थानीय लीड",
                "affiliateBonus": "विभिन्न ग्राहकों पर बोनस",
                "feedStudioBonus": "फीड स्टूडियो पर बोनस"
            },
            "contactUs": "संपर्क करें",
            "signUp": "साइन अप करें"
        },
        "sec3": {
            "title": "हमारा साथी कैसे बने?",
            "col1": {
                "title": "साइन अप करें",
                "desc": ""
            },
            "col2": {
                "title": "हमारी बिक्री और प्लेटफ़ॉर्म प्रशिक्षण में भाग लें",
                "desc": ""
            },
            "col3": {
                "title": "हमारे लीड और क्लाइंट को प्रबंधित करें",
                "desc": ""
            }
        },
        "sec4": {
            "title": "साझेदार कार्यक्रम के लिए साइन अप करें",
            "infoList": {
                "item1": "फ़ीड स्टूडियो तक पहुंच रणनीतियों, बिक्री और सेवाओं के मुद्दों पर चर्चा करती है",
                "item2": "दृश्यता और मान्यता फ़ीड स्टूडियो पार्टनर्स पेज पर एक आधिकारिक भागीदार के रूप में सूचीबद्ध होकर",
                "item3": "बिक्री डैशबोर्ड तक पहुंच"
            },
            "form": {
                "plan": {
                    "affiliate": "संबद्ध",
                    "gold": "सोना",
                    "custom": "रिवाज"
                },
                "name": "नाम",
                "email": "ईमेल",
                "phone": "फ़ोन",
                "message": "संदेश",
                "policyLabel": "मुझे नियम व शर्तें मंजूर हैं",
                "marketingLabel": "मैं Feed.Studio न्यूज़लेटर की सदस्यता लेना चाहूंगा",
                "button": "जल्दी पहुंचें"
            }
        }
    },
    "registration": {
        "meta": {
            "title": "मुफ्त के लिए साइन अप करने के लिए स्टूडियो फ़ीड",
            "desc": {
                "short": "साइन अप करें और फ़ीड स्टूडियो के लिए $ 1000 क्रेडिट प्राप्त करें।",
                "long": "फ़ीड स्टूडियो का उपयोग करने के लिए साइन अप करें और $ 1000 का स्वागत क्रेडिट प्राप्त करें।"
            }
        }
    },
    "earlybirdreg": {
        "form": {
            "infoList": {
                "desc1": "",
                "desc2": "",
                "item1": "",
                "item2": "",
                "item3": "",
                "item4": "",
                "item5": "",
                "item6": "",
                "item7": ""
            }
        }
    },
    "login": {
        "title": "",
        "subtitle": "",
        "subtitle2": "",
        "googleButton": ""
    }
};