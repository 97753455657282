export default {
    "home": {
        "sec1": {
            "title": "Zvýšte svoje príjmy. Bez námahy.",
            "cta": "Stiahnite si zadarmo",
            "col1": {
                "title": "zvýšiť svoje <br\/> príjmy",
                "desc": "optimalizovať štruktúru informačného kanála a zvýšiť svoje príjmy o 5 - 30%"
            },
            "col2": {
                "title": "otvoriť nové predajné kanály <br\/>",
                "desc": "predávať viac pomocou nových predajných kanálov"
            },
            "col3": {
                "title": "vyskúšajte zadarmo bez <br\/> časového limitu",
                "desc": "naša metóda stanovovania cien priebežne vám poskytuje najflexibilnejšie riešenie na trhu"
            },
            "col4": {
                "title": "ušetrite svoj <br\/> čas",
                "desc": "naši konzultanti vytvárajú a optimalizujú informačné kanály vašich produktov"
            }
        },
        "sec2": {
            "title": "import a export s ľahkosťou",
            "col1": {
                "title": "riadenie <br\/> dodávateľov <br\/>",
                "desc": "Aplikácia Feed Studio kombinuje rôzne informačné kanály produktov do jedného importovateľného informačného kanála"
            },
            "col2": {
                "title": "používať viac ako 100 <br\/> iných formátov",
                "desc": "Potrebujete rôzne popisy v Nákupoch Google a Instangrame? Žiadny problém"
            },
            "col3": {
                "title": "optimalizujte svoje údaje <br\/> podľa platformy",
                "desc": "Potrebujete trochu iný nákupný popis Google ako obchod s Instagramom? Žiaden problém"
            },
            "col4": {
                "title": "zmeniť históriu",
                "desc": "Prečo inzerovať nesprávnu cenu? Kedy zlyhala aktualizácia produktu? Poznáme odpoveď!"
            },
            "col5": {
                "title": "SEO optimalizácia",
                "desc": "Automatizované vytváranie a optimalizácia metaznačiek SEO."
            },
            "col6": {
                "title": "riadená služba",
                "desc": "Aplikácia Feed Studio kombinuje rôzne informačné kanály produktov do jedného importovateľného informačného kanála"
            }
        },
        "sec3": {
            "title": "Chcete rozvíjať svoje podnikanie bez boja?"
        },
        "sec4": {
            "item2": {
                "subtitle": "Správa krmív"
            },
            "item4": {
                "subtitle": "Vyhľadajte konzolu"
            }
        },
        "sec5": {
            "title": "Krásne automatizované kampane s reklamami Google",
            "col1": {
                "title": "Reklamy na značku",
                "desc": "Vedeli ste, že 40% vašich zákazníkov neklikne na váš web na stránke s výsledkami vyhľadávania? Oslovte ich kampaňami so značkou!"
            },
            "col2": {
                "title": "Dynamické reklamy vo vyhľadávacej sieti"
            },
            "col3": {
                "title": "Reklamy v službe Nákupy"
            },
            "col4": {
                "title": "Grafické reklamy",
                "desc": "Oslovte svoje cieľové publikum v Obsahovej sieti Google pomocou responzívneho remarketingu, dynamického remarketingu a inteligentných reklám."
            }
        },
        "sec6": {
            "title": "Ste pripravení automatizovať svoju reklamu?",
            "cta": "Získať zadarmo"
        },
        "sec7": {
            "title": "",
            "subtitle": "Komplexné služby aj v rámci bezplatného plánu"
        },
        "premier-partner-desc": "Spoločnosť Google je pre nás vynikajúcou agentúrou s najlepším výkonom, takže si môžete byť istí, že vaša stratégia pre reklamy Google je v dobrých rukách.",
        "sec8": {
            "title": "",
            "cta": "STIAHNUŤ ZDARMA"
        },
        "main": {
            "title": "<b> KRMIVO <\/b> .ME.BABY",
            "description": "Vyžiadajte si účet pre včasné vtáctvo a vyskúšajte najnovší softvér Google Feed <br\/> určený pre odborníkov a agentúry. <br\/> Na obmedzenú dobu získate uvítací kredit <s> 300 <\/s> 1 000 dolárov.",
            "button": "Získajte kredit 1 000 USD"
        },
        "cta1": {
            "title": "PODPORA DOČASNEJ BIRD",
            "description": "naši prví používatelia dostanú uvítací kredit vo výške 1 000 dolárov na testovanie aplikácie Feed Studio",
            "button": "ZÍSKEJTE ÚVER 1 000 USD",
            "subtitle": "NIE JE POTREBNÁ ŽIADNA ÚVEROVÁ KARTA!"
        },
        "secFeeds": {
            "title": "čo by ste mali opraviť vo svojich informačných kanáloch?",
            "header": {
                "channel": "channel",
                "export": "export",
                "description": "popis",
                "result": "následok"
            },
            "google": {
                "title": "Google",
                "feeds": {
                    "merchant": {
                        "name": "Informačný kanál Merchant Center",
                        "desc": "predávať produkty v službe Nákupy Google",
                        "result": "zvýšiť výnosy optimalizovanou štruktúrou poľa"
                    },
                    "promotions": {
                        "name": "Propagačný kanál",
                        "desc": "zobrazte propagácie zákazníkovi",
                        "result": "zvýšiť mieru prekliknutia a zvýšiť počet konverzií"
                    },
                    "localInventory": {
                        "name": "Informačný kanál miestnych inventárov",
                        "desc": "zobrazovať svoje produkty v Mapách Google",
                        "result": "zvýšte príjmy offline, získajte nových zákazníkov do svojho obchodu"
                    },
                    "productRatings": {
                        "name": "Informačný kanál hodnotenia produktov",
                        "desc": "Sprístupnite svoje hodnotenia a recenzie produktov používateľom Google",
                        "result": "vyššia miera prekliknutia"
                    },
                    "dynamicRemarketing": {
                        "name": "Dynamické remarketingové informačné kanály",
                        "desc": "zobrazovať reklamy ľuďom, ktorí predtým navštívili váš web",
                        "result": "zvýšiť príjmy"
                    },
                    "page": {
                        "name": "Zdroj stránky",
                        "desc": "Informačný kanál stránky pre dynamické reklamy vo vyhľadávacej sieti",
                        "result": "zvýšiť výkonnosť kampane vo vyhľadávacej sieti"
                    },
                    "sitemap": {
                        "name": "Informačný kanál súboru Sitemap",
                        "desc": "Informačný kanál URL na optimalizáciu vyhľadávacieho nástroja",
                        "result": "zviditeľnite sa vo Vyhľadávaní Google"
                    },
                    "manufacturer": {
                        "name": "Krmivo od výrobcu",
                        "desc": "poskytovať spoločnosti Google lepšie a presnejšie informácie o svojich produktoch",
                        "result": "získajte komplexnú analýzu a vylepšite svoju prítomnosť na Googli"
                    }
                }
            },
            "facebook": {
                "title": "Facebook",
                "feeds": {
                    "dynamicAds": {
                        "name": "Informačný kanál dynamických reklám",
                        "desc": "zobrazovať reklamy ľuďom, ktorí predtým navštívili váš web",
                        "result": "zvýšiť príjmy"
                    },
                    "pageShop": {
                        "name": "Informačný kanál stránky",
                        "desc": "zobrazovať a predávať produkty ľuďom na Facebooku",
                        "result": "zvýšiť príjmy"
                    },
                    "marketplace": {
                        "name": "Informačný kanál na trhu",
                        "desc": "zobrazovať a predávať produkty ľuďom na Facebooku",
                        "result": "zvýšiť príjmy"
                    }
                }
            },
            "instagram": {
                "title": "Instagram",
                "feeds": {
                    "shopping": {
                        "name": "Nákupný kanál",
                        "desc": "zobrazovať a predávať produkty ľuďom na Instagrame",
                        "result": "zvýšiť príjmy"
                    }
                }
            },
            "amazon": {
                "title": "Amazonka",
                "feeds": {
                    "product": {
                        "name": "Krmivo pre produkty",
                        "desc": "zobrazovať a predávať produkty ľuďom na webe Amazon",
                        "result": "zvýšiť príjmy"
                    }
                }
            },
            "ebay": {
                "title": "ebay",
                "feeds": {
                    "product": {
                        "name": "Krmivo pre produkty",
                        "desc": "zobrazovať a predávať produkty ľuďom na Ebay",
                        "result": "zvýšiť príjmy"
                    }
                }
            },
            "criteo": {
                "title": "Criteo",
                "feeds": {
                    "product": {
                        "name": "Krmivo pre produkty",
                        "desc": "predávať produkty online",
                        "result": "zvýšiť príjmy"
                    }
                }
            }
        },
        "cta2": {
            "title": "REZERVUJTE SVOJMI S KRMIVOM",
            "button": "ZÍSKEJTE ÚVER 1 000 USD",
            "subtitle": "NIE JE POTREBNÁ ŽIADNA ÚVEROVÁ KARTA!"
        },
        "meta": {
            "title": "Štúdio krmív »Nástroj na správu zdrojov XML - Generátor informačných kanálov XML - Nákupný kanál Google",
            "desc": {
                "short": "Nástroj na generovanie informačného kanála služby Nákupy Google.",
                "long": "Generátor informačného kanála služby Nákupy Google"
            }
        }
    },
    "pricing": {
        "plugin": {
            "col1": {
                "header": "VLASTNOSTI \/ PLÁNY",
                "row1": "Neobmedzené produkty",
                "row2": "Reklamy Google - informačný kanál v službe Nákupy",
                "row3": "Reklamy Google - informačný kanál dynamickej grafickej reklamy",
                "row4": "Reklamy Google - PageReed DSA",
                "row5": "Informačný kanál produktu Facebook",
                "row6": "Arukereso.hu Informačný kanál produktu",
                "row7": "Informačný kanál produktu Criteo",
                "row8": "Vlastný informačný kanál RTB",
                "row9": "Automatizácia reklám Google",
                "row10": "Vlastný dovoz a vývoz",
                "row11": "webináre",
                "row12": "Vyhradený správca účtu",
                "row13": "Vitajte voucher",
                "row14": "Mesačný poplatok",
                "row15": "Cena za 10 000 operácií"
            },
            "agency": "Všetky vaše peniaze späť, ak nedosiahneme očakávané výsledky do 3 mesiacov.",
            "col2": {
                "header": "Životnosť zadarmo",
                "row6": "týždenné",
                "cta": "registrácia",
                "row1": "Vstavaný prístrojový panel",
                "row14": "9 dolárov",
                "row15": "na"
            },
            "col3": {
                "header": "PREMIUM",
                "row6": "denná",
                "cta": "registrácia",
                "row1": "Prémiová palubná doska",
                "row13": "300 dolárov",
                "row14": "9 dolárov",
                "row15": "$ 1"
            },
            "col4": {
                "row6": "Reálny čas",
                "cta": "registrácia",
                "row1": "Prístrojová doska VIP",
                "row10": "Súkromné webináre",
                "header": "Prémia pre Early Bird",
                "row13": "1 000 USD",
                "row14": "9 dolárov",
                "row15": "$ 1"
            }
        },
        "agency": {
            "allin": {
                "li6": "Chatbot",
                "li9": "Garancia vrátenia peňazí*"
            }
        },
        "title": "Plány, ktoré zapadajú do vášho celku \n marketingový rozpočet",
        "sec2": {
            "title": "Dominujte svoj online rast",
            "cta": "ZÍSKAŤ ZADARMO"
        },
        "sec6": {
            "title": "Ak potrebujete technickú podporu, navštívte náš informačný panel podpory.",
            "cta": "PODPORA"
        },
        "header": {
            "title": "CENY",
            "sub": "pay as you go"
        }
    },
    "testimonials": {
        "title": "Čo hovoria naši používatelia",
        "1": {
            "desc": "Na reklamných kampaniach Google spolupracujeme už takmer rok. Od agentúry sme vždy zažili rýchlu a profesionálnu prácu, sú veľmi kompetentní, spolupracujú a snažia sa plne vyhovieť našim potrebám. Okrem toho, že prinášajú inovatívne riešenia, sú dostatočne pokojné na to, aby dôkladne vysvetlili riešenia zložitých problémov a výsledky kempingov.",
            "name": "Adam Dallos",
            "pos": "PwC, asistent manažéra, marketing, komunikačný rozvoj podnikania"
        }
    },
    "footer": {
        "description": ""
    },
    "menu": {
        "navigation": "NAVIGÁCIA",
        "signin": "Prihlásiť sa",
        "features": "Vlastnosti",
        "pricing": "stanovenie ceny",
        "blog": "Blog",
        "terms-conditions": "podmienky",
        "partners": "PARTNERSTVO",
        "affiliate": "Partnerský",
        "whitelabel": "biely štítok",
        "introducing": "Predstavujeme partnera",
        "findus": "Nájdete nás na",
        "home": "Domov",
        "dashboard": "prístrojová doska",
        "contact": "Kontakt"
    },
    "features": {
        "title": "Reklama na Googli <br\/> bola jednoduchšia ako kedykoľvek predtým",
        "subtitle": "",
        "sec1": {
            "title": "Prečo inzerovať na Googli?",
            "col1": {
                "subtitle": "Porazte svoju konkurenciu",
                "desc": "Každý deň ľudia hľadajú vašu značku alebo produkty, Google vám dáva možnosť dostať svoju firmu pred konkurenciu."
            },
            "col2": {
                "subtitle": "Oslovte ideálnych zákazníkov",
                "desc": "Google je jednou z najlepších reklamných platforiem zameraných na správnych používateľov v správnom čase na všetkých úrovniach interakcie."
            },
            "col3": {
                "subtitle": "Zvýšte svoj predaj",
                "desc": "Návštevnosť vašich webových stránok sa prostredníctvom spoločnosti Google výrazne zvýši s návštevníkmi, ktorí sa môžu ľahko zmeniť na zákazníkov, čím sa zvýši váš predaj a online obchod."
            }
        },
        "sec2": {
            "title": "Dominujte svoj online rast",
            "cta": "VYSKÚŠAJTE ZDARMA"
        }
    },
    "getstarted": {
        "title": "Začnite s 3 jednoduchými krokmi",
        "col1": {
            "subtitle": "1. Stiahnite si",
            "desc": ""
        },
        "col2": {
            "subtitle": "2. Aktivujte",
            "desc": ""
        },
        "col3": {
            "subtitle": "3. Sledovanie",
            "desc": ""
        }
    },
    "dashboard": {
        "title": "prístrojová doska",
        "domainsettings": {
            "title": "NASTAVENIA DOMÁNY",
            "subtitle1": "Pridajte novú doménu",
            "input1": "Vaša nová doména",
            "cta1": "pridať",
            "subtitle2": "Vaše súčasné domény",
            "currentdomains1": "doména",
            "currentdomains2": "Kľúč API"
        },
        "subscription": {
            "title": "PRIHLÁSENIE",
            "subtitle1": "zostatok",
            "remaining": "zostávajúce"
        },
        "tab2": {
            "desc1": "Momentálne používate naše",
            "desc2": "predplatné",
            "cta": "PREDPIS SPÍNAČA"
        },
        "billing": {
            "title": "Fakturačné údaje",
            "input": {
                "name": "Fakturačné meno",
                "vat": "identifikačné číslo DPH",
                "address": "adresa",
                "city": "veľkomesto",
                "zip": "PSČ",
                "country": "Krajina"
            }
        },
        "user": {
            "title": "UŽÍVATEĽSKÉ NASTAVENIA",
            "subtitle": "Vaše prihlasovacie údaje",
            "lastlogin": "Posledné prihlásenie:"
        }
    },
    "cta": {
        "update": "UPDATE"
    },
    "input": {
        "email": "Emailová adresa",
        "password": "heslo",
        "passwordrepeat": "zopakujte heslo"
    },
    "partners": {
        "affiliate": {
            "subtitle": "Exkluzívne bonusy a propagačné akcie <br> na podporu pridružených spoločností",
            "substitle2": "Fúzia nástrojov affiliate marketingu a platobných plánov na zvýšenie vašich ziskov!"
        },
        "affilieate": {
            "description": ""
        },
        "whitelabel": {
            "title": "Partnerstvo White Label"
        },
        "introducing": {
            "subtitle": "",
            "desc2": "Pre viac informácií kontaktujte náš špecializovaný tím na:"
        }
    },
    "url": {
        "features": "Vlastnosti",
        "pricing": "oceňovanie",
        "partnership": {
            "affiliate": "Honours \/ pobočka",
            "whitelabel": "Honours \/ Whitelabel",
            "introductory": "Honours \/ úvodné"
        },
        "contact": "kontakt",
        "login": "Prihlásiť sa"
    },
    "b2c": {
        "main": {
            "title": "<b> KRMIVO <\/b> RIADENIE"
        },
        "cta1": {
            "title": "PODPORA DOČASNEJ BIRD",
            "description": "Na obmedzený čas dostane každý používateľ s počiatočným vtákom uvítací kredit vo výške 1 000 dolárov na testovanie balíka Feed Studio Premium. <br\/> <br\/> Žiadne skúšobné obdobie, žiadna kreditná karta nie je potrebná.",
            "button": "ZÍSKEJTE ÚVER 1 000 USD"
        },
        "sec1": {
            "title": "ako môžete použiť feed.studio",
            "col1": {
                "title": "zaregistrujte sa a dajte nám <br\/> surový informačný kanál",
                "desc": "dajte nám svoje kontaktné údaje a údaje informačného kanála na nastavenie vášho účtu"
            },
            "col2": {
                "title": "naši manažéri nastavili <br\/> váš informačný kanál pre export",
                "desc": "z vášho surového krmiva vytvoríme vaše exportné krmivo"
            },
            "col3": {
                "title": "Užite si (výsledky)",
                "desc": "Sadnite si s vedomím, že naši odborníci tvrdo pracujú na tom, aby vám poskytli to najlepšie krmivo, ktoré potrebujete."
            }
        },
        "sec2": {
            "title": "Prihláste sa na odber testerov včasných vtákov",
            "infoList": {
                "item1": "Na uvítací kredit nie je stanovená žiadna lehota",
                "item2": "na konci uvítacieho obdobia vám nebudeme účtovať žiadne poplatky",
                "item3": "Žiadny produkt ani vývozný limit",
                "item4": "Spravovaná služba - nemusíte sa učiť platformu",
                "item5": "Svoj účet môžete kedykoľvek odstrániť",
                "item6": "Môžete požiadať o vlastné formáty exportu"
            },
            "form": {
                "name": "názov",
                "email": "e-mail",
                "phone": "telefón",
                "feedUrl": "Adresa URL informačného kanála",
                "policyLabel": "Súhlasím s podmienkami",
                "marketingLabel": "Chcel by som sa prihlásiť na odber bulletinu Feed.Studio",
                "button": "získať skorý prístup",
                "feedType": ""
            }
        },
        "meta": {
            "title": "Generátor informačných kanálov XML, Nákupy Google a informačný kanál Facebook",
            "desc": {
                "short": "Vytvárajte a optimalizujte svoje informačné kanály Google a Facebook ľahko.",
                "long": "Informačný kanál Google Shopping, optimalizácia informačného kanála produktu."
            }
        }
    },
    "b2b": {
        "main": {
            "title": "<b> KRMIVO <\/b> .PARTNERS",
            "description": "Feed.Studio určené pre obchodných zástupcov a agentúry v oblasti elektronického obchodu. Tešíme sa na regionálnych partnerov, ktorí vybudujú úspešnú budúcnosť. <\/br> Zvýšte svoje príjmy a predajte viac pomocou Feed.Studio!",
            "button": "PREDAJ VIAC"
        },
        "sec1": {
            "title": "Prečo s nami pracovať?",
            "col1": {
                "title": "predať viac",
                "desc": "predať viac"
            },
            "col2": {
                "title": "poraziť <\/br> konkurentov",
                "desc": "byť prvý"
            },
            "col3": {
                "title": "uspokojiť svojich klientov <\/br>",
                "desc": "kontrola kvality"
            },
            "col4": {
                "title": "ušetriť čas",
                "desc": "s našou chybnou službou"
            }
        },
        "secPartner": {
            "title": "partnerstva",
            "header": {
                "affiliate": "Partnerský",
                "gold": "zlato",
                "exclusive": "výhradný",
                "custom": "zvyk"
            },
            "requirements": {
                "title": "požiadavky",
                "newClient": "nových klientov \/ rok",
                "certifiedusers": "certifikovaný aktívny interný užívateľ"
            },
            "recognition": {
                "title": "uznanie",
                "visible": "viditeľné na feed.studio",
                "trademark": "práva na používanie ochrannej známky „feed studio“"
            },
            "training": {
                "title": "Výhody odbornej prípravy",
                "biweekly": "dvojtýždňové stretnutia",
                "salesSupport": "podpora predaja a poradenstvo"
            },
            "marketing": {
                "title": "marketingové výhody",
                "brand": "značku v miestnych kampaniach",
                "customerStories": "príbehy o úspechoch zákazníkov"
            },
            "sales": {
                "title": "výhody z predaja",
                "materials": "predajné materiály",
                "accountManagers": "špecializovaný správca účtu",
                "leads": "miestne vedie",
                "affiliateBonus": "BONUS NA OSOBITNÝCH KLIENTOCH",
                "feedStudioBonus": "bonus v službe Feed Studio"
            },
            "contactUs": "Kontaktuj nás",
            "signUp": "Prihlásiť Se"
        },
        "sec3": {
            "title": "ako byť naším partnerom?",
            "col1": {
                "title": "Prihlásiť Se",
                "desc": ""
            },
            "col2": {
                "title": "Zúčastnite sa našich školení o predaji a platformách",
                "desc": ""
            },
            "col3": {
                "title": "riadiť našich potenciálnych zákazníkov a klientov",
                "desc": ""
            }
        },
        "sec4": {
            "title": "Zaregistrujte sa do PARTNERSKÉHO PROGRAMU",
            "infoList": {
                "item1": "prístup do krmivového štúdia diskutuje o stratégiách, problémoch s predajom a službami",
                "item2": "viditeľnosť a uznanie tým, že sú uvedení ako oficiálny partner na stránke Partneri Feed Studio",
                "item3": "prístup na informačný panel predaja"
            },
            "form": {
                "plan": {
                    "affiliate": "Partnerský",
                    "gold": "zlato",
                    "custom": "zvyk"
                },
                "name": "názov",
                "email": "e-mail",
                "phone": "telefón",
                "message": "správa",
                "policyLabel": "Súhlasím s podmienkami",
                "marketingLabel": "Chcel by som sa prihlásiť na odber bulletinu Feed.Studio",
                "button": "Získajte skorý prístup"
            }
        }
    },
    "registration": {
        "meta": {
            "title": "Zaregistrujte sa zadarmo na štúdio Feed",
            "desc": {
                "short": "Prihláste sa a získajte kredit 1 000 USD pre aplikáciu Feed Studio.",
                "long": "Zaregistrujte sa a získajte uvítací kredit vo výške 1 000 dolárov, aby ste mohli používať Feed Studio."
            }
        }
    },
    "earlybirdreg": {
        "form": {
            "infoList": {
                "desc1": "",
                "desc2": "",
                "item1": "",
                "item2": "",
                "item3": "",
                "item4": "",
                "item5": "",
                "item6": "",
                "item7": ""
            }
        }
    },
    "login": {
        "title": "",
        "subtitle": "",
        "subtitle2": "",
        "googleButton": ""
    }
};