export default {
    "home": {
        "sec1": {
            "title": "Повысьте свой доход. Effortlessly.",
            "cta": "Скачать бесплатно",
            "col1": {
                "title": "увеличить свой <br\/> доход",
                "desc": "оптимизировать структуру своего корма и увеличить доход на 5-30%"
            },
            "col2": {
                "title": "открыть новые каналы продаж <br\/>",
                "desc": "продавать больше с новыми каналами продаж"
            },
            "col3": {
                "title": "Попробуйте бесплатно без <br\/> ограничения по времени",
                "desc": "наш метод ценообразования Pay As You Go дает вам самое гибкое решение на рынке"
            },
            "col4": {
                "title": "сэкономьте свое <br\/> время",
                "desc": "наши консультанты создают и оптимизируют ваши продукты"
            }
        },
        "sec2": {
            "title": "импорт и экспорт с легкостью",
            "col1": {
                "title": "управление подачей <br\/>",
                "desc": "Feed Studio объединяет различные продукты в одну импортируемую ленту."
            },
            "col2": {
                "title": "использовать более 100 <br\/> других форматов",
                "desc": "Нужны разные дескрипты Google Shopping и Instangram? Не проблема"
            },
            "col3": {
                "title": "оптимизировать ваш продукт <br\/> данные по платформе",
                "desc": "Вам нужно немного другое описание покупок в Google, чем в магазине Instagram? нет проблем"
            },
            "col4": {
                "title": "изменить историю",
                "desc": "Почему рекламируют неправильную цену? Когда не удалось обновить ваш продукт? Мы знаем ответ!"
            },
            "col5": {
                "title": "SEO оптимизация",
                "desc": "Автоматизированное создание и оптимизация метатегов SEO."
            },
            "col6": {
                "title": "управляемый сервис",
                "desc": "Feed Studio объединяет различные продукты в одну импортируемую ленту."
            }
        },
        "sec3": {
            "title": "Хотите развивать свой бизнес без борьбы?"
        },
        "sec4": {
            "item2": {
                "subtitle": "Управление подачей"
            },
            "item4": {
                "subtitle": "Поиск консоли"
            }
        },
        "sec5": {
            "title": "Красиво автоматизированные рекламные кампании Google",
            "col1": {
                "title": "Фирменные объявления",
                "desc": "Знаете ли вы, что 40% ваших клиентов не нажимают на ваш сайт на странице результатов поиска? Достигните их с кампаниями бренда!"
            },
            "col2": {
                "title": "Динамический поиск объявлений"
            },
            "col3": {
                "title": "Торговые объявления"
            },
            "col4": {
                "title": "Медийная реклама",
                "desc": "Получите доступ к своей целевой аудитории в контекстно-медийной сети Google с помощью адаптивного ремаркетинга, динамического ремаркетинга и умных объявлений."
            }
        },
        "sec6": {
            "title": "Готовы автоматизировать вашу рекламу?",
            "cta": "Получить бесплатно"
        },
        "sec7": {
            "title": "",
            "subtitle": "Комплексные услуги даже в бесплатном плане"
        },
        "premier-partner-desc": "Google отмечен нами как наиболее эффективный агент, поэтому вы можете быть уверены, что ваша стратегия Google Ads находится в хороших руках.",
        "sec8": {
            "title": "",
            "cta": "СКАЧАТЬ БЕСПЛАТНО"
        },
        "main": {
            "title": "<b> FEED <\/b> .ME.BABY",
            "description": "Запросите раннюю учетную запись, чтобы опробовать новейшее программное обеспечение менеджера Google Feed <br\/> предназначенное для профессионалов и агентств. <br\/> В течение ограниченного времени вы получите приветственный кредит в размере <s> 300 долларов США <\/s> размере 1000 долларов США.",
            "button": "ПОЛУЧИТЬ кредит в 1000 долларов"
        },
        "cta1": {
            "title": "СОДЕЙСТВИЕ РАННЕЙ ПТИЦЕ",
            "description": "наши первые пользователи получают приветственный кредит в размере 1000 долларов на тестирование Feed Studio",
            "button": "ПОЛУЧИТЬ КРЕДИТ НА 1000 $",
            "subtitle": "КРЕДИТНАЯ КАРТА НЕ ТРЕБУЕТСЯ!"
        },
        "secFeeds": {
            "title": "что вы должны исправить в своих каналах?",
            "header": {
                "channel": "канал",
                "export": "экспорт",
                "description": "описание",
                "result": "результат"
            },
            "google": {
                "title": "Google",
                "feeds": {
                    "merchant": {
                        "name": "Merchant Center feed",
                        "desc": "продавать товары в Google Shopping",
                        "result": "увеличить доход за счет оптимизации структуры поля"
                    },
                    "promotions": {
                        "name": "Фид рекламных акций",
                        "desc": "показать рекламные акции вашему клиенту",
                        "result": "повысить рейтинг кликов и увеличить конверсию"
                    },
                    "localInventory": {
                        "name": "Локальная лента объявлений инвентаря",
                        "desc": "показать свои продукты на Google Maps",
                        "result": "увеличить оффлайн доход, привлечь новых клиентов в ваш магазин"
                    },
                    "productRatings": {
                        "name": "Рейтинг продуктов",
                        "desc": "сделать ваши оценки продуктов и обзоры доступными для пользователей Google",
                        "result": "более высокий рейтинг кликов"
                    },
                    "dynamicRemarketing": {
                        "name": "Динамические ремаркетинговые каналы",
                        "desc": "показывать объявления людям, которые ранее посещали ваш сайт",
                        "result": "увеличить доход"
                    },
                    "page": {
                        "name": "Фид страниц",
                        "desc": "Фид страниц для динамических поисковых объявлений",
                        "result": "повысить эффективность своей поисковой кампании"
                    },
                    "sitemap": {
                        "name": "Карта сайта",
                        "desc": "Фид URL для поисковой оптимизации",
                        "result": "увеличить видимость в поиске Google"
                    },
                    "manufacturer": {
                        "name": "Фид производителя",
                        "desc": "предоставлять более качественную и точную информацию о ваших продуктах в Google",
                        "result": "получить исчерпывающую аналитику и улучшить свое присутствие в Google"
                    }
                }
            },
            "facebook": {
                "title": "facebook",
                "feeds": {
                    "dynamicAds": {
                        "name": "Динамическая рекламная лента",
                        "desc": "показывать объявления людям, которые ранее посещали ваш сайт",
                        "result": "увеличить доход"
                    },
                    "pageShop": {
                        "name": "Page Shop feed",
                        "desc": "показывать и продавать товары людям на Facebook",
                        "result": "увеличить доход"
                    },
                    "marketplace": {
                        "name": "Торговая площадка",
                        "desc": "показывать и продавать товары людям на Facebook Marketplace",
                        "result": "увеличить доход"
                    }
                }
            },
            "instagram": {
                "title": "Instagram",
                "feeds": {
                    "shopping": {
                        "name": "Торговый канал",
                        "desc": "показывать и продавать товары людям в Instagram",
                        "result": "увеличить доход"
                    }
                }
            },
            "amazon": {
                "title": "Амазонка",
                "feeds": {
                    "product": {
                        "name": "Подача продукта",
                        "desc": "показывать и продавать товары людям на Amazon",
                        "result": "увеличить доход"
                    }
                }
            },
            "ebay": {
                "title": "Ebay",
                "feeds": {
                    "product": {
                        "name": "Подача продукта",
                        "desc": "показывать и продавать товары людям на Ebay",
                        "result": "увеличить доход"
                    }
                }
            },
            "criteo": {
                "title": "Criteo",
                "feeds": {
                    "product": {
                        "name": "Подача продукта",
                        "desc": "продавать товары онлайн",
                        "result": "увеличить доход"
                    }
                }
            }
        },
        "cta2": {
            "title": "Увеличить ваши продажи с FEED.STUDIO",
            "button": "ПОЛУЧИТЬ КРЕДИТ НА 1000 $",
            "subtitle": "КРЕДИТНАЯ КАРТА НЕ ТРЕБУЕТСЯ!"
        },
        "meta": {
            "title": "Feed Studio »Инструмент менеджера XML Feed - Генератор XML Product Feed - Google Shopping Feed",
            "desc": {
                "short": "Генератор Google Shopping Feed.",
                "long": "Генератор Google Shopping Feed"
            }
        }
    },
    "pricing": {
        "plugin": {
            "col1": {
                "header": "ОСОБЕННОСТИ \/ ПЛАНЫ",
                "row1": "Неограниченные продукты",
                "row2": "Google Ads - Shopping Feed",
                "row3": "Google Ads - динамический показ рекламных фидов",
                "row4": "Google Ads - DSA Pagefeed",
                "row5": "Фид продукта Facebook",
                "row6": "Arukereso.hu Лента продуктов",
                "row7": "Подача продукта Criteo",
                "row8": "РТБ Хаус Продукт Фид",
                "row9": "Google Ads Automation",
                "row10": "Таможенный импорт и экспорт",
                "row11": "Вебинары",
                "row12": "Выделенный менеджер по работе с клиентами",
                "row13": "Приветственный ваучер",
                "row14": "Ежемесячная оплата",
                "row15": "Цена за 10 000 операций"
            },
            "agency": "Все ваши деньги вернутся, если мы не дадим ожидаемых результатов в течение 3 месяцев.",
            "col2": {
                "header": "БЕСПЛАТНАЯ ЖИЗНЬ",
                "row6": "еженедельно",
                "cta": "Регистрация",
                "row1": "Встроенная панель приборов",
                "row14": "9 долларов",
                "row15": "не доступно"
            },
            "col3": {
                "header": "PREMIUM",
                "row6": "Ежедневно",
                "cta": "Регистрация",
                "row1": "Премиум панель",
                "row13": "300 долларов",
                "row14": "9 долларов",
                "row15": "1 доллар"
            },
            "col4": {
                "row6": "Реальное время",
                "cta": "Регистрация",
                "row1": "VIP панель",
                "row10": "Частные вебинары",
                "header": "Ранняя Птица Премиум",
                "row13": "1000 долларов",
                "row14": "9 долларов",
                "row15": "1 доллар"
            }
        },
        "agency": {
            "allin": {
                "li6": "Chatbot",
                "li9": "Гарантия возврата денег*"
            }
        },
        "title": "Планы, которые соответствуют всему вашему \n маркетинговый бюджет",
        "sec2": {
            "title": "Доминируй свой онлайн рост",
            "cta": "ПОЛУЧИТЬ БЕСПЛАТНО"
        },
        "sec6": {
            "title": "Если вам нужна техническая поддержка, посетите нашу панель поддержки.",
            "cta": "СЛУЖБА ПОДДЕРЖКИ"
        },
        "header": {
            "title": "Ценообразование",
            "sub": "плати как сможешь"
        }
    },
    "testimonials": {
        "title": "Что говорят наши пользователи",
        "1": {
            "desc": "Мы работаем вместе над рекламными кампаниями Google уже почти год. Мы всегда испытывали быструю и профессиональную работу от агентства, они очень компетентны, готовы к сотрудничеству и стремятся полностью удовлетворить наши потребности. Помимо предоставления инновационных решений, они достаточно спокойны, чтобы глубоко объяснить решения сложных проблем и результаты кампаний.",
            "name": "Адам Даллос",
            "pos": "PwC, помощник менеджера по маркетингу, развитию коммуникационного бизнеса"
        }
    },
    "footer": {
        "description": ""
    },
    "menu": {
        "navigation": "НАВИГАЦИЯ",
        "signin": "Войти в систему",
        "features": "особенности",
        "pricing": "ценообразование",
        "blog": "Блог",
        "terms-conditions": "Условия использования",
        "partners": "ПАРТНЕРСТВО",
        "affiliate": "филиал",
        "whitelabel": "белая этикетка",
        "introducing": "Представляющий партнер",
        "findus": "Найти нас на",
        "home": "Дом",
        "dashboard": "Приборная панель",
        "contact": "контакт"
    },
    "features": {
        "title": "Реклама на Google <br\/> легче , чем когда - либо",
        "subtitle": "",
        "sec1": {
            "title": "Зачем размещать рекламу в Google?",
            "col1": {
                "subtitle": "Побей свой конкурс",
                "desc": "Каждый день люди ищут ваш бренд или продукты, Google дает вам возможность поставить ваш бизнес перед конкурентами."
            },
            "col2": {
                "subtitle": "Достичь идеальных клиентов",
                "desc": "Google является одной из лучших рекламных платформ для нацеливания нужных пользователей в нужное время на всех уровнях взаимодействия."
            },
            "col3": {
                "subtitle": "Вырасти свои продажи",
                "desc": "Трафик на ваш сайт значительно увеличится благодаря Google, благодаря посетителям, которые могут легко превратиться в клиентов, что приведет к росту продаж и онлайн-бизнеса."
            }
        },
        "sec2": {
            "title": "Доминируй свой онлайн рост",
            "cta": "ПОПРОБОВАТЬ БЕСПЛАТНО"
        }
    },
    "getstarted": {
        "title": "Начните с 3 простых шагов",
        "col1": {
            "subtitle": "1. Скачать",
            "desc": ""
        },
        "col2": {
            "subtitle": "2. Активировать",
            "desc": ""
        },
        "col3": {
            "subtitle": "3. Трек",
            "desc": ""
        }
    },
    "dashboard": {
        "title": "Приборная панель",
        "domainsettings": {
            "title": "ДОМЕННЫЕ НАСТРОЙКИ",
            "subtitle1": "Добавить новый домен",
            "input1": "Ваш новый домен",
            "cta1": "Добавить",
            "subtitle2": "Ваши текущие домены",
            "currentdomains1": "Домен",
            "currentdomains2": "Ключ API"
        },
        "subscription": {
            "title": "ПОДПИСКА",
            "subtitle1": "Баланс",
            "remaining": "осталось"
        },
        "tab2": {
            "desc1": "Вы в настоящее время используете наш",
            "desc2": "подписка",
            "cta": "ПОДПИСКА НА ВЫКЛЮЧАТЕЛЬ"
        },
        "billing": {
            "title": "Платежные реквизиты",
            "input": {
                "name": "Платежное имя",
                "vat": "Номер НДС",
                "address": "Адрес",
                "city": "город",
                "zip": "почтовый индекс",
                "country": "Страна"
            }
        },
        "user": {
            "title": "ПОЛЬЗОВАТЕЛЬСКИЕ НАСТРОЙКИ",
            "subtitle": "Ваши данные для входа",
            "lastlogin": "Последний Войти:"
        }
    },
    "cta": {
        "update": "ОБНОВИТЬ"
    },
    "input": {
        "email": "Адрес электронной почты",
        "password": "пароль",
        "passwordrepeat": "Повторите пароль"
    },
    "partners": {
        "affiliate": {
            "subtitle": "Эксклюзивные бонусы и акции <br> для повышения Партнеров",
            "substitle2": "Слияние инструментов аффилированного маркетинга и планов платежей для увеличения вашей прибыли!"
        },
        "affilieate": {
            "description": ""
        },
        "whitelabel": {
            "title": "Партнерство White Label"
        },
        "introducing": {
            "subtitle": "",
            "desc2": "Для получения дополнительной информации, пожалуйста, свяжитесь с нашей специализированной командой по адресу:"
        }
    },
    "url": {
        "features": "функции",
        "pricing": "ценообразование",
        "partnership": {
            "affiliate": "patnership \/ филиал",
            "whitelabel": "patnership \/ WhiteLabel",
            "introductory": "patnership \/ вводный"
        },
        "contact": "контакт",
        "login": "авторизоваться"
    },
    "b2c": {
        "main": {
            "title": "<b> FEED <\/b> УПРАВЛЕНИЕ"
        },
        "cta1": {
            "title": "СОДЕЙСТВИЕ РАННЕЙ ПТИЦЕ",
            "description": "В течение ограниченного времени каждый ранний пользователь получает приветственный кредит в размере 1000 долларов для тестирования пакета Feed Studio Premium. <br\/> <br\/> Нет пробного периода, не нужна кредитная карта.",
            "button": "ПОЛУЧИТЬ КРЕДИТ НА 1000 $"
        },
        "sec1": {
            "title": "как вы можете использовать feed.studio",
            "col1": {
                "title": "Зарегистрируйтесь и дайте нам <br\/> свой сырой корм",
                "desc": "дайте нам свой контакт и данные фида, чтобы настроить свой аккаунт"
            },
            "col2": {
                "title": "наши менеджеры настроили <br\/> ваш экспортный канал",
                "desc": "из вашего сырья мы создаем ваш экспортный канал"
            },
            "col3": {
                "title": "Наслаждайтесь (результаты)",
                "desc": "Расслабьтесь, зная, что наши специалисты прилагают все усилия, чтобы предоставить вам лучший корм, который вам нужен."
            }
        },
        "sec2": {
            "title": "Подпишитесь на ранний тестер",
            "infoList": {
                "item1": "Нет ограничений по времени для приветственного кредита",
                "item2": "мы не взимаем \/ выставляем счет в конце периода приветствия",
                "item3": "Нет товара или экспортный лимит",
                "item4": "Управляемый сервис - вам не нужно изучать платформу",
                "item5": "Вы можете удалить свой аккаунт в любое время",
                "item6": "Вы можете задать пользовательские форматы экспорта"
            },
            "form": {
                "name": "имя",
                "email": "Электронное письмо",
                "phone": "Телефон",
                "feedUrl": "URL фида",
                "policyLabel": "Я принимаю правила и условия",
                "marketingLabel": "Я хотел бы подписаться на рассылку новостей Feed.Studio",
                "button": "получить ранний доступ",
                "feedType": ""
            }
        },
        "meta": {
            "title": "Генератор каналов XML-продуктов, Google Shopping и Facebook Feed",
            "desc": {
                "short": "Легко создавайте и оптимизируйте свои каналы Google и Facebook.",
                "long": "Google Shopping Feed, Оптимизация ленты товаров."
            }
        }
    },
    "b2b": {
        "main": {
            "title": "<b> FEED <\/b> .PARTNERS",
            "description": "Feed.Studio предназначен для электронной коммерции, а также для профессионалов и агентств. Мы ищем региональных партнеров для построения успешного будущего. <\/br> свой доход, продавайте больше с Feed.Studio!",
            "button": "ПРОДАТЬ БОЛЬШЕ"
        },
        "sec1": {
            "title": "Зачем работать с нами?",
            "col1": {
                "title": "продать больше",
                "desc": "продать больше"
            },
            "col2": {
                "title": "победить своих <\/br> конкурентов",
                "desc": "быть первым"
            },
            "col3": {
                "title": "удовлетворить ваших <\/br> клиентов",
                "desc": "контроль качества"
            },
            "col4": {
                "title": "сэкономить время",
                "desc": "с нашим mamaged обслуживанием"
            }
        },
        "secPartner": {
            "title": "партнерские отношения",
            "header": {
                "affiliate": "филиал",
                "gold": "Золото",
                "exclusive": "исключающее",
                "custom": "изготовленный на заказ"
            },
            "requirements": {
                "title": "Требования",
                "newClient": "новые клиенты \/ год",
                "certifiedusers": "сертифицированный активный внутренний пользователь"
            },
            "recognition": {
                "title": "признание",
                "visible": "видимый на feed.studio",
                "trademark": "права на использование товарного знака «feed studio»"
            },
            "training": {
                "title": "Преимущества обучения",
                "biweekly": "раз в две недели",
                "salesSupport": "поддержка продаж и руководство"
            },
            "marketing": {
                "title": "маркетинговые преимущества",
                "brand": "бренд на местных кампаниях",
                "customerStories": "истории успеха клиентов"
            },
            "sales": {
                "title": "преимущества продаж",
                "materials": "продажа материалов",
                "accountManagers": "выделенный менеджер по работе с клиентами",
                "leads": "местные ведет",
                "affiliateBonus": "БОНУС НА ФИЛИАЛЬНЫХ КЛИЕНТАХ",
                "feedStudioBonus": "бонус на Feed Studio"
            },
            "contactUs": "Связаться с нами",
            "signUp": "Подписаться"
        },
        "sec3": {
            "title": "как быть нашим партнером?",
            "col1": {
                "title": "Подписаться",
                "desc": ""
            },
            "col2": {
                "title": "принять участие в наших тренингах по продажам и платформе",
                "desc": ""
            },
            "col3": {
                "title": "управлять нашими клиентами и клиентами",
                "desc": ""
            }
        },
        "sec4": {
            "title": "Зарегистрируйтесь в ПАРТНЕРСКОЙ ПРОГРАММЕ",
            "infoList": {
                "item1": "доступ к Feed Studio для обсуждения стратегий, вопросов продаж и обслуживания",
                "item2": "видимость и узнаваемость, будучи зарегистрированным в качестве официального партнера на странице партнеров Feed Studio",
                "item3": "доступ к панели инструментов продаж"
            },
            "form": {
                "plan": {
                    "affiliate": "филиал",
                    "gold": "Золото",
                    "custom": "изготовленный на заказ"
                },
                "name": "имя",
                "email": "Электронное письмо",
                "phone": "Телефон",
                "message": "Сообщение",
                "policyLabel": "Я принимаю правила и условия",
                "marketingLabel": "Я хотел бы подписаться на рассылку новостей Feed.Studio",
                "button": "Получить ранний доступ"
            }
        }
    },
    "registration": {
        "meta": {
            "title": "Зарегистрируйтесь бесплатно в Feed Studio",
            "desc": {
                "short": "Зарегистрируйтесь и получите кредит в $ 1000 на Feed Studio.",
                "long": "Зарегистрируйтесь и получите приветственный кредит в размере 1000 долларов США для использования Feed Studio."
            }
        }
    },
    "earlybirdreg": {
        "form": {
            "infoList": {
                "desc1": "",
                "desc2": "",
                "item1": "",
                "item2": "",
                "item3": "",
                "item4": "",
                "item5": "",
                "item6": "",
                "item7": ""
            }
        }
    },
    "login": {
        "title": "",
        "subtitle": "",
        "subtitle2": "",
        "googleButton": ""
    }
};