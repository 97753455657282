export default {
    "home": {
        "sec1": {
            "title": "Aumenta le tue entrate. Senza sforzo.",
            "cta": "Download gratis",
            "col1": {
                "title": "aumentare le tue entrate <br\/>",
                "desc": "ottimizzare la struttura dei feed e aumentare le entrate del 5-30%"
            },
            "col2": {
                "title": "aprire nuovi canali di vendita <br\/>",
                "desc": "vendere di più con nuovi canali di vendita"
            },
            "col3": {
                "title": "prova gratuitamente senza <br\/> limite di tempo",
                "desc": "il nostro metodo di determinazione dei prezzi a consumo ti offre la soluzione più flessibile sul mercato"
            },
            "col4": {
                "title": "risparmia il tuo <br\/> tempo",
                "desc": "i nostri consulenti costruiscono e ottimizzano i feed dei tuoi prodotti"
            }
        },
        "sec2": {
            "title": "importare ed esportare con facilità",
            "col1": {
                "title": "gestione del fornitore <br\/>",
                "desc": "Feed Studio combina diversi feed di prodotto in un unico feed importabile"
            },
            "col2": {
                "title": "usa più di 100 <br\/> altri formati",
                "desc": "Hai bisogno di descrizioni di Google Shopping e Instangram diverse? Non è un problema"
            },
            "col3": {
                "title": "ottimizzare i dati del prodotto <br\/> per piattaforma",
                "desc": "Hai bisogno di una descrizione dello shopping di Google leggermente diversa rispetto al negozio di Instagram? Nessun problema"
            },
            "col4": {
                "title": "cambiare la storia",
                "desc": "Perché pubblicizzare un prezzo sbagliato? In caso di errore nell'aggiornamento del prodotto? Conosciamo la risposta!"
            },
            "col5": {
                "title": "Ottimizzazione SEO",
                "desc": "Creazione e ottimizzazione automatizzate di meta tag SEO."
            },
            "col6": {
                "title": "servizio gestito",
                "desc": "Feed Studio combina diversi feed di prodotto in un unico feed importabile"
            }
        },
        "sec3": {
            "title": "Vuoi far crescere il tuo business senza la lotta?"
        },
        "sec4": {
            "item2": {
                "subtitle": "Gestione dei mangimi"
            },
            "item4": {
                "subtitle": "Console di ricerca"
            }
        },
        "sec5": {
            "title": "Campagne di annunci Google meravigliosamente automatizzate",
            "col1": {
                "title": "Annunci di marca",
                "desc": "Sapevi che il 40% dei tuoi clienti non fa clic sul tuo sito nella pagina dei risultati di ricerca? Raggiungili con campagne di marchi!"
            },
            "col2": {
                "title": "Annunci dinamici della rete di ricerca"
            },
            "col3": {
                "title": "Annunci commerciali"
            },
            "col4": {
                "title": "Annunci display",
                "desc": "Raggiungi il tuo pubblico di destinazione sulla Rete Display di Google con remarketing reattivo, remarketing dinamico e annunci intelligenti."
            }
        },
        "sec6": {
            "title": "Pronto ad automatizzare la tua pubblicità?",
            "cta": "Ottenere gratuitamente"
        },
        "sec7": {
            "title": "",
            "subtitle": "Servizi completi anche nel piano gratuito"
        },
        "premier-partner-desc": "Google ci distingue come un'agenzia con le migliori prestazioni, quindi puoi essere certo che la tua strategia di annunci Google è in buone mani.",
        "sec8": {
            "title": "",
            "cta": "DOWNLOAD GRATIS"
        },
        "main": {
            "title": "<b> FEED <\/b> .ME.BABY",
            "description": "Richiedi un account early bird per provare l'ultimo feed di Google <br\/> software di gestione progettato per professionisti e agenzie. <br\/> Per un periodo di tempo limitato riceverai <s> $ 300 <\/s> $ 1,000 di credito di benvenuto.",
            "button": "Ottieni $ 1.000 di credito"
        },
        "cta1": {
            "title": "PROMOZIONE DI EARLY BIRD",
            "description": "i nostri primi utenti ricevono $ 1000 di credito di benvenuto per testare Feed Studio",
            "button": "Ottieni $ 1,000 CREDITO",
            "subtitle": "NESSUNA CARTA DI CREDITO NECESSARIA!"
        },
        "secFeeds": {
            "title": "cosa dovresti correggere nei tuoi feed?",
            "header": {
                "channel": "canale",
                "export": "esportare",
                "description": "descrizione",
                "result": "risultato"
            },
            "google": {
                "title": "Google",
                "feeds": {
                    "merchant": {
                        "name": "Feed di Merchant Center",
                        "desc": "vendere prodotti su Google Shopping",
                        "result": "aumentare le entrate grazie a una struttura dei campi ottimizzata"
                    },
                    "promotions": {
                        "name": "Feed di promozioni",
                        "desc": "mostra le promozioni ai tuoi clienti",
                        "result": "aumentare la percentuale di clic e aumentare le conversioni"
                    },
                    "localInventory": {
                        "name": "Feed di annunci di inventario locale",
                        "desc": "mostra i tuoi prodotti su Google Maps",
                        "result": "aumentare le entrate offline, attirare nuovi clienti nel tuo negozio"
                    },
                    "productRatings": {
                        "name": "Feed valutazioni dei prodotti",
                        "desc": "rendere le valutazioni e le recensioni dei prodotti disponibili per gli utenti di Google",
                        "result": "maggiore percentuale di clic"
                    },
                    "dynamicRemarketing": {
                        "name": "Feed di remarketing dinamico",
                        "desc": "mostra annunci alle persone che hanno già visitato il tuo sito web",
                        "result": "aumentare le entrate"
                    },
                    "page": {
                        "name": "Feed di pagina",
                        "desc": "Feed di pagina per gli annunci dinamici della rete di ricerca",
                        "result": "aumentare il rendimento della tua campagna di ricerca"
                    },
                    "sitemap": {
                        "name": "Feed Sitemap",
                        "desc": "Feed URL per l'ottimizzazione dei motori di ricerca",
                        "result": "aumentare la visibilità in Ricerca Google"
                    },
                    "manufacturer": {
                        "name": "Feed del produttore",
                        "desc": "fornire a Google informazioni migliori e più accurate sui tuoi prodotti",
                        "result": "ottieni analisi complete e migliora la tua presenza su Google"
                    }
                }
            },
            "facebook": {
                "title": "Facebook",
                "feeds": {
                    "dynamicAds": {
                        "name": "Feed di annunci dinamici",
                        "desc": "mostra annunci alle persone che hanno già visitato il tuo sito web",
                        "result": "aumentare le entrate"
                    },
                    "pageShop": {
                        "name": "Feed negozio pagina",
                        "desc": "mostrare e vendere prodotti alle persone su Facebook",
                        "result": "aumentare le entrate"
                    },
                    "marketplace": {
                        "name": "Feed del marketplace",
                        "desc": "mostrare e vendere prodotti alle persone sul Marketplace di Facebook",
                        "result": "aumentare le entrate"
                    }
                }
            },
            "instagram": {
                "title": "instagram",
                "feeds": {
                    "shopping": {
                        "name": "Feed acquisti",
                        "desc": "mostrare e vendere prodotti alle persone su Instagram",
                        "result": "aumentare le entrate"
                    }
                }
            },
            "amazon": {
                "title": "amazon",
                "feeds": {
                    "product": {
                        "name": "Feed del prodotto",
                        "desc": "mostrare e vendere prodotti alle persone su Amazon",
                        "result": "aumentare le entrate"
                    }
                }
            },
            "ebay": {
                "title": "ebay",
                "feeds": {
                    "product": {
                        "name": "Feed del prodotto",
                        "desc": "mostrare e vendere prodotti alle persone su Ebay",
                        "result": "aumentare le entrate"
                    }
                }
            },
            "criteo": {
                "title": "Criteo",
                "feeds": {
                    "product": {
                        "name": "Feed del prodotto",
                        "desc": "vendere prodotti online",
                        "result": "aumentare le entrate"
                    }
                }
            }
        },
        "cta2": {
            "title": "POTENZIA LE TUE VENDITE CON FEED.STUDIO",
            "button": "Ottieni $ 1,000 CREDITO",
            "subtitle": "NESSUNA CARTA DI CREDITO NECESSARIA!"
        },
        "meta": {
            "title": "Feed Studio »Strumento XML Feed Manager - Generatore di feed di prodotto XML - Google Shopping Feed",
            "desc": {
                "short": "Strumento generatore di feed di Google Shopping.",
                "long": "Generatore di feed di Google Shopping"
            }
        }
    },
    "pricing": {
        "plugin": {
            "col1": {
                "header": "CARATTERISTICHE \/ PIANI",
                "row1": "Prodotti illimitati",
                "row2": "Annunci Google - Feed acquisti",
                "row3": "Annunci Google - Feed di annunci display dinamici",
                "row4": "Google Ads - DSA Pagefeed",
                "row5": "Feed del prodotto Facebook",
                "row6": "Arukereso.hu Feed prodotto",
                "row7": "Feed di prodotti Criteo",
                "row8": "Feed prodotti casa RTB",
                "row9": "Automazione annunci Google",
                "row10": "Importazioni ed esportazioni personalizzate",
                "row11": "Webinar",
                "row12": "Account Manager dedicato",
                "row13": "Buono di benvenuto",
                "row14": "Tariffa mensile",
                "row15": "Prezzo per 10.000 operazioni"
            },
            "agency": "Tutti i tuoi soldi indietro se non forniamo i risultati previsti in 3 mesi.",
            "col2": {
                "header": "SENZA VITA",
                "row6": "settimanalmente",
                "cta": "Registrazione",
                "row1": "Dashboard integrata",
                "row14": "$ 9",
                "row15": "n \/ A"
            },
            "col3": {
                "header": "PREMIUM",
                "row6": "Quotidiano",
                "cta": "Registrazione",
                "row1": "Dashboard premium",
                "row13": "$ 300",
                "row14": "$ 9",
                "row15": "$ 1"
            },
            "col4": {
                "row6": "Tempo reale",
                "cta": "Registrazione",
                "row1": "Dashboard VIP",
                "row10": "Webinar privati",
                "header": "Early Bird Premium",
                "row13": "$ 1000",
                "row14": "$ 9",
                "row15": "$ 1"
            }
        },
        "agency": {
            "allin": {
                "li6": "chatbot",
                "li9": "Soddisfatti o rimborsati*"
            }
        },
        "title": "Piani che si adattano al tuo intero \n il budget di mercato",
        "sec2": {
            "title": "Domina la tua crescita online",
            "cta": "OTTENERE GRATUITAMENTE"
        },
        "sec6": {
            "title": "Se hai bisogno di supporto tecnico, visita la nostra dashboard di supporto.",
            "cta": "SUPPORTO"
        },
        "header": {
            "title": "PREZZI",
            "sub": "paga mentre vai"
        }
    },
    "testimonials": {
        "title": "Cosa dicono i nostri utenti",
        "1": {
            "desc": "Lavoriamo insieme sulle campagne pubblicitarie di Google da quasi un anno. Abbiamo sempre sperimentato un lavoro rapido e professionale da parte dell'agenzia, sono molto competenti, cooperativi e cercano di soddisfare pienamente le nostre esigenze. Oltre a proporre soluzioni innovative, sono abbastanza calmi da spiegare in profondità le soluzioni a problemi complessi e i risultati delle campagne.",
            "name": "Adam Dallos",
            "pos": "PwC, Assistant Manager, Marketing, Comunicazione Sviluppo commerciale"
        }
    },
    "footer": {
        "description": ""
    },
    "menu": {
        "navigation": "NAVIGAZIONE",
        "signin": "Registrati",
        "features": "Caratteristiche",
        "pricing": "Prezzi",
        "blog": "blog",
        "terms-conditions": "Termini & Condizioni",
        "partners": "ASSOCIAZIONE",
        "affiliate": "affiliato",
        "whitelabel": "etichetta bianca",
        "introducing": "Presentazione del partner",
        "findus": "Trovaci su",
        "home": "Casa",
        "dashboard": "Pannello di controllo",
        "contact": "Contatto"
    },
    "features": {
        "title": "La pubblicità su Google <br\/> diventata più semplice che mai",
        "subtitle": "",
        "sec1": {
            "title": "Perché fare pubblicità su Google?",
            "col1": {
                "subtitle": "Batti la tua competizione",
                "desc": "Ogni giorno le persone sono alla ricerca del tuo marchio o dei tuoi prodotti, Google ti dà la possibilità di mettere la tua azienda davanti alla concorrenza."
            },
            "col2": {
                "subtitle": "Raggiungi i clienti ideali",
                "desc": "Google è una delle migliori piattaforme pubblicitarie per indirizzare gli utenti giusti al momento giusto a tutti i livelli di coinvolgimento."
            },
            "col3": {
                "subtitle": "Fai crescere le tue vendite",
                "desc": "Il traffico verso il tuo sito Web aumenterà notevolmente attraverso Google con visitatori che possono facilmente trasformarsi in clienti, aumentando le tue vendite e il tuo business online."
            }
        },
        "sec2": {
            "title": "Domina la tua crescita online",
            "cta": "PROVA GRATIS"
        }
    },
    "getstarted": {
        "title": "Inizia con 3 semplici passaggi",
        "col1": {
            "subtitle": "1. Scarica",
            "desc": ""
        },
        "col2": {
            "subtitle": "2. Attiva",
            "desc": ""
        },
        "col3": {
            "subtitle": "3. Traccia",
            "desc": ""
        }
    },
    "dashboard": {
        "title": "Pannello di controllo",
        "domainsettings": {
            "title": "IMPOSTAZIONI DEL DOMINIO",
            "subtitle1": "Aggiungi nuovo dominio",
            "input1": "Il tuo nuovo dominio",
            "cta1": "Inserisci",
            "subtitle2": "I tuoi domini attuali",
            "currentdomains1": "Dominio",
            "currentdomains2": "Chiave API"
        },
        "subscription": {
            "title": "SOTTOSCRIZIONE",
            "subtitle1": "Equilibrio",
            "remaining": "residuo"
        },
        "tab2": {
            "desc1": "Attualmente stai utilizzando il nostro",
            "desc2": "sottoscrizione",
            "cta": "ABBONAMENTO INTERRUTTORE"
        },
        "billing": {
            "title": "Dettagli di fatturazione",
            "input": {
                "name": "Nome fatturazione",
                "vat": "Partita IVA",
                "address": "Indirizzo",
                "city": "Città",
                "zip": "Cap",
                "country": "Nazione"
            }
        },
        "user": {
            "title": "IMPOSTAZIONI UTENTE",
            "subtitle": "I tuoi dati di accesso",
            "lastlogin": "Ultimo accesso:"
        }
    },
    "cta": {
        "update": "AGGIORNARE"
    },
    "input": {
        "email": "Indirizzo email",
        "password": "Parola d'ordine",
        "passwordrepeat": "ripeti la password"
    },
    "partners": {
        "affiliate": {
            "subtitle": "Bonus e promozioni esclusivi <br> per aumentare gli affiliati",
            "substitle2": "Una fusione di strumenti di marketing di affiliazione e piani di pagamento per aumentare i tuoi profitti!"
        },
        "affilieate": {
            "description": ""
        },
        "whitelabel": {
            "title": "Partnership con etichetta bianca"
        },
        "introducing": {
            "subtitle": "",
            "desc2": "Per ulteriori informazioni, contattare il nostro team specializzato all'indirizzo:"
        }
    },
    "url": {
        "features": "Caratteristiche",
        "pricing": "prezzi",
        "partnership": {
            "affiliate": "patnership \/ affiliato",
            "whitelabel": "patnership \/ whitelabel",
            "introductory": "patnership \/ introduttivo"
        },
        "contact": "contatto",
        "login": "accesso"
    },
    "b2c": {
        "main": {
            "title": "<b> FEED <\/b> .MANAGEMENT"
        },
        "cta1": {
            "title": "PROMOZIONE DI EARLY BIRD",
            "description": "Per un periodo di tempo limitato, tutti gli utenti mattinieri ricevono $ 1000 di credito di benvenuto per provare il pacchetto Feed Studio Premium. <br\/> <br\/> Nessun periodo di prova, nessuna carta di credito necessaria.",
            "button": "Ottieni $ 1,000 CREDITO"
        },
        "sec1": {
            "title": "come puoi usare feed.studio",
            "col1": {
                "title": "Registrati e ci danno <br\/> tuo feed grezzo",
                "desc": "forniscici i tuoi dati di contatto e di feed per configurare il tuo account"
            },
            "col2": {
                "title": "i nostri gestori hanno impostato <br\/> tuo feed di esportazione",
                "desc": "dal tuo feed non elaborato creiamo il tuo feed di esportazione"
            },
            "col3": {
                "title": "Goditi (i risultati)",
                "desc": "Siediti sapendo che i nostri esperti stanno lavorando duramente per fornirti il miglior feed di cui hai bisogno."
            }
        },
        "sec2": {
            "title": "Iscriviti per il tester early bird",
            "infoList": {
                "item1": "Nessun limite di tempo per il credito di benvenuto",
                "item2": "non ti addebitiamo \/ fatturiamo al termine del periodo di benvenuto",
                "item3": "Nessun limite di prodotto o esportazione",
                "item4": "Servizio gestito: non è necessario apprendere la piattaforma",
                "item5": "Puoi eliminare il tuo account in qualsiasi momento",
                "item6": "Puoi chiedere formati di esportazione personalizzati"
            },
            "form": {
                "name": "Nome",
                "email": "E-mail",
                "phone": "Telefono",
                "feedUrl": "URL del feed",
                "policyLabel": "Accetto i Termini e le Condizioni",
                "marketingLabel": "Vorrei iscrivermi alla newsletter di Feed.Studio",
                "button": "ottenere l'accesso anticipato",
                "feedType": ""
            }
        },
        "meta": {
            "title": "Generatore di feed di prodotti XML, Google Shopping e feed di Facebook",
            "desc": {
                "short": "Crea e ottimizza facilmente i tuoi feed di Google e Facebook.",
                "long": "Feed di Google Shopping, ottimizzazione del feed di prodotto."
            }
        }
    },
    "b2b": {
        "main": {
            "title": "<b> FEED <\/b> .PARTNERS",
            "description": "Feed.Studio progettato per professionisti e agenzie di e-commerce. Siamo alla ricerca di partner regionali per costruire un futuro di successo. <\/br> Aumenta le tue entrate, vendi di più con Feed.Studio!",
            "button": "VENDI DI PIÙ"
        },
        "sec1": {
            "title": "Perché lavorare con noi?",
            "col1": {
                "title": "vendere di più",
                "desc": "vendere di più"
            },
            "col2": {
                "title": "battere i tuoi concorrenti <\/br>",
                "desc": "Sii il primo"
            },
            "col3": {
                "title": "soddisfare i tuoi clienti <\/br>",
                "desc": "controllo di qualità"
            },
            "col4": {
                "title": "risparmia tempo",
                "desc": "con il nostro servizio mamaged"
            }
        },
        "secPartner": {
            "title": "partnership",
            "header": {
                "affiliate": "affiliato",
                "gold": "Oro",
                "exclusive": "Esclusivo",
                "custom": "costume"
            },
            "requirements": {
                "title": "Requisiti",
                "newClient": "nuovi clienti \/ anno",
                "certifiedusers": "utente interno attivo certificato"
            },
            "recognition": {
                "title": "Riconoscimento",
                "visible": "visibile su feed.studio",
                "trademark": "diritti di utilizzo del marchio \"feed studio\""
            },
            "training": {
                "title": "Benefici della formazione",
                "biweekly": "incontri bisettimanali",
                "salesSupport": "supporto e assistenza alle vendite"
            },
            "marketing": {
                "title": "vantaggi di marketing",
                "brand": "marchio sulle campagne locali",
                "customerStories": "storie di successo dei clienti"
            },
            "sales": {
                "title": "vantaggi di vendita",
                "materials": "materiali di vendita",
                "accountManagers": "account manager dedicato",
                "leads": "contatti locali",
                "affiliateBonus": "BONUS SU CLIENTI AFFILIATI",
                "feedStudioBonus": "bonus su Feed Studio"
            },
            "contactUs": "Contattaci",
            "signUp": "Iscriviti"
        },
        "sec3": {
            "title": "come essere nostro partner?",
            "col1": {
                "title": "Iscriviti",
                "desc": ""
            },
            "col2": {
                "title": "prendere parte ai nostri corsi di formazione sulle vendite e sulla piattaforma",
                "desc": ""
            },
            "col3": {
                "title": "gestire i nostri contatti e clienti",
                "desc": ""
            }
        },
        "sec4": {
            "title": "Iscriviti al PROGRAMMA PARTNER",
            "infoList": {
                "item1": "accesso a Feed Studio per discutere di strategie, problemi di vendita e servizi",
                "item2": "visibilità e riconoscimento essendo elencati come partner ufficiale nella pagina dei partner di Feed Studio",
                "item3": "accesso alla dashboard delle vendite"
            },
            "form": {
                "plan": {
                    "affiliate": "affiliato",
                    "gold": "Oro",
                    "custom": "costume"
                },
                "name": "Nome",
                "email": "E-mail",
                "phone": "Telefono",
                "message": "Messaggio",
                "policyLabel": "Accetto i Termini e le Condizioni",
                "marketingLabel": "Vorrei iscrivermi alla newsletter di Feed.Studio",
                "button": "Ottieni l'accesso anticipato"
            }
        }
    },
    "registration": {
        "meta": {
            "title": "Iscriviti gratuitamente a Feed Studio",
            "desc": {
                "short": "Iscriviti e ricevi $ 1000 di credito per Feed Studio.",
                "long": "Iscriviti e ricevi $ 1000 di credito di benvenuto per utilizzare Feed Studio."
            }
        }
    },
    "earlybirdreg": {
        "form": {
            "infoList": {
                "desc1": "",
                "desc2": "",
                "item1": "",
                "item2": "",
                "item3": "",
                "item4": "",
                "item5": "",
                "item6": "",
                "item7": ""
            }
        }
    },
    "login": {
        "title": "",
        "subtitle": "",
        "subtitle2": "",
        "googleButton": ""
    }
};