export default {
    "home": {
        "sec1": {
            "title": "Növeld bevételeidet. Könnyedén.",
            "cta": "Ingyenes letöltés",
            "col1": {
                "title": "növeld meg<br\/>a bevételed",
                "desc": "Optimalizáld a terméklistád szerkezetét, és növeld webáruházad bevételét akár 30%-kal."
            },
            "col2": {
                "title": "nyiss új értékesítési <br\/> csatornákat",
                "desc": "Adj el többet új értékesítési csatornákon."
            },
            "col3": {
                "title": "próbáld ki ingyenesen <br\/> határidő nélkül",
                "desc": "Tanácsadóink elkészítik és optimalizálják a feedeket."
            },
            "col4": {
                "title": "takarítsd meg <br\/> az időd",
                "desc": "tanácsadóink elkészítik és optimalizálják a feedeket"
            }
        },
        "sec2": {
            "title": "termék import és export könnyedén",
            "col1": {
                "title": "beszállítói feed <br\/> menedzsment",
                "desc": "A Feed Studio egyesíti a különböző termékkatalógusokat egy importra kész bemenetté."
            },
            "col2": {
                "title": "Több mint 350 <br\/> további formátum",
                "desc": "Hirdesd termékeidet bármely platformon könnyedén."
            },
            "col3": {
                "title": "Optimalizáld termékeid <br\/> platformonként",
                "desc": "Ha szükséged van egy eltérő Google Shopping leírásra, mint az Instagramon."
            },
            "col4": {
                "title": "változástörténet",
                "desc": "Rossz árakat hirdetett, vagy leállt a termékfrissítése? Nálunk megtalálja a választ!"
            },
            "col5": {
                "title": "SEO optimalizálás",
                "desc": "Automatizált SEO metacímke létrehozása és optimalizálása."
            },
            "col6": {
                "title": "kezelt szolgáltatás",
                "desc": "A Feed Studio egyesíti a különböző termékcsatornákat egy importálható hírcsatornává."
            }
        },
        "sec3": {
            "title": "Szeretnéd növelni a webáruházad bevételéd könnyedén?"
        },
        "sec4": {
            "item2": {
                "subtitle": "Feed Kezelés"
            },
            "item4": {
                "subtitle": "Search console"
            }
        },
        "sec5": {
            "title": "Gyönyörűen automatizált Google Ads kampányok",
            "col1": {
                "title": "Márka hirdetések",
                "desc": "Tudta, hogy az ügyfelek 40% -a nem kattint a webhelyére a keresési eredményoldalon? Érje el őket márkakampányokkal!"
            },
            "col2": {
                "title": "Dinamikus keresési hirdetések"
            },
            "col3": {
                "title": "Vásárlási hirdetések"
            },
            "col4": {
                "title": "Display hirdetések",
                "desc": "Jutékony remarketing, dinamikus remarketing és intelligens hirdetések segítségével érje el célcsoportját a Google Display Hálózaton."
            }
        },
        "sec6": {
            "title": "Készen áll a hirdetés automatizálására?",
            "cta": "Ingyenes"
        },
        "sec7": {
            "title": "",
            "subtitle": "Átfogó szolgáltatások még ingyenes csomagban is"
        },
        "premier-partner-desc": "A Google megkülönbözteti minket a legjobban teljesítő ügynökségként, így biztos lehet benne, hogy a Google hirdetési stratégiája jó kezekben van.",
        "sec8": {
            "title": "",
            "cta": "LETÖLTÉS INGYEN"
        },
        "main": {
            "title": "<b>FEED<\/b>.ME.BABY",
            "description": "Regisztrálj early bird fiókot a legfrissebb Google feedkezelő <br\/> szoftver kipróbálásához, amelyet szakemberek és ügynökségek számára terveztünk. <br\/> Korlátozott ideig <s> $300 <\/s> $1000 kezdőkredittel.",
            "button": "Kérem az $1,000 kreditet"
        },
        "cta1": {
            "title": "EARLY BIRD PROMÓCIÓ",
            "description": "első felhasználóinkat $1000 kezdőkreditet jutalmazzuk a Feed Studio tesztelésére",
            "button": "Kérem az $1,000 kreditet",
            "subtitle": "Nincs szükség hitelkártyára!"
        },
        "secFeeds": {
            "title": "mit kell javítania a feededben?",
            "header": {
                "channel": "csatorna",
                "export": "export",
                "description": "leírás",
                "result": "eredmény"
            },
            "google": {
                "title": "Google",
                "feeds": {
                    "merchant": {
                        "name": "Merchant Center feed",
                        "desc": "add el a termékeidet Google Shoppingon",
                        "result": "növelj a bevételt optimalizált feed szerkezettel"
                    },
                    "promotions": {
                        "name": "Promóciós feed",
                        "desc": "mutasd meg promócióitat ügyfeleid számára",
                        "result": "növeld az átkattintási arányt és a konverziók számát"
                    },
                    "localInventory": {
                        "name": "Local Inventory hirdetési feed",
                        "desc": "mutasd meg termékeit a Google Maps-en",
                        "result": "növelj offline bevételt, hozz új ügyfeleket üzletedbe"
                    },
                    "productRatings": {
                        "name": "Product Rating feed",
                        "desc": "tedd elérhetővé termékértékeléseid és véleményeit a Google felhasználói számára",
                        "result": "magasabb átkattintási arány"
                    },
                    "dynamicRemarketing": {
                        "name": "Dinamikus remarketingcsatornák",
                        "desc": "hirdetéseket megjelenítése azok számára, akik korábban már meglátogatták a webhelyet",
                        "result": "bevételnövelés"
                    },
                    "page": {
                        "name": "Oldaladatfeed",
                        "desc": "Dinamikus keresési hirdetések oldalcsatornája",
                        "result": "növeld a keresési kampányok teljesítményét"
                    },
                    "sitemap": {
                        "name": "Webhelytérkép-hírcsatorna",
                        "desc": "URL-hírcsatorna a keresőmotor optimalizálása érdekében",
                        "result": "növeld a láthatóságod a Google Keresőben"
                    },
                    "manufacturer": {
                        "name": "Gyártói feed",
                        "desc": "jobb és pontosabb információt szolgáltathat termékeiről a Google számára",
                        "result": "szerezz átfogó elemzést és javítsd jelenléted a Google-on"
                    }
                }
            },
            "facebook": {
                "title": "Facebook",
                "feeds": {
                    "dynamicAds": {
                        "name": "Dinamikus hirdetések hírcsatornája",
                        "desc": "hirdetéseket megjelenítése azok számára, akik korábban már meglátogatták a webhelyet",
                        "result": "bevételnövelés"
                    },
                    "pageShop": {
                        "name": "Page Shop hírcsatorna",
                        "desc": "termékeket mutathat és adhat el az embereknek a Facebookon",
                        "result": "bevételnövelés"
                    },
                    "marketplace": {
                        "name": "Marketplace feed",
                        "desc": "termékeket mutathat és adhat el az embereknek a Facebook Marketplace-en",
                        "result": "bevételnövelés"
                    }
                }
            },
            "instagram": {
                "title": "Instagram",
                "feeds": {
                    "shopping": {
                        "name": "Shopping feed",
                        "desc": "termékeket mutathat és adhat el az embereknek Instagram",
                        "result": "bevételnövelés"
                    }
                }
            },
            "amazon": {
                "title": "Amazon",
                "feeds": {
                    "product": {
                        "name": "Termék feed",
                        "desc": "termékek bemutatása és eladása az emberek számára az Amazonon",
                        "result": "bevételnövelés"
                    }
                }
            },
            "ebay": {
                "title": "eBay",
                "feeds": {
                    "product": {
                        "name": "Termék feed",
                        "desc": "hirdesd termékekeid az Ebay-en",
                        "result": "bevételnövelés"
                    }
                }
            },
            "criteo": {
                "title": "Criteo",
                "feeds": {
                    "product": {
                        "name": "Termék feed",
                        "desc": "add el a termékeidet online",
                        "result": "bevételnövelés"
                    }
                }
            }
        },
        "cta2": {
            "title": "NÖVELD AZ ELÁDÁSAID FEED STUDIO-VAL",
            "button": "KÉRD AZ $1.000 KREDITET",
            "subtitle": "Nincs szükség hitelkártyára!"
        },
        "meta": {
            "title": "Feed Studio » XML Feed kezelő eszköz - XML Feed generátor - Google Shopping Feed",
            "desc": {
                "short": "Google Shopping Feed generátor eszköz.",
                "long": "A Google Shopping Feed generátor"
            }
        }
    },
    "pricing": {
        "plugin": {
            "col1": {
                "header": "FUNKCIÓK \/ CSOMAGOK",
                "row1": "Korlátlan mennyiségű termék",
                "row2": "Google Ads - Shopping hírcsatorna",
                "row3": "Google hirdetések - dinamikus banner hirdetések terméklista",
                "row4": "Google Ads - DSA Pagefeed",
                "row5": "Facebook Dinamikus Termékhirdetés",
                "row6": "Arukereso.hu Feed",
                "row7": "Criteo Feed",
                "row8": "RTB House Feed",
                "row9": "Google Ads Automatizálás",
                "row10": "Egyedi xml importálása és generálása",
                "row11": "Webináriumok",
                "row12": "Dedikált kapcsolattartó",
                "row13": "Kezdőkredit",
                "row14": "Havidíj",
                "row15": "Ár \/ 10 000 művelet"
            },
            "agency": "Az összes pénz visszatér, ha 3 hónapon belül nem teljesítjük a várt eredményeket.",
            "col2": {
                "header": "INGYEN ÖRÖKÉLET",
                "row6": "Heti",
                "cta": "Regisztráció",
                "row1": "Beépített irányítópult",
                "row14": "$ 9",
                "row15": "nem elérhető"
            },
            "col3": {
                "header": "PRÉMIUM",
                "row6": "Napi",
                "cta": "Regisztráció",
                "row1": "Prémium irányítópult",
                "row13": "$ 300",
                "row14": "$ 9",
                "row15": "$ 1"
            },
            "col4": {
                "row6": "Valós idejű",
                "cta": "Regisztráció",
                "row1": "VIP irányítópult",
                "row10": "Privát webináriumok",
                "header": "Early Bird Premium",
                "row13": "$1000",
                "row14": "$9",
                "row15": "$1"
            }
        },
        "agency": {
            "allin": {
                "li6": "Chatbot",
                "li9": "Pénzvisszafizetési garancia*"
            }
        },
        "title": "Az egésznek megfelelő tervek \n marketing költségvetés",
        "sec2": {
            "title": "Uralja az online növekedést",
            "cta": "INGYENES"
        },
        "sec6": {
            "title": "Ha technikai támogatásra van szüksége, keresse fel a támogatási irányítópultot.",
            "cta": "TÁMOGATÁS"
        },
        "header": {
            "title": "ÁRAZÁS",
            "sub": "fizess valós használat alapon"
        }
    },
    "testimonials": {
        "title": "Amit a felhasználók mondnak",
        "1": {
            "desc": "Már majdnem egy éve dolgozunk együtt a Google hirdetési kampányokon. Mindig tapasztaltunk gyors és professzionális munkát az ügynökségtől, nagyon hozzáértőek, együttműködőek és szeretnék teljes mértékben kielégíteni igényeinket. Az innovatív megoldások bevezetése mellett elég nyugodtak is, hogy mélyen elmagyarázzák a komplex problémák megoldásait és a kempingezés eredményeit.",
            "name": "Adam Dallos",
            "pos": "PwC, igazgatóhelyettes, marketing, kommunikációs üzletfejlesztés"
        }
    },
    "footer": {
        "description": ""
    },
    "menu": {
        "navigation": "NAVIGÁCIÓ",
        "signin": "Bejelentkezés",
        "features": "Jellemzők",
        "pricing": "Árazás",
        "blog": "blog",
        "terms-conditions": "Felhasználási feltételek",
        "partners": "partnerségi",
        "affiliate": "Partner",
        "whitelabel": "fehér címke",
        "introducing": "Bemutatjuk a partnert",
        "findus": "Elérhetőségünk",
        "home": "itthon",
        "dashboard": "Irányítópult",
        "contact": "Kapcsolatba lépni"
    },
    "features": {
        "title": "A Google hirdetései <br\/> könnyebbek, mint valaha",
        "subtitle": "",
        "sec1": {
            "title": "Miért hirdet a Google-on?",
            "col1": {
                "subtitle": "Verem a versenyt",
                "desc": "Minden nap az emberek keresik az Ön márkáját vagy termékeit, a Google lehetőséget ad arra, hogy vállalkozása a verseny előtt álljon."
            },
            "col2": {
                "subtitle": "Érje el az ideális ügyfeleket",
                "desc": "A Google az egyik legjobb hirdetési platform, amely a megfelelő felhasználókat a megfelelő időben, minden elkötelezettség szintjén megcélozza."
            },
            "col3": {
                "subtitle": "Növelje értékesítését",
                "desc": "A webhelyére érkező forgalom jelentősen növekszik a Google révén, olyan látogatókkal, akik könnyen ügyfelekké válhatnak, növelve az értékesítést és az online üzleti tevékenységet."
            }
        },
        "sec2": {
            "title": "Uralja az online növekedést",
            "cta": "Próbáld ki INGYEN"
        }
    },
    "getstarted": {
        "title": "Kezdje el három egyszerű lépéssel",
        "col1": {
            "subtitle": "1. Töltse le",
            "desc": ""
        },
        "col2": {
            "subtitle": "2. Aktiválja",
            "desc": ""
        },
        "col3": {
            "subtitle": "3. Kövesse nyomon",
            "desc": ""
        }
    },
    "dashboard": {
        "title": "Irányítópult",
        "domainsettings": {
            "title": "DOMAIN BEÁLLÍTÁSOK",
            "subtitle1": "Új domain hozzáadása",
            "input1": "Az új domain",
            "cta1": "hozzáad",
            "subtitle2": "Jelenlegi domainjei",
            "currentdomains1": "Tartomány",
            "currentdomains2": "API kulcs"
        },
        "subscription": {
            "title": "FELIRATKOZÁS",
            "subtitle1": "Egyensúly",
            "remaining": "többi"
        },
        "tab2": {
            "desc1": "Jelenleg a mi",
            "desc2": "Feliratkozás",
            "cta": "KAPCSOLAT LEÍRÁS"
        },
        "billing": {
            "title": "Számlázási adatok",
            "input": {
                "name": "Számlázási név",
                "vat": "Adószám",
                "address": "Cím",
                "city": "Város",
                "zip": "irányítószám",
                "country": "Ország"
            }
        },
        "user": {
            "title": "FELHASZNÁLÓI BEÁLLÍTÁSOK",
            "subtitle": "Az Ön bejelentkezési adatai",
            "lastlogin": "Utolsó bejelentkezés:"
        }
    },
    "cta": {
        "update": "UPDATE"
    },
    "input": {
        "email": "Email cím",
        "password": "Jelszó",
        "passwordrepeat": "Jelszó újra"
    },
    "partners": {
        "affiliate": {
            "subtitle": "Exkluzív bónuszok és promóciók <br> a leányvállalatok fellendítéséhez",
            "substitle2": "A kapcsolt marketing eszközök és fizetési tervek egyesítése a profit növelése érdekében!"
        },
        "affilieate": {
            "description": ""
        },
        "whitelabel": {
            "title": "Fehér Címke partnerség"
        },
        "introducing": {
            "subtitle": "",
            "desc2": "További információkért kérjük, vegye fel a kapcsolatot speciális csapatunkkal a következő címen:"
        }
    },
    "url": {
        "features": "jellemzők",
        "pricing": "árazás",
        "partnership": {
            "affiliate": "patnership \/ társult",
            "whitelabel": "patnership \/ Fehér címke",
            "introductory": "patnership \/ bevezető"
        },
        "contact": "kapcsolatba lépni",
        "login": "Belépés"
    },
    "b2c": {
        "main": {
            "title": "<b>FEED<\/b>.KEZELÉS"
        },
        "cta1": {
            "title": "KERESKEDELES MŰKÖDÉS",
            "description": "Minden early bird felhasználó $1000 kreditet kap a Feed Studio Premium csomag használatára. <br\/> <br\/> Nincs próbaidőszak, nincs szükség hitelkártyára.",
            "button": "$1000 promóciós kredit"
        },
        "sec1": {
            "title": "Hogyan lehet használni a feed.studio szoftvert",
            "col1": {
                "title": "iratkozz fel és add meg <br\/> a forrás feeded",
                "desc": "add meg nekünk elérhetőségéd és feeded adatait fiók beállításához"
            },
            "col2": {
                "title": "kollegáink létrehozzák <br\/> az export feeded",
                "desc": "a forrás feedből elkészítjük az exportod"
            },
            "col3": {
                "title": "Élvezd (az eredményeket)",
                "desc": "Kollegáink biztosítják neked a szakmailag és technikailag megfelelő kimenetet."
            }
        },
        "sec2": {
            "title": "Iratkozzon fel a promócióra",
            "infoList": {
                "item1": "Nincs időkorlát a promóciós kreditekre",
                "item2": "az promóció végén automatikusan nem számolunk fel díjakat",
                "item3": "Nincs termék- vagy export limit",
                "item4": "Menedzselt szolgáltatás - nem kell megtanulnod a platformot",
                "item5": "Bármikor törölheted fiókod",
                "item6": "Kérhetsz egyedi export formátumokat"
            },
            "form": {
                "name": "Név",
                "email": "Email",
                "phone": "Telefon",
                "feedUrl": "Feed URL",
                "policyLabel": "Elfogadom a szerződési feltételeket",
                "marketingLabel": "Feliratkoznék a Feed.Studio hírlevélre",
                "button": "kérj early bird hozzáférést",
                "feedType": "Export feed formátuma"
            }
        },
        "meta": {
            "title": "XML Feed generátor, Google Shopping és Facebook Feed",
            "desc": {
                "short": "Készítsen és optimalizáljon Google és Facebook feedjeit.",
                "long": "Google Shopping Feed, Termék Feed Optimalizálás."
            }
        }
    },
    "b2b": {
        "main": {
            "title": "<b> FEED <\/b> .PARTNEREK",
            "description": "A Feed.Studio e-kereskedelemben részt vevő felek és ügynökségek számára készült. Regionális partnereket keresünk a sikeres jövő építéséhez. <\/br> Növelje bevételeit, többet értékesítsen a Feed.Studio segítségével!",
            "button": "TOVÁBBI ELADÁS"
        },
        "sec1": {
            "title": "Miért dolgozzon velünk?",
            "col1": {
                "title": "elad többet",
                "desc": "elad többet"
            },
            "col2": {
                "title": "verte meg <\/br> versenytársak",
                "desc": "Legyél az első"
            },
            "col3": {
                "title": "kielégíti a <\/br> ügyfelek",
                "desc": "minőség ellenőrzés"
            },
            "col4": {
                "title": "időt takarít meg",
                "desc": "az ápolt szolgálatunkkal"
            }
        },
        "secPartner": {
            "title": "partnerségek",
            "header": {
                "affiliate": "Partner",
                "gold": "Arany",
                "exclusive": "Kizárólagos",
                "custom": "Egyedi"
            },
            "requirements": {
                "title": "követelmények",
                "newClient": "új ügyfelek \/ év",
                "certifiedusers": "hitelesített aktív belső felhasználó"
            },
            "recognition": {
                "title": "Elismerés",
                "visible": "látható a feed.studio oldalon",
                "trademark": "a „feed studio” védjegy használata"
            },
            "training": {
                "title": "Képzési előnyök",
                "biweekly": "kéthetenkénti ülések",
                "salesSupport": "értékesítési támogatás és útmutatás"
            },
            "marketing": {
                "title": "marketing előnyei",
                "brand": "márka a helyi kampányokban",
                "customerStories": "vásárlói sikertörténetek"
            },
            "sales": {
                "title": "értékesítési előnyök",
                "materials": "értékesítési anyagok",
                "accountManagers": "dedikált számlavezető",
                "leads": "helyi vezet",
                "affiliateBonus": "BÓNUSZ KAPCSOLATOS ÜGYFELEKNEK",
                "feedStudioBonus": "bónusz a Feed Stúdióban"
            },
            "contactUs": "Lépjen kapcsolatba velünk",
            "signUp": "Regisztrálj"
        },
        "sec3": {
            "title": "hogyan lehetünk partnerünk?",
            "col1": {
                "title": "regisztrálj",
                "desc": ""
            },
            "col2": {
                "title": "vegyen részt értékesítési és platform tréningeinkben",
                "desc": ""
            },
            "col3": {
                "title": "kezelheti leadjainkat és ügyfeleinket",
                "desc": ""
            }
        },
        "sec4": {
            "title": "Iratkozzon fel a PARTNER PROGRAMRA",
            "infoList": {
                "item1": "hozzáférés a feed stúdióba, hogy megvitassák a stratégiákat, az értékesítési és szolgáltatási kérdéseket",
                "item2": "láthatóság és felismerés azzal, hogy hivatalos partnerként szerepel a Feed Studio partnerek oldalán",
                "item3": "hozzáférés az Értékesítés irányítópultjához"
            },
            "form": {
                "plan": {
                    "affiliate": "Partner",
                    "gold": "Arany",
                    "custom": "Egyedi"
                },
                "name": "Név",
                "email": "Email",
                "phone": "Telefon",
                "message": "Üzenet",
                "policyLabel": "Elfogadom a szerződési feltételeket",
                "marketingLabel": "Feliratkoznék a Feed.Studio hírlevélre",
                "button": "Korai hozzáférés"
            }
        }
    },
    "registration": {
        "meta": {
            "title": "Feed Studio Ingyenes Regisztráció",
            "desc": {
                "short": "Iratkozzon fel és szerezzen 1000 USD jóváírást a Feed Studio számára.",
                "long": "Iratkozzon fel, és kap 1000 dollár üdvözlő jóváírást a Feed Studio használatához."
            }
        }
    },
    "earlybirdreg": {
        "form": {
            "infoList": {
                "desc1": "Szerezz meg mindent, ami a legjobb dinamikus termékhirdetésekhez kell.",
                "desc2": "$1000 promóciós kredit ingyen » Regisztrálj és kapj $1000 induló kreditet a Feed Studio használatához",
                "item1": "A próbaidőszak végén nincs automatikus terhelés",
                "item2": "Az ajándék kreditek bármeddig felhasználhatók",
                "item3": "A próbaidőszak végén nem számlázunk",
                "item4": "Nincsen termék vagy export limit",
                "item5": "Menedzselt szolgáltatás",
                "item6": "Az előfizetés bármikor felfüggeszthető",
                "item7": "Egyedi export formátumot is kérhet"
            }
        }
    },
    "login": {
        "title": "Belépés",
        "subtitle": "Használd a belépéshez a Google fiókod",
        "subtitle2": "A belépés indításához kattints a gombra!",
        "googleButton": "Belépsé Google fiókkal"
    }
};