import Axios from 'axios'

export default class ApiService {

    client;
    user;

    constructor(user = null) {
        let options = {
            baseURL: apiUrl,
            timeOut: 10000
        }

        if(user !== null && user.token !== undefined && user.token !== null) {
            options.headers = {
                auth: user.token
            }
        }

        this.user = user
        this.client = Axios.create(options)
    }

    async authWithAccessToken(accessToken) {
        return this.client.get(`/users/googleAuth/accessToken?accessToken=${accessToken}`)
        .then((res) => {
            this.client = Axios.create({
                baseURL: apiUrl,
                timeOut: 10000,
                headers: {
                    auth: res.data.data.token
                }
            })

            localStorage.setItem("user", JSON.stringify(res.data.data))

            return res
        })
    }

    async googleAuth(authCode) {
        return this.client.post("/users/googleAuth", {
            code: authCode
        })
        .then((res) => {
            this.client = Axios.create({
                baseURL: apiUrl,
                timeOut: 10000,
                headers: {
                    auth: res.data.data.token
                }
            })

            localStorage.setItem("user", JSON.stringify(res.data.data))

            return res
        })
    }

    async getAuthUrl(redirectUrl) {
        return this.client.get("/users/googleAuth/url?redirectUrl="+redirectUrl)
    }

    async getMe() {
        return this.client.get("/me")
    }

    async earlyBirdSubscription(name, email, phone, feedType, feedUrl) {
        return this.client.post("/early-bird", {
            name,
            email,
            phone,
            feedType,
            feedUrl
        })
    }
}

export const apiUrl = process.env.REACT_APP_API_URL