import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from 'redux/reducers';
import thunkMiddleware from 'redux-thunk'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: Function;
  }
}

let composedEnhancer = null
let middlewareEnhancer = applyMiddleware(
  thunkMiddleware
)
let devToolEnhancement = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()

if(devToolEnhancement !== undefined && devToolEnhancement !== null) {
  composedEnhancer = compose(middlewareEnhancer, devToolEnhancement)
}
else {
  composedEnhancer = middlewareEnhancer
}

const globalStore = createStore(
  rootReducer,
  composedEnhancer
);

export { globalStore }