export default {
    "home": {
        "sec1": {
            "title": "Aumente sua receita. Sem esforço.",
            "subtitle": "GPlugin automatiza anúncios do Google em Wordpress, WooCommerce",
            "cta": "Download grátis"
        },
        "sec2": {
            "title": "O GPlugin é como ter um especialista em anúncios do Google 24 horas por dia, 7 dias por semana, gratuitamente",
            "col1": {
                "title": "Um clique <br\/> assistente de configuração",
                "desc": "O GPlugin configura e gerencia suas contas do Google Ads, Merchant Center e Search Console sem que você precise levantar um dedo."
            },
            "col2": {
                "title": "Automatizado <br\/> sincronismo do produto",
                "desc": "O GPlugin se comunica diretamente com o Google, você não precisa criar e gerenciar feeds de produtos do comerciante"
            },
            "col3": {
                "title": "Automated <br\/> build de campanha",
                "desc": "O plug-in de anúncios do Google cria suas campanhas de pesquisa, compras e exibição no Google a partir do Wordpress, Woocommerce."
            },
            "col4": {
                "title": "Dashboard <br\/> no WordPress",
                "desc": "Todas as configurações e relatórios do Google Ads, do Merchant Center, do Search Console e relatórios integrados ao seu painel do Wordpress."
            }
        },
        "sec3": {
            "title": "Quer crescer o seu negócio sem a luta?"
        },
        "sec4": {
            "title": "All-in-one Wordpress Plugin para o Google",
            "item1": {
                "subtitle": "Etiquetagem de remarketing",
                "desc": "Quais usuários converterão os melhores? Os que já visitaram sua loja - GPlugin garante que você se envolva com eles"
            },
            "item2": {
                "subtitle": "Gerenciamento de feed",
                "desc": "O GPlugin sincroniza seu catálogo de produtos automaticamente com o Google Ads e o Merchant Center sem a dificuldade."
            },
            "item3": {
                "subtitle": "Gestão de anúncios do Google",
                "desc": "Suas campanhas e anúncios estão prontos para trazer clientes para você logo após a configuração do plug-in."
            },
            "item4": {
                "subtitle": "Console de pesquisa",
                "desc": "O GPlugin alimenta automaticamente o tráfego de pesquisa e os relatórios de desempenho do Search Console no seu Painel."
            }
        },
        "sec5": {
            "title": "Campanhas de anúncios do Google lindamente automatizadas",
            "col1": {
                "title": "Anúncios de marca",
                "desc": "Você sabia que 40% dos seus clientes não clicam no seu site na página de resultados de pesquisa? Alcance-os com campanhas de marca!"
            },
            "col2": {
                "title": "Anúncios de pesquisa dinâmicos",
                "desc": "O GPlugin conecta seu inventário a pesquisas relevantes e cria textos de anúncios atraentes."
            },
            "col3": {
                "title": "Anúncios de compras",
                "desc": "Criação inteligente, de vitrines e de campanhas de compras padrão em poucos minutos - com o GPlugin!"
            },
            "col4": {
                "title": "Anúncios de visualização",
                "desc": "Alcance seu público-alvo na Rede de Display do Google com remarketing responsivo, remarketing dinâmico e anúncios inteligentes."
            }
        },
        "sec6": {
            "title": "Pronto para automatizar seu anúncio?",
            "cta": "Liberta-te"
        },
        "sec7": {
            "title": "TAXA DE SUBSCRIÇÃO GPLUGIN",
            "subtitle": "Serviços abrangentes, mesmo no plano gratuito"
        },
        "premier-partner-desc": "Somos reconhecidos pelo Google como uma agência de melhor desempenho para que você tenha certeza de que sua estratégia de anúncios do Google está em boas mãos.",
        "sec8": {
            "title": "Seja um jogador do GPlugin agora.",
            "cta": "DOWNLOAD GRÁTIS"
        }
    },
    "pricing": {
        "plugin": {
            "col1": {
                "header": "CARACTERÍSTICAS \/ PLANOS",
                "row1": "painel de controle",
                "row2": "Estatisticas",
                "row3": "Campanhas de pesquisa",
                "row4": "Exibir campanhas",
                "row5": "Campanhas do Shopping",
                "row6": "Sincronização",
                "row7": "Suporte do Fórum",
                "row8": "Suporte por email",
                "row9": "Suporte por chat",
                "row10": "Webinars",
                "row11": "Suporte do Google Meet",
                "row12": "Gerente de Contas Dedicado",
                "row13": "Total Mensal"
            },
            "footer": "A taxa de inscrição de plug-in não inclui custos de publicidade.",
            "agency": "Todo seu dinheiro de volta se não entregarmos os resultados esperados em 3 meses.",
            "col2": {
                "header": "LIVRE DA VIDA",
                "row6": "Semanal",
                "cta": "DOWNLOAD GRÁTIS",
                "row1": "Painel embutido"
            },
            "col3": {
                "header": "PRÊMIO",
                "row6": "Diariamente",
                "cta": "COMECE O TESTE GRÁTIS",
                "row1": "Painel Premium"
            },
            "col4": {
                "row6": "Tempo real",
                "cta": "COMECE O TESTE GRÁTIS",
                "row1": "Painel VIP",
                "row10": "Webinars privados"
            }
        },
        "agency": {
            "title": "Serviços do Campaign Manager",
            "google": {
                "title": "GOOGLE",
                "time": "\/ mo",
                "li1": "Gerente de Contas Dedicado",
                "li2": "Gerenciamento do Analytics",
                "li3": "Relatórios do Data Studio",
                "li4": "Anúncios do Google: pesquisa, compras, exibição, YouTube",
                "li5": "E-mail, bate-papo, telefone e suporte do Google Meet",
                "li6": "Consultoria mensal",
                "li7": "Garantia de devolução de dinheiro*",
                "cta": "SE INSCREVER"
            },
            "allin": {
                "li1": "Gerente Sênior de Contas Dedicado",
                "li2": "Gerenciamento do Analytics",
                "li3": "Relatórios do Data Studio",
                "li4": "Anúncios do Google: pesquisa, compras, exibição, YouTube",
                "li5": "Facebook, Instagram, LinkedIn, Bing, Twitter, Snapchat, Anúncios do Reddit",
                "li6": "Chatbot",
                "li7": "E-mail, bate-papo, telefone e suporte do Google Meet",
                "li8": "Consultoria semanal",
                "li9": "Garantia de devolução de dinheiro*",
                "cta": "SE INSCREVER"
            },
            "enterprise": {
                "title": "EMPREENDIMENTO",
                "price": "personalizadas",
                "desc": "Preciso de mais? Solicitar serviços de agência personalizados.",
                "cta": "CONTATE-NOS"
            }
        },
        "title": "Planos que se ajustam ao seu todo \n orçamento de marketing",
        "subtitle": "Integração, otimização, criatividade - GPlugin faz tudo para você com um incrível apoio de agência de marketing.",
        "sec2": {
            "title": "Domine seu crescimento on-line",
            "cta": "LIBERTA-TE"
        },
        "sec6": {
            "title": "Se você precisar de suporte técnico, visite nosso painel de suporte.",
            "cta": "APOIO, SUPORTE"
        }
    },
    "testimonials": {
        "title": "O que nossos usuários dizem",
        "1": {
            "desc": "Trabalhamos juntos em campanhas de anúncios do Google há quase um ano. Nós sempre experimentamos um trabalho rápido e profissional da agência, eles são muito competentes, cooperativos e procuram satisfazer plenamente as nossas necessidades. Além de trazer soluções inovadoras, elas são suficientemente calmas para explicar profundamente as soluções para problemas complexos e os resultados das campanhas.",
            "name": "Adam Dallos",
            "pos": "PwC, Assistant Manager, Marketing, Desenvolvimento de Negócios de Comunicação"
        }
    },
    "footer": {
        "description": "O GPlugin permite que as lojas de comércio eletrônico anunciam com eficiência nas plataformas do Google. Criado por comerciantes, para varejistas - nosso objetivo é tornar seu sucesso de marketing fácil e sem esforço."
    },
    "menu": {
        "navigation": "NAVEGAÇÃO",
        "signin": "Assinar em",
        "features": "Características",
        "pricing": "Preços",
        "blog": "Blog",
        "terms-conditions": "termos e Condições",
        "partners": "PARCERIA",
        "affiliate": "Afiliado",
        "whitelabel": "etiqueta branca",
        "introducing": "Apresentando o parceiro",
        "findus": "Encontrar-nos no",
        "home": "Casa",
        "dashboard": "painel de controle",
        "contact": "Contato"
    },
    "features": {
        "title": "Publicidade no Google <br\/> tornou mais fácil do que nunca",
        "subtitle": "GPlugin traz-lhe as ferramentas e faz todo o trabalho <br\/> que você vai precisar para executar campanhas de marketing digital de sucesso <br\/> em sua loja de comércio eletrônico.",
        "sec1": {
            "title": "Por que anunciar no Google?",
            "col1": {
                "subtitle": "Vencer sua competição",
                "desc": "Todos os dias, as pessoas pesquisam sua marca ou seus produtos. O Google oferece a possibilidade de levar sua empresa à frente de seus concorrentes."
            },
            "col2": {
                "subtitle": "Alcance clientes ideais",
                "desc": "O Google é uma das melhores plataformas de publicidade para segmentar os usuários certos no momento certo em todos os níveis de engajamento."
            },
            "col3": {
                "subtitle": "Aumente suas vendas",
                "desc": "O tráfego para o seu site aumentará consideravelmente com o Google, com visitantes que podem facilmente se transformar em clientes, aumentando suas vendas e negócios on-line."
            }
        },
        "sec2": {
            "title": "Domine seu crescimento on-line",
            "cta": "TENTE GRÁTIS"
        }
    },
    "getstarted": {
        "title": "Comece com 3 etapas fáceis",
        "col1": {
            "subtitle": "1. Download",
            "desc": "Baixe, instale e ative o GPlugin em sua loja de comércio eletrônico."
        },
        "col2": {
            "subtitle": "2. Ativar",
            "desc": "Registre-se para uma conta no GPlugin e use sua chave de API para conectar sua loja."
        },
        "col3": {
            "subtitle": "3. Trilha",
            "desc": "Rastreie análises e fique tranquilo sabendo que o GPlugin está trabalhando em suas campanhas."
        }
    },
    "contact": {
        "title": "Nós adoraríamos ouvir de você!",
        "subtitle": "Como cliente, você é nossa prioridade número um. Nós realmente nos preocupamos com o modo como nossos serviços funcionam para você e queremos que você fique 100% satisfeito. Se você está infeliz, ficamos infelizes.",
        "companyname": "MTT Digital Kft.",
        "companydetails": "Széll Kálmán tér 11. \n 1024, Budapeste \n Hungria",
        "contactus": {
            "title": "Contate-Nos",
            "desc": "Envie suas perguntas ou comentários \n em relação aos nossos serviços para: \n hello@gplugin.uk"
        }
    },
    "dashboard": {
        "title": "painel de controle",
        "domainsettings": {
            "title": "CONFIGURAÇÕES DE DOMÍNIO",
            "subtitle1": "Adicionar novo domínio",
            "input1": "Seu novo domínio",
            "cta1": "Adicionar",
            "subtitle2": "Seus domínios atuais",
            "currentdomains1": "Domínio",
            "currentdomains2": "Chave API"
        },
        "subscription": {
            "title": "INSCRIÇÃO",
            "subtitle1": "Equilibrar",
            "remaining": "restante"
        },
        "tab2": {
            "desc1": "Você está usando nosso",
            "desc2": "inscrição",
            "cta": "SUBSCRIPÇÃO DO SWITCH"
        },
        "billing": {
            "title": "Detalhes de faturamento",
            "input": {
                "name": "Nome de cobrança",
                "vat": "Número de IVA",
                "address": "Endereço",
                "city": "Cidade",
                "zip": "Código postal",
                "country": "País"
            }
        },
        "user": {
            "title": "CONFIGURAÇÕES DO USUÁRIO",
            "subtitle": "Seus detalhes de login",
            "lastlogin": "Último login:"
        }
    },
    "cta": {
        "update": "ATUALIZAR"
    },
    "input": {
        "email": "Endereço de e-mail",
        "password": "Senha",
        "passwordrepeat": "Repita a senha"
    },
    "partners": {
        "affiliate": {
            "title": "Afiliado GPlugin <br> Parceiros",
            "subtitle": "Bónus e Promoções Exclusivas <br> para impulsionar Afiliados",
            "substitle2": "Uma fusão de ferramentas de marketing de afiliados e planos de pagamento para aumentar seus lucros!",
            "desc2": "<p> Obtenha os recursos de marketing necessários para promover o GPlugin com sucesso. O Programa de Afiliados GPlugin é um dos programas de afiliados financeiros mais lucrativos do mundo e sempre dá a você as melhores ferramentas para atingir seus objetivos! <\/p> \n <p> Para mais informações contacte a nossa equipa especializada em: <br> <a href=\"mailto:affiliate@gplugin.uk\"> affiliate@gplugin.uk <\/a> <\/p>"
        },
        "affilieate": {
            "description": "<ul> <li> Junte-se aos Afiliados do GPlugin e comece a ganhar <\/li> <li> Trusted & Ferramentas de marketing de alta conversão <\/li> <li> , Software de acompanhamento & Portal de afiliados fácil de usar <\/li> <li> Aumente sua receita com novas promoções mensalmente <\/li> <li> Os ganhos de afiliados nunca foram tão generosos <\/li> <li> Transforme o tráfego referido em ganhos com um Parceiro regulado do Google Premier <\/li> <li> O programa de afiliados online GPlugin oferece a melhor exposição com as melhores taxas de conversão, permitindo que os afiliados se beneficiem da marca globalmente reconhecida do Google Partners. <\/li> <\/ul> \n <ul> <li> As tecnologias internas sob medida são formuladas para os Afiliados GPlugin, incluindo um sistema de rastreamento de classe mundial, por meio do qual nossos afiliados podem acompanhar e monitorar facilmente todas as inscrições, acessos e tráfego de indicações. <\/li> <li> Uma biblioteca de recursos eficazes de marketing, incluindo banners e widgets, está disponível, permitindo que o GPlugin Affiliates facilite o acesso fácil e sem problemas à marca mundialmente reconhecida e globalmente reconhecida. <\/li> <li> Inscreva-se no Programa de Afiliados GPlugin para aproveitar os benefícios de uma parceria bem-sucedida, eficiente e lucrativa. <\/li> <\/ul>"
        },
        "whitelabel": {
            "title": "Parceria White Label"
        },
        "introducing": {
            "title": "GPlugin Apresentando Parceria",
            "subtitle": "Por que se tornar um parceiro de apresentação do GPlugin?",
            "desc2": "Para mais informações, entre em contato com nossa equipe especializada em:"
        }
    },
    "url": {
        "features": "características",
        "pricing": "preços",
        "partnership": {
            "affiliate": "patnerhip \/ afiliado",
            "whitelabel": "patnership \/ whitelabel",
            "introductory": "paternidade \/ introdutória"
        },
        "contact": "contato",
        "login": "entrar"
    }
};