import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import localeAr from "locales/ar.js"
import localeCs from "locales/cs.js"
import localeDe from "locales/de.js"
import localeEn from "locales/en.js"
import localeEs from "locales/es.js"
import localeFr from "locales/fr.js"
import localeGaIe from "locales/ga_IE.js"
import localeHiIn from "locales/hi_IN.js"
import localeHu from "locales/hu.js"
import localeIt from "locales/it.js"
import localeMs from "locales/ms.js"
import localePl from "locales/pl.js"
import localePtBr from "locales/pt_BR.js"
import localeRu from "locales/ru.js"
import localeSk from "locales/sk.js"
import localeTr from "locales/tr.js"
import localeUk from "locales/uk.js"
import localeZhCn from "locales/zh_CN.js"

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        resources: {
            ar: {
                translation: localeAr
            },
            cs: {
                translation: localeCs
            },
            de: {
                translation: localeDe
            },
            en: {
                translation: localeEn
            },
            es: {
                translation: localeEs
            },
            fr: {
                translation: localeFr
            },
            ga_IE: {
                translation: localeGaIe
            },
            hi_IN: {
                translation: localeHiIn
            },
            hu: {
                translation: localeHu
            },
            it: {
                translation: localeIt
            },
            ms: {
                translation: localeMs
            },
            pl: {
                translation: localePl
            },
            pt_BR: {
                translation: localePtBr
            },
            ru: {
                translation: localeRu
            },
            sk: {
                translation: localeSk
            },
            tr: {
                translation: localeTr
            },
            uk: {
                translation: localeUk
            },
            zh_CN: {
                translation: localeZhCn
            },
        },
        fallbackLng: "en",
        detection: {
            order: ['localStorage', 'path'],
            lookupQuerystring: 'lng',
            lookupLocalStorage: 'lng',
            lookupFromPathIndex: 0,
            caches: ['localStorage'],
        },
        interpolation: {
            escapeValue: false
        }
    });

if (!Object.keys(i18n.options.resources).includes(i18n.language)) {
    i18n.changeLanguage('en');
}

export default i18n;