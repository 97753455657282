export default {
    "home": {
        "sec1": {
            "title": "Cuir le d'ioncam. Go dícheallach.",
            "subtitle": "Uathoibríonn GPlugin Google Ads ar Wordpress, WooCommerce",
            "cta": "Íoslódáil saor in aisce"
        },
        "sec2": {
            "title": "Tá GPlugin cosúil le saineolaí Google Ads 24\/7 a bheith aige saor in aisce",
            "col1": {
                "title": "One-click <br\/> draoi thus",
                "desc": "Bunaíonn agus bainistíonn GPlugin do chuntais Google Ads, Ionad Ceannaíochta agus Cuardaigh Console gan tú a bheith ag tógáil méar."
            },
            "col2": {
                "title": "Sync táirge uathoibrithe <br\/>",
                "desc": "Déanann GPlugin cumarsáid dhíreach le Google, ní gá duit beathaí táirgí ceannaíochta a chruthú agus a bhainistiú"
            },
            "col3": {
                "title": "Uathoibrithe <br\/> thógáil feachtas",
                "desc": "Tógann plugin Google Ads do fheachtais cuardaigh Google, siopadóireachta agus taispeána ó Wordpress, Woocommerce."
            },
            "col4": {
                "title": "Painéal na <br\/> i WordPress",
                "desc": "Gach Google Ads, Ionad Ceannaíochta, suímh Chonsól Cuardaigh agus tuarascálacha comhtháite isteach i do Painéal na nIonstraimí Wordpress."
            }
        },
        "sec3": {
            "title": "Ar mhaith leat do ghnó a fhás gan an streachailt?"
        },
        "sec4": {
            "title": "Breiseán Wordpress uile-Google in Google",
            "item1": {
                "subtitle": "Athmhargadh Clibeáil",
                "desc": "Cé acu úsáideoirí a dhéanfaidh an chuid is fearr? Na daoine a thug cuairt ar do stór cheana féin - cinntíonn GPlugin go bhfanann tú i dteagmháil leo"
            },
            "item2": {
                "subtitle": "Bainistíocht Beatha",
                "desc": "Déanann GPlugin do chatalóg táirge a shioncronú go huathoibríoch le Google Ads agus Ionad na gCeannaithe gan an streachailt."
            },
            "item3": {
                "subtitle": "Google Ads Management",
                "desc": "Tá do fheachtais agus d'fhógraí réidh chun custaiméirí a thabhairt ceart duit tar éis duit an breiseán a bhunú."
            },
            "item4": {
                "subtitle": "Cuardaigh consól",
                "desc": "Cuireann GPlugin na tuairiscí cuardaigh tráchta agus feidhmíochta de chuid Search Console ar fáil go huathoibríoch i do Phainéal."
            }
        },
        "sec5": {
            "title": "Feachtais Google Ads uathoibrithe go hálainn",
            "col1": {
                "title": "Fógraí Branda",
                "desc": "An raibh a fhios agat nach ndéanann 40% de do chustaiméirí cliceáil ar do shuíomh ar leathanach torthaí cuardaigh? Buail isteach le feachtais bhranda iad!"
            },
            "col2": {
                "title": "Fógraí Cuardaigh Dinimiciúla",
                "desc": "Nascann GPlugin do fhardal le cuardaigh ábhartha agus cruthaíonn sé téacsanna ad tarraingteacha."
            },
            "col3": {
                "title": "Fógraí Siopadóireachta",
                "desc": "Cruthú cliste, taispeántais agus gnáthfheachtas siopadóireachta laistigh de chúpla nóiméad - le GPlugin!"
            },
            "col4": {
                "title": "Taispeáin Fógraí",
                "desc": "Déan do sprioclucht féachana a bhaint amach ar Google Display Network le hathphriontáil freagrúil, athphriontáil dinimiciúil agus fógraí cliste."
            }
        },
        "sec6": {
            "title": "Réidh le do fhógra a uathoibriú?",
            "cta": "Faigh Saor"
        },
        "sec7": {
            "title": "TÁILLE FOCHRÍOMHAÍOCHTA GPLUGIN",
            "subtitle": "Seirbhísí cuimsitheacha fiú sa phlean saor in aisce"
        },
        "premier-partner-desc": "Is é Google an t-idirdhealú atá déanta againn mar ghníomhaireacht a fheidhmíonn go barr ionas gur féidir leat a bheith cinnte go bhfuil do straitéis Google Ads i lámha maithe.",
        "sec8": {
            "title": "Bí mar imreoir GPLgin anois.",
            "cta": "DOWNLOAD SAOR IN AISCE"
        }
    },
    "pricing": {
        "plugin": {
            "col1": {
                "header": "GNÉITHE \/ PLEANANNA",
                "row1": "Painéal na nIonstraimí",
                "row2": "Staitisticí",
                "row3": "Feachtais cuardaigh",
                "row4": "Feachtais a thaispeáint",
                "row5": "Feachtais siopadóireachta",
                "row6": "Sioncrónú",
                "row7": "Tacaíocht an Fhóraim",
                "row8": "Tacaíocht ríomhphoist",
                "row9": "Tacaíocht Comhrá",
                "row10": "Webinars",
                "row11": "Tacaíocht Google Meet",
                "row12": "Bainisteoir Cuntais Tiomnaithe",
                "row13": "Iomlán Míosúil"
            },
            "footer": "Ní áirítear costais fógraíochta ar tháille suibscríobh breiseáin.",
            "agency": "Gach do chuid airgid ar ais mura ndéanaimid na torthaí ionchais a sheachadadh i 3 mhí.",
            "col2": {
                "header": "SAOR IN AISCE SAOR IN AISCE",
                "row6": "Seachtainiúil",
                "cta": "DOWNLOAD SAOR IN AISCE",
                "row1": "Painéal na nIonstraimí Tógtha"
            },
            "col3": {
                "header": "PRÉIMHE",
                "row6": "Laethúil",
                "cta": "TOSACH SAOR IN AISCE",
                "row1": "Phainéal préimhe"
            },
            "col4": {
                "row6": "Fíor-am",
                "cta": "TOSACH SAOR IN AISCE",
                "row1": "Phainéal VIP",
                "row10": "Seimineáir phríobháideacha"
            }
        },
        "agency": {
            "title": "Seirbhísí Bainisteoir Feachtais",
            "google": {
                "title": "GOOGLE",
                "time": "\/ mo",
                "li1": "Bainisteoir Cuntais Tiomnaithe",
                "li2": "Bainistíocht anailíse",
                "li3": "Tuairiscíonn Stiúideo Sonraí",
                "li4": "Fógraí Google: Cuardaigh, Siopadóireacht, Taispeáin, YouTube",
                "li5": "Ríomhphost, Comhrá, Fón, Google Meet Support",
                "li6": "Comhairliúchán míosúil",
                "li7": "Ráthaíocht aisíoc airgid *",
                "cta": "LEAGANAS"
            },
            "allin": {
                "li1": "Bainisteoir Sinsearach Cuntais Tiomnaithe",
                "li2": "Bainistíocht anailíse",
                "li3": "Tuairiscíonn Stiúideo Sonraí",
                "li4": "Fógraí Google: Cuardaigh, Siopadóireacht, Taispeáin, YouTube",
                "li5": "Facebook, Instagram, LinkedIn, Bing, Twitter, Snapchat, Fógraí Reddit",
                "li6": "Chatbot",
                "li7": "Ríomhphost, Comhrá, Fón, Google Meet Support",
                "li8": "Comhairliúchán seachtainiúil",
                "li9": "Ráthaíocht aisíoc airgid *",
                "cta": "LEAGANAS"
            },
            "enterprise": {
                "title": "FIONTAR",
                "price": "Saincheap",
                "desc": "An bhfuil níos mó uait? Iarr ar sheirbhísí gníomhaireachta pearsantaithe.",
                "cta": "GLAOIGH ORAINN"
            }
        },
        "title": "Pleananna a fheileann do do chuid iomlán \n buiséad margaíochta",
        "subtitle": "Comhtháthú, leas iomlán a bhaint, cruthaitheach - déanann GPlugin é go léir le tacaíocht iontach ó ghníomhaireacht margaíochta.",
        "sec2": {
            "title": "Dírigh ar do Fhás Ar Líne",
            "cta": "Faigh SAOR IN AISCE"
        },
        "sec6": {
            "title": "Má theastaíonn tacaíocht theicniúil uait, tabhair cuairt ar ár n-painéal tacaíochta.",
            "cta": "TACAÍOCHT"
        }
    },
    "testimonials": {
        "title": "Cad a deir ár n-úsáideoirí",
        "1": {
            "desc": "Táimid ag obair le chéile ar fheachtais Google Ad ar feadh beagnach bliain anois. Bhí obair thapa agus ghairmiúil againn i gcónaí ón ngníomhaireacht, tá siad an-inniúil, comhoibritheach agus ag iarraidh ár gcuid riachtanas a chomhlíonadh go hiomlán. Chomh maith le réitigh nuálacha a thabhairt tá siad socair go leor chun na réitigh ar fhadhbanna casta a mhíniú go domhain agus ar thorthaí an fheachtais.",
            "name": "Adam Dallos",
            "pos": "PwC, Bainisteoir Cúnta, Margaíocht, Forbairt Gnó Cumarsáide"
        }
    },
    "footer": {
        "description": "Tá GPlugin ag cur ar chumas siopaí ríomhthráchtála fógraíocht éifeachtach a dhéanamh ar Google Platforms. Cruthaithe ag margaíochta, do mhiondíoltóirí - is é ár gcuspóir do rath margaíochta a dhéanamh éasca agus gan dua."
    },
    "menu": {
        "navigation": "ULLMHÚ",
        "signin": "Sínigh isteach",
        "features": "Gnéithe",
        "pricing": "Praghsáil",
        "blog": "Blag",
        "terms-conditions": "Téarmaí ⁊ Coinníollacha",
        "partners": "COMHPHÁIRTÍOCHT",
        "affiliate": "Comhpháirtí",
        "whitelabel": "Lipéad bán",
        "introducing": "Comhpháirtí a thabhairt isteach",
        "findus": "Faigh Linn Ar",
        "home": "Baile",
        "dashboard": "Painéal na nIonstraimí",
        "contact": "Teagmháil"
    },
    "features": {
        "title": "Bhí an fhógraíocht ar Google <br\/> níos éasca ná riamh",
        "subtitle": "Tugann GPlugin tú na huirlisí agus a dhéanann gach an obair <br\/> go mbainfidh tú gá a rith feachtais margaíochta digití ar éirigh <br\/> ar do siopa ríomhthráchtáil.",
        "sec1": {
            "title": "Cén fáth go bhfógrófá ar Google?",
            "col1": {
                "subtitle": "Buail do Chomórtas",
                "desc": "Gach lá tá daoine ag cuardach do bhranda nó do tháirgí, tugann Google an cumas duit do ghnó a fháil os comhair do chomórtais."
            },
            "col2": {
                "subtitle": "Custaiméirí sármhaitheasa a bhaint amach",
                "desc": "Tá Google ar cheann de na hardáin fógraíochta is fearr chun díriú ar na húsáideoirí cearta ag an am ceart ar fud gach leibhéal rannpháirtíochta."
            },
            "col3": {
                "subtitle": "Do Dhíolacháin a Fhás",
                "desc": "Méadóidh an trácht ar do láithreán gréasáin go mór trí Google le cuairteoirí ar féidir leo dul isteach i gcustaiméirí go héasca, ag fás do dhíolachán agus do ghnó ar líne."
            }
        },
        "sec2": {
            "title": "Dírigh ar do Fhás Ar Líne",
            "cta": "TRÍ SAOR IN AISCE"
        }
    },
    "getstarted": {
        "title": "Tús a chur le 3 chéim éasca",
        "col1": {
            "subtitle": "1. Íoslódáil",
            "desc": "Íoslódáil, suiteáil agus gníomhaigh GPlugin ar do stór ríomhthráchtála."
        },
        "col2": {
            "subtitle": "2. Gníomhachtaigh",
            "desc": "Cláraigh le haghaidh cuntas ag GPlugin agus bain úsáid as do eochair API chun do stór a cheangal."
        },
        "col3": {
            "subtitle": "3. Rian",
            "desc": "Déan anailís ar an anailísíocht agus an chuid eile go héasca agus tú eolach go bhfuil GPlugin ag obair ar do chuid feachtas."
        }
    },
    "contact": {
        "title": "Ba bhreá linn cloisteáil uait!",
        "subtitle": "Mar chustaiméir, is tusa an príomhthosaíocht atá againn. Táimid an-chúramach faoin gcaoi a n-oibríonn ár seirbhísí duit agus ba mhaith linn go mbeifeá sásta 100%. Má tá tú míshásta, táimid míshásta.",
        "companyname": "MTT Digital Kft.",
        "companydetails": "Széll Kálmán tér 11. \n 1024, Búdaipeist \n An Ungáir",
        "contactus": {
            "title": "Glaoigh orainn",
            "desc": "Seol do cheisteanna nó do thuairimí \n maidir lenár seirbhísí: \n hello@gplugin.uk"
        }
    },
    "dashboard": {
        "title": "Painéal na nIonstraimí",
        "domainsettings": {
            "title": "LÁITHREÁIN DOMAIN",
            "subtitle1": "Cuir fearann nua leis",
            "input1": "Do réimse nua",
            "cta1": "Cuir leis",
            "subtitle2": "Do réimsí reatha",
            "currentdomains1": "Fearann",
            "currentdomains2": "Eochair API"
        },
        "subscription": {
            "title": "SINTIÚS",
            "subtitle1": "Iarmhéid",
            "remaining": "fágtha"
        },
        "tab2": {
            "desc1": "Tá tú ag baint úsáide as ár n-úsáid faoi láthair",
            "desc2": "sintiús",
            "cta": "FÓGRAÍOCHT SWITCH"
        },
        "billing": {
            "title": "Sonraí billeála",
            "input": {
                "name": "Ainm billeála",
                "vat": "uimhir CBL",
                "address": "Seoladh",
                "city": "Cathair",
                "zip": "cód zip",
                "country": "Tír"
            }
        },
        "user": {
            "title": "LÁITHREÁIN ÚSÁIDEOIRÍ",
            "subtitle": "Do shonraí logála isteach",
            "lastlogin": "Logáil isteach is déanaí:"
        }
    },
    "cta": {
        "update": "Nuashonrú"
    },
    "input": {
        "email": "Seoladh ríomhphoist",
        "password": "Pasfhocal",
        "passwordrepeat": "athscríobh an Pasfhocal"
    },
    "partners": {
        "affiliate": {
            "title": "GPlugin Affiliate <br> Partners",
            "subtitle": "Bónais eisiach & Cur Chun Cinn <br> a mhéadú Gaolmhara",
            "substitle2": "Tá comhleá idir uirlisí margaíochta cleamhnaithe agus pleananna íocaíochta chun do bhrabúis a threisiú!",
            "desc2": "<p> Faigh na hacmhainní margaíochta atá de dhíth ort chun GPlugin a chur chun cinn go rathúil. Tá an GPlugin Affiliates Programme ar cheann de na cláir chleamhnaithe airgeadais is brabúsaí ar fud an domhain agus i gcónaí cuireann sé ar do chumas na huirlisí is fearr chun do spriocanna a bhaint amach! <\/p> \n <p> Le haghaidh tuilleadh eolais déan teagmháil lenár bhfoireann speisialaithe ag: <br> <a href=\"mailto:affiliate@gplugin.uk\"> affiliate@gplugin.uk <\/a> <\/p>"
        },
        "affilieate": {
            "description": "<ul> <li> Member GPlugin cleamhnaithe agus tosú ag tuilleamh <\/li> <li> Iontaofa & Ard-Brand a athrú <\/li> <li> Uirlisí margaíochta, Bogearraí Rianaithe & Tairseach chleamhnaithe atá éasca le húsáid <\/li> <li> do chuid ioncaim le Tionscnaimh nua ar bhonn míosúil <\/li> <li> Níor thuill Tuilleamh Comhpháirtíochta é seo riamh <\/li> <li> Ná déan trácht atreoraithe go tuilleamh le Comhpháirtí Príobháideach Google rialaithe <\/li> <li> Tairgeann an clár cleamhnaithe GPlugin an nochtadh is fearr leis na rátaí comhshó is fearr, ag an am céanna a cheadaíonn do chleamhnaithe leas a bhaint as branda Google Partner atá aitheanta go domhanda. <\/li> <\/ul> \n <ul> <li> Déantar teicneolaíochtaí saincheaptha intí a cheapadh le haghaidh GPlugin Affiliates, lena n-áirítear córas rianaithe den chéad scoth, trínar féidir lenár gcuid cleamhnaithe rianú agus monatóireacht a dhéanamh go héasca ar gach comhartha atreoraithe, amas agus trácht. <\/li> <li> Tá leabharlann d'acmhainní margaíochta éifeachtacha lena n-áirítear meirgí agus giuirléidí ar fáil, rud a ligeann do GPlugin a bheith in ann rochtain gan stró a fháil ar bhrandáil atá aitheanta go domhanda. <\/li> <li> Cláraigh le GPlugin Affiliates Programme chun taitneamh a bhaint as buntáistí comhpháirtíochta rathúla, éifeachtúla agus brabúsaí. <\/li> <\/ul>"
        },
        "whitelabel": {
            "title": "Comhpháirtíocht Lipéad Bán"
        },
        "introducing": {
            "title": "GPlugin in aithne don Chomhpháirtíocht",
            "subtitle": "Cén fáth a bheith ina Chomhpháirtí Réamhrá le haghaidh GPlugin?",
            "desc2": "Le haghaidh tuilleadh eolais déan teagmháil lenár bhfoireann speisialaithe ag:"
        }
    },
    "url": {
        "features": "gnéithe",
        "pricing": "praghsáil",
        "partnership": {
            "affiliate": "comhpháirtíocht \/ cleamhnacht",
            "whitelabel": "pátrúnacht \/ lipéad bán",
            "introductory": "comhpháirtíocht \/ réamhrá"
        },
        "contact": "teagmháil",
        "login": "Logáil isteach"
    }
};