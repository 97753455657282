export default {
    "home": {
        "sec1": {
            "title": "Augmentez vos revenus. Sans effort.",
            "cta": "Télécharger gratuitement",
            "col1": {
                "title": "boostez vos <br\/> revenus",
                "desc": "optimisez votre structure de flux et augmentez vos revenus de 5 à 30%"
            },
            "col2": {
                "title": "ouvrir de nouveaux canaux de vente <br\/>",
                "desc": "vendre plus avec de nouveaux canaux de vente"
            },
            "col3": {
                "title": "essayez gratuitement sans <br\/> limite de temps",
                "desc": "notre méthode de tarification au fur et à mesure vous offre la solution la plus flexible du marché"
            },
            "col4": {
                "title": "économisez votre <br\/> temps",
                "desc": "nos consultants construisent et optimisent vos flux de produits"
            }
        },
        "sec2": {
            "title": "importer et exporter facilement",
            "col1": {
                "title": "gestion des flux de fournisseurs <br\/>",
                "desc": "Feed Studio combine différents flux de produits en un seul flux importable"
            },
            "col2": {
                "title": "utiliser plus de 100 <br\/> autres formats",
                "desc": "Besoin de différentes descriptions Google Shopping et Instangram? Pas de problème"
            },
            "col3": {
                "title": "optimisez vos <br\/> données produit par plateforme",
                "desc": "Avez-vous besoin d'une description d'achat Google légèrement différente de la boutique Instagram? aucun problème"
            },
            "col4": {
                "title": "changer l'histoire",
                "desc": "Pourquoi annoncer un mauvais prix? En cas d'échec de la mise à jour de votre produit? Nous connaissons la réponse!"
            },
            "col5": {
                "title": "Optimisation SEO",
                "desc": "Création et optimisation automatisées de méta-balises SEO."
            },
            "col6": {
                "title": "service géré",
                "desc": "Feed Studio combine différents flux de produits en un seul flux importable"
            }
        },
        "sec3": {
            "title": "Vous voulez développer votre entreprise sans vous battre?"
        },
        "sec4": {
            "item2": {
                "subtitle": "Gestion des flux"
            },
            "item4": {
                "subtitle": "Console de recherche"
            }
        },
        "sec5": {
            "title": "Campagnes Google Ads magnifiquement automatisées",
            "col1": {
                "title": "Annonces de marque",
                "desc": "Saviez-vous que 40% de vos clients ne cliquent pas sur votre site sur la page de résultats de recherche? Atteignez-les avec des campagnes de marque!"
            },
            "col2": {
                "title": "Annonces dynamiques du Réseau de Recherche"
            },
            "col3": {
                "title": "Annonces Shopping"
            },
            "col4": {
                "title": "Afficher les annonces",
                "desc": "Touchez votre public cible sur le Réseau Display de Google grâce au remarketing réactif, au remarketing dynamique et aux annonces intelligentes."
            }
        },
        "sec6": {
            "title": "Prêt à automatiser votre publicité?",
            "cta": "Obtenez gratuitement"
        },
        "sec7": {
            "title": "",
            "subtitle": "Des services complets même dans le plan gratuit"
        },
        "premier-partner-desc": "Nous nous distinguons par Google en tant qu'agence la plus performante, vous pouvez donc être sûr que votre stratégie Google Ads est entre de bonnes mains.",
        "sec8": {
            "title": "",
            "cta": "TÉLÉCHARGER GRATUITEMENT"
        },
        "main": {
            "title": "<b> ALIMENTATION <\/b> .ME.BABY",
            "description": "Demandez un compte hâtif pour essayer le dernier logiciel de gestion de flux Google <br\/> conçu pour les professionnels et les agences. <br\/> Pour un temps limité, vous obtiendrez <s> 300 $ <\/s> 1 000 $ de crédit de bienvenue.",
            "button": "OBTENEZ 1 000 $ de crédit"
        },
        "cta1": {
            "title": "PROMOTION DES OISEAUX",
            "description": "nos premiers utilisateurs obtiennent un crédit de bienvenue de 1 000 $ pour tester Feed Studio",
            "button": "OBTENEZ 1 000 $ DE CRÉDIT",
            "subtitle": "AUCUNE CARTE DE CRÉDIT NÉCESSAIRE!"
        },
        "secFeeds": {
            "title": "que devez-vous corriger dans vos flux?",
            "header": {
                "channel": "canal",
                "export": "exportation",
                "description": "la description",
                "result": "résultat"
            },
            "google": {
                "title": "Google",
                "feeds": {
                    "merchant": {
                        "name": "Flux Merchant Center",
                        "desc": "vendre des produits sur Google Shopping",
                        "result": "augmenter les revenus grâce à une structure de champ optimisée"
                    },
                    "promotions": {
                        "name": "Flux de promotions",
                        "desc": "montrer des promotions à votre client",
                        "result": "augmenter le taux de clics et augmenter les conversions"
                    },
                    "localInventory": {
                        "name": "Flux d'annonces d'inventaire local",
                        "desc": "montrer vos produits sur Google Maps",
                        "result": "augmenter les revenus hors ligne, attirer de nouveaux clients dans votre magasin"
                    },
                    "productRatings": {
                        "name": "Flux d'avis sur les produits",
                        "desc": "rendre les évaluations et les avis sur vos produits disponibles pour les utilisateurs de Google",
                        "result": "taux de clics plus élevé"
                    },
                    "dynamicRemarketing": {
                        "name": "Flux de remarketing dynamique",
                        "desc": "diffuser des annonces auprès de personnes ayant déjà visité votre site Web",
                        "result": "augmenter les revenus"
                    },
                    "page": {
                        "name": "Flux de page",
                        "desc": "Flux de page pour les annonces dynamiques du Réseau de Recherche",
                        "result": "augmenter les performances de votre campagne de recherche"
                    },
                    "sitemap": {
                        "name": "Flux du plan du site",
                        "desc": "Flux d'URL pour l'optimisation des moteurs de recherche",
                        "result": "augmentez votre visibilité dans la recherche Google"
                    },
                    "manufacturer": {
                        "name": "Flux du fabricant",
                        "desc": "fournir à Google des informations meilleures et plus précises sur vos produits",
                        "result": "obtenez des analyses complètes et améliorez votre présence sur Google"
                    }
                }
            },
            "facebook": {
                "title": "Facebook",
                "feeds": {
                    "dynamicAds": {
                        "name": "Flux d'annonces dynamiques",
                        "desc": "diffuser des annonces auprès de personnes ayant déjà visité votre site Web",
                        "result": "augmenter les revenus"
                    },
                    "pageShop": {
                        "name": "Page Shop feed",
                        "desc": "montrer et vendre des produits aux gens sur Facebook",
                        "result": "augmenter les revenus"
                    },
                    "marketplace": {
                        "name": "Flux Marketplace",
                        "desc": "montrer et vendre des produits aux gens sur Facebook Marketplace",
                        "result": "augmenter les revenus"
                    }
                }
            },
            "instagram": {
                "title": "instagram",
                "feeds": {
                    "shopping": {
                        "name": "Flux d'achat",
                        "desc": "montrer et vendre des produits aux gens sur Instagram",
                        "result": "augmenter les revenus"
                    }
                }
            },
            "amazon": {
                "title": "amazone",
                "feeds": {
                    "product": {
                        "name": "Flux de produit",
                        "desc": "montrer et vendre des produits aux gens sur Amazon",
                        "result": "augmenter les revenus"
                    }
                }
            },
            "ebay": {
                "title": "ebay",
                "feeds": {
                    "product": {
                        "name": "Flux de produit",
                        "desc": "montrer et vendre des produits aux gens sur Ebay",
                        "result": "augmenter les revenus"
                    }
                }
            },
            "criteo": {
                "title": "criteo",
                "feeds": {
                    "product": {
                        "name": "Flux de produit",
                        "desc": "vendre des produits en ligne",
                        "result": "augmenter les revenus"
                    }
                }
            }
        },
        "cta2": {
            "title": "BOOSTEZ VOS VENTES AVEC FEED.STUDIO",
            "button": "OBTENEZ 1 000 $ DE CRÉDIT",
            "subtitle": "AUCUNE CARTE DE CRÉDIT NÉCESSAIRE!"
        },
        "meta": {
            "title": "Feed Studio »Outil de gestion de flux XML - Générateur de flux de produits XML - Flux Google Shopping",
            "desc": {
                "short": "Outil générateur de flux Google Shopping.",
                "long": "Générateur de flux Google Shopping"
            }
        }
    },
    "pricing": {
        "plugin": {
            "col1": {
                "header": "CARACTÉRISTIQUES \/ PLANS",
                "row1": "Produits illimités",
                "row2": "Google Ads - Flux d'achat",
                "row3": "Google Ads - Flux d'annonces graphiques dynamiques",
                "row4": "Google Ads - DSA Pagefeed",
                "row5": "Flux de produits Facebook",
                "row6": "Arukereso.hu Product Feed",
                "row7": "Flux de produits Criteo",
                "row8": "Flux de produits RTB House",
                "row9": "Google Ads Automation",
                "row10": "Importations et exportations personnalisées",
                "row11": "Webinaires",
                "row12": "Gestionnaire de compte dédié",
                "row13": "Bon de bienvenue",
                "row14": "Redevance mensuelle",
                "row15": "Prix pour 10 000 opérations"
            },
            "agency": "Tout votre argent si nous ne livrons pas les résultats escomptés dans 3 mois.",
            "col2": {
                "header": "À VIE GRATUITE",
                "row6": "Hebdomadaire",
                "cta": "enregistrement",
                "row1": "Tableau de bord intégré",
                "row14": "9 $",
                "row15": "n \/ a"
            },
            "col3": {
                "header": "PRIME",
                "row6": "du quotidien",
                "cta": "enregistrement",
                "row1": "Tableau de bord Premium",
                "row13": "300 $",
                "row14": "9 $",
                "row15": "1 $"
            },
            "col4": {
                "row6": "Temps réel",
                "cta": "enregistrement",
                "row1": "Tableau de bord VIP",
                "row10": "Webinaires privés",
                "header": "Early Bird Premium",
                "row13": "1 000 $",
                "row14": "9 $",
                "row15": "1 $"
            }
        },
        "agency": {
            "allin": {
                "li6": "Chatbot",
                "li9": "Garantie de remboursement*"
            }
        },
        "title": "Des plans qui correspondent à votre \n budget marketing",
        "sec2": {
            "title": "Dominez votre croissance en ligne",
            "cta": "OBTENEZ GRATUITEMENT"
        },
        "sec6": {
            "title": "Si vous avez besoin d'une assistance technique, visitez notre tableau de bord d'assistance.",
            "cta": "SOUTIEN"
        },
        "header": {
            "title": "PRIX",
            "sub": "payer au fur et à mesure"
        }
    },
    "testimonials": {
        "title": "Ce que nos utilisateurs disent",
        "1": {
            "desc": "Nous travaillons ensemble sur les campagnes Google Ad depuis près d'un an maintenant. Nous avons toujours fait l'expérience d'un travail rapide et professionnel de l'agence, ils sont très compétents, coopératifs et cherchent à répondre pleinement à nos besoins. En plus d'apporter des solutions innovantes, ils sont assez calmes pour expliquer en profondeur les solutions aux problèmes complexes et les résultats des campagnes.",
            "name": "Adam Dallos",
            "pos": "PwC, Assistant Manager, Marketing, Communication Business Development"
        }
    },
    "footer": {
        "description": ""
    },
    "menu": {
        "navigation": "LA NAVIGATION",
        "signin": "Se connecter",
        "features": "Caractéristiques",
        "pricing": "Tarification",
        "blog": "Blog",
        "terms-conditions": "termes et conditions",
        "partners": "PARTENARIAT",
        "affiliate": "Affilier",
        "whitelabel": "marque blanche",
        "introducing": "Partenaire de présentation",
        "findus": "Trouvez-nous sur",
        "home": "Accueil",
        "dashboard": "Tableau de bord",
        "contact": "Contact"
    },
    "features": {
        "title": "La publicité sur Google <br\/> plus facile que jamais",
        "subtitle": "",
        "sec1": {
            "title": "Pourquoi annoncer sur Google?",
            "col1": {
                "subtitle": "Battez vos concurrents",
                "desc": "Chaque jour, les gens recherchent votre marque ou vos produits, Google vous donne la possibilité de placer votre entreprise devant vos concurrents."
            },
            "col2": {
                "subtitle": "Atteignez des clients idéaux",
                "desc": "Google est l'une des meilleures plateformes publicitaires pour cibler les bons utilisateurs au bon moment à tous les niveaux d'engagement."
            },
            "col3": {
                "subtitle": "Augmentez vos ventes",
                "desc": "Le trafic vers votre site Web augmentera considérablement via Google avec des visiteurs qui peuvent facilement devenir des clients, ce qui augmente vos ventes et vos affaires en ligne."
            }
        },
        "sec2": {
            "title": "Dominez votre croissance en ligne",
            "cta": "ESSAYER GRATUITEMENT"
        }
    },
    "getstarted": {
        "title": "Commencez avec 3 étapes faciles",
        "col1": {
            "subtitle": "1. Téléchargez",
            "desc": ""
        },
        "col2": {
            "subtitle": "2. Activez",
            "desc": ""
        },
        "col3": {
            "subtitle": "3. Suivre",
            "desc": ""
        }
    },
    "dashboard": {
        "title": "Tableau de bord",
        "domainsettings": {
            "title": "PARAMÈTRES DE DOMAINE",
            "subtitle1": "Ajouter un nouveau domaine",
            "input1": "Votre nouveau domaine",
            "cta1": "Ajouter",
            "subtitle2": "Vos domaines actuels",
            "currentdomains1": "Domaine",
            "currentdomains2": "clé API"
        },
        "subscription": {
            "title": "ABONNEMENT",
            "subtitle1": "Équilibre",
            "remaining": "restant"
        },
        "tab2": {
            "desc1": "Vous utilisez actuellement notre",
            "desc2": "abonnement",
            "cta": "ABONNEMENT SWITCH"
        },
        "billing": {
            "title": "Détails de la facturation",
            "input": {
                "name": "Nom de facturation",
                "vat": "numéro de TVA",
                "address": "Adresse",
                "city": "Ville",
                "zip": "code postal",
                "country": "Pays"
            }
        },
        "user": {
            "title": "PARAMÈTRES UTILISATEUR",
            "subtitle": "Vos informations de connexion",
            "lastlogin": "Dernière connexion:"
        }
    },
    "cta": {
        "update": "MISE À JOUR"
    },
    "input": {
        "email": "Adresse électronique",
        "password": "Mot de passe",
        "passwordrepeat": "Répéter le mot de passe"
    },
    "partners": {
        "affiliate": {
            "subtitle": "Bonus et promotions exclusifs <br> pour stimuler les affiliés",
            "substitle2": "Une fusion d'outils de marketing d'affiliation et de plans de paiement pour augmenter vos bénéfices!"
        },
        "affilieate": {
            "description": ""
        },
        "whitelabel": {
            "title": "Partenariat en marque blanche"
        },
        "introducing": {
            "subtitle": "",
            "desc2": "Pour plus d'informations, veuillez contacter notre équipe spécialisée à:"
        }
    },
    "url": {
        "features": "traits",
        "pricing": "prix",
        "partnership": {
            "affiliate": "partenariat \/ affilié",
            "whitelabel": "partenariat \/ marque blanche",
            "introductory": "partenariat \/ introduction"
        },
        "contact": "contact",
        "login": "s'identifier"
    },
    "b2c": {
        "main": {
            "title": "<b> ALIMENTATION <\/b> .GESTION"
        },
        "cta1": {
            "title": "PROMOTION DES OISEAUX",
            "description": "Pour un temps limité, chaque utilisateur lève-tôt obtient un crédit de bienvenue de 1 000 $ pour tester le package Feed Studio Premium. <br\/> <br\/> Aucune période d'essai, aucune carte de crédit nécessaire.",
            "button": "OBTENEZ 1 000 $ DE CRÉDIT"
        },
        "sec1": {
            "title": "comment pouvez-vous utiliser feed.studio",
            "col1": {
                "title": "inscrivez-vous et donnez-nous <br\/> votre flux brut",
                "desc": "donnez-nous vos coordonnées et vos données de flux pour configurer votre compte"
            },
            "col2": {
                "title": "nos managers configurent <br\/> votre flux d'exportation",
                "desc": "à partir de votre flux brut, nous créons votre flux d'exportation"
            },
            "col3": {
                "title": "Profitez (des résultats)",
                "desc": "Asseyez-vous en sachant que nos experts travaillent dur pour vous fournir le meilleur aliment dont vous avez besoin."
            }
        },
        "sec2": {
            "title": "Inscrivez-vous pour le lève-tôt",
            "infoList": {
                "item1": "Aucune limite de temps pour le crédit de bienvenue",
                "item2": "nous ne vous facturons \/ facturons pas à la fin de la période de bienvenue",
                "item3": "Aucune limite de produit ou d'exportation",
                "item4": "Service géré - vous n'avez pas besoin d'apprendre la plateforme",
                "item5": "Vous pouvez supprimer votre compte à tout moment",
                "item6": "Vous pouvez demander des formats d'exportation personnalisés"
            },
            "form": {
                "name": "Nom",
                "email": "Email",
                "phone": "Téléphone",
                "feedUrl": "URL du flux",
                "policyLabel": "J'accepte les termes et conditions",
                "marketingLabel": "Je souhaite m'inscrire à la newsletter Feed.Studio",
                "button": "obtenir un accès anticipé",
                "feedType": ""
            }
        },
        "meta": {
            "title": "Générateur de flux de produits XML, Google Shopping et flux Facebook",
            "desc": {
                "short": "Créez et optimisez facilement vos flux Google et Facebook.",
                "long": "Google Shopping Feed, Product Feed Optimzation."
            }
        }
    },
    "b2b": {
        "main": {
            "title": "<b> ALIMENTATION <\/b> .PARTENAIRES",
            "description": "Feed.Studio conçu pour les professionnels et les agences de commerce électronique. Nous recherchons des partenaires régionaux pour bâtir un avenir prospère. <\/br> Augmentez vos revenus, vendez plus avec Feed.Studio!",
            "button": "VENDRE PLUS"
        },
        "sec1": {
            "title": "Pourquoi travailler avec nous?",
            "col1": {
                "title": "vendre plus",
                "desc": "vendre plus"
            },
            "col2": {
                "title": "battez vos concurrents <\/br>",
                "desc": "Soyez le premier"
            },
            "col3": {
                "title": "satisfaire vos <\/br> clients",
                "desc": "Contrôle de qualité"
            },
            "col4": {
                "title": "gagner du temps",
                "desc": "avec notre service mamaged"
            }
        },
        "secPartner": {
            "title": "partenariats",
            "header": {
                "affiliate": "Affilier",
                "gold": "Or",
                "exclusive": "Exclusif",
                "custom": "Douane"
            },
            "requirements": {
                "title": "Exigences",
                "newClient": "nouveaux clients \/ an",
                "certifiedusers": "utilisateur interne actif certifié"
            },
            "recognition": {
                "title": "Reconnaissance",
                "visible": "visible sur feed.studio",
                "trademark": "droits d'utilisation de la marque «feed studio»"
            },
            "training": {
                "title": "Avantages de la formation",
                "biweekly": "réunions bihebdomadaires",
                "salesSupport": "soutien aux ventes et conseils"
            },
            "marketing": {
                "title": "avantages marketing",
                "brand": "marque sur les campagnes locales",
                "customerStories": "histoires de réussite client"
            },
            "sales": {
                "title": "avantages commerciaux",
                "materials": "matériel de vente",
                "accountManagers": "gestionnaire de compte dédié",
                "leads": "prospects locaux",
                "affiliateBonus": "BONUS SUR LES CLIENTS AFFILIÉS",
                "feedStudioBonus": "bonus sur Feed Studio"
            },
            "contactUs": "Nous contacter",
            "signUp": "S'inscrire"
        },
        "sec3": {
            "title": "comment être notre partenaire?",
            "col1": {
                "title": "s'inscrire",
                "desc": ""
            },
            "col2": {
                "title": "participer à nos formations commerciales et plateformes",
                "desc": ""
            },
            "col3": {
                "title": "gérer nos prospects et clients",
                "desc": ""
            }
        },
        "sec4": {
            "title": "Inscrivez-vous au PROGRAMME PARTENAIRE",
            "infoList": {
                "item1": "accès à Feed Studio discuter des stratégies, des problèmes de vente et de services",
                "item2": "visibilité et reconnaissance en étant répertorié comme partenaire officiel sur la page des partenaires de Feed Studio",
                "item3": "accès au tableau de bord des ventes"
            },
            "form": {
                "plan": {
                    "affiliate": "Affilier",
                    "gold": "Or",
                    "custom": "Douane"
                },
                "name": "Nom",
                "email": "Email",
                "phone": "Téléphone",
                "message": "Message",
                "policyLabel": "J'accepte les termes et conditions",
                "marketingLabel": "Je souhaite m'inscrire à la newsletter Feed.Studio",
                "button": "Obtenez un accès anticipé"
            }
        }
    },
    "registration": {
        "meta": {
            "title": "Inscrivez-vous gratuitement à Feed Studio",
            "desc": {
                "short": "Inscrivez-vous et obtenez 1 000 $ de crédit pour Feed Studio.",
                "long": "Inscrivez-vous et obtenez un crédit de bienvenue de 1 000 $ pour utiliser Feed Studio."
            }
        }
    },
    "earlybirdreg": {
        "form": {
            "infoList": {
                "desc1": "",
                "desc2": "",
                "item1": "",
                "item2": "",
                "item3": "",
                "item4": "",
                "item5": "",
                "item6": "",
                "item7": ""
            }
        }
    },
    "login": {
        "title": "",
        "subtitle": "",
        "subtitle2": "",
        "googleButton": ""
    }
};