export default {
    "home": {
        "sec1": {
            "title": "Boost your revenue. Effortlessly.",
            "cta": "Download free",
            "col1": {
                "title": "Boost your<br\/>revenue",
                "desc": "Optimize your feed structure and increase your revenue by 5-30%."
            },
            "col2": {
                "title": "Open new sales<br\/>channels",
                "desc": "Sell more with new sales channels."
            },
            "col3": {
                "title": "Try free without<br\/>time limit",
                "desc": "Our pay as you go pricing method gives you the most flexible solution on the market."
            },
            "col4": {
                "title": "Save your<br\/>time",
                "desc": "Our consultants build and optimize your product feeds."
            }
        },
        "sec2": {
            "title": "Import & Export with ease",
            "col1": {
                "title": "Supplier feed management",
                "desc": "Feed Studio combines different product feeds into one importable feed"
            },
            "col2": {
                "title": "Use more than 350 other formats",
                "desc": "Advertise your products in any platform with ease."
            },
            "col3": {
                "title": "Optimize product data by platform",
                "desc": "Do you need a little bit different Google shopping description than Instagram shop? No problem."
            },
            "col4": {
                "title": "Change history",
                "desc": "Did you advertise incorrect prices or failed a product update? Track back your logs."
            },
            "col5": {
                "title": "SEO optimization",
                "desc": "Automated SEO meta tag creation and optimization."
            },
            "col6": {
                "title": "Managed service",
                "desc": "Feed Studio combines different  product feeds into one importable feed "
            }
        },
        "sec3": {
            "title": "Want to grow your business without the struggle?"
        },
        "sec4": {
            "item2": {
                "subtitle": "Feed Management"
            },
            "item4": {
                "subtitle": "Search console"
            }
        },
        "sec5": {
            "title": "Beautifully automated Google Ads campaigns",
            "col1": {
                "title": "Brand Ads",
                "desc": "Did you know that 40% of your customers don't click on your site on search result page? Reach them with brand campaigns!"
            },
            "col2": {
                "title": "Dynamic Search Ads"
            },
            "col3": {
                "title": "Shopping Ads"
            },
            "col4": {
                "title": "Display Ads",
                "desc": "Reach your target audience on Google Display Network with responsive remarketing, dynamic remarketing and smart ads."
            }
        },
        "sec6": {
            "title": "Ready to automate your advertisement?",
            "cta": "Get Free"
        },
        "sec7": {
            "title": "",
            "subtitle": "Comprehensive services even in the free plan"
        },
        "premier-partner-desc": "We are distinguished by Google as a top performing agency so you can be sure your Google Ads strategy is in good hands.",
        "sec8": {
            "title": "",
            "cta": "DOWNLOAD FREE"
        },
        "main": {
            "title": "<b>FEED<\/b>.ME.BABY",
            "description": "Request an early bird account to try out the latest Google feed<br\/>manager software designed for professionals and agencies.<br\/>For a limited time you will get <s>$300<\/s> $1,000 welcome credit.",
            "button": "GET $1,000 credit"
        },
        "cta1": {
            "title": "EARLY BIRD PROMOTION",
            "description": "our first users get $1000 welcome credit to test Feed Studio",
            "button": "GET $1,000 CREDIT",
            "subtitle": "NO CREDIT CARD NEEDED!"
        },
        "secFeeds": {
            "title": "what should you fix in your feeds?",
            "header": {
                "channel": "channel",
                "export": "export",
                "description": "description",
                "result": "result"
            },
            "google": {
                "title": "google",
                "feeds": {
                    "merchant": {
                        "name": "Merchant Center feed",
                        "desc": "sell products on Google Shopping",
                        "result": "boost revenue by optimized field structure"
                    },
                    "promotions": {
                        "name": "Promotions feed",
                        "desc": "show promotions to your customer",
                        "result": "boost clickthrough rate & Increase conversions"
                    },
                    "localInventory": {
                        "name": "Local Inventory Ads feed",
                        "desc": "show your products on Google Maps",
                        "result": "boost offline revenue, get new customers into your store"
                    },
                    "productRatings": {
                        "name": "Product Ratings feed",
                        "desc": "make your product ratings and reviews available for users of Google",
                        "result": "higher clickthrough rate"
                    },
                    "dynamicRemarketing": {
                        "name": "Dynamic Remarketing feeds",
                        "desc": "show ads to people who have previously visited your website",
                        "result": "boost revenue"
                    },
                    "page": {
                        "name": "Page feed",
                        "desc": "Page feed for Dynamic Search Ads",
                        "result": "increase your search campaign’s performance"
                    },
                    "sitemap": {
                        "name": "Sitemap feed",
                        "desc": "URL feed for search engine optimization",
                        "result": "increase your visibility in Google Search "
                    },
                    "manufacturer": {
                        "name": "Manufacturer feed",
                        "desc": "provide better and more accurate information about your products to Google",
                        "result": "get comprehensive analytics & improve your presence on Google"
                    }
                }
            },
            "facebook": {
                "title": "facebook",
                "feeds": {
                    "dynamicAds": {
                        "name": "Dynamic Ads feed",
                        "desc": "show ads to people who have previously visited your website",
                        "result": "boost revenue"
                    },
                    "pageShop": {
                        "name": "Page Shop feed",
                        "desc": "show and sell products to people on Facebook",
                        "result": "boost revenue"
                    },
                    "marketplace": {
                        "name": "Marketplace feed",
                        "desc": "show and sell products to people on Facebook Marketplace",
                        "result": "boost revenue"
                    }
                }
            },
            "instagram": {
                "title": "Instagram",
                "feeds": {
                    "shopping": {
                        "name": "Shopping feed",
                        "desc": "show and sell products to people on Instagram",
                        "result": "boost revenue"
                    }
                }
            },
            "amazon": {
                "title": "Amazon",
                "feeds": {
                    "product": {
                        "name": "Product feed",
                        "desc": "show and sell products to people on Amazon",
                        "result": "boost revenue"
                    }
                }
            },
            "ebay": {
                "title": "eBay",
                "feeds": {
                    "product": {
                        "name": "Product feed",
                        "desc": "show and sell products to people on Ebay",
                        "result": "boost revenue"
                    }
                }
            },
            "criteo": {
                "title": "criteo",
                "feeds": {
                    "product": {
                        "name": "Product feed",
                        "desc": "sell products online",
                        "result": "boost revenue"
                    }
                }
            }
        },
        "cta2": {
            "title": "BOOST YOUR SALES WITH FEED.STUDIO",
            "button": "GET $1,000 CREDIT",
            "subtitle": "NO CREDIT CARD NEEDED!"
        },
        "meta": {
            "title": "Feed Studio » XML Feed Manager Tool - XML Product Feed Generator - Google Shopping Feed",
            "desc": {
                "short": "Google Shopping Feed generator tool.",
                "long": "Google Shopping Feed generator"
            }
        }
    },
    "pricing": {
        "plugin": {
            "col1": {
                "header": "FEATURES \/ PLANS",
                "row1": "Unlimited products",
                "row2": "Google Ads - Shopping Feed",
                "row3": "Google Ads - Dynamic Display Ad Feed",
                "row4": "Google Ads - DSA Pagefeed",
                "row5": "Facebook Display Product Ads Feed",
                "row6": "Arukereso.hu Product Feed",
                "row7": "Criteo Product Feed",
                "row8": "RTB House Product Feed",
                "row9": "Google Ads Automation",
                "row10": "Custom imports and exports",
                "row11": "Webinars",
                "row12": "Dedicated Account Manager",
                "row13": "Welcome Voucher",
                "row14": "Monthly Fee",
                "row15": "Price per 10,000 operation"
            },
            "agency": "All your money back if we don't deliver the expected results in 3 months.",
            "col2": {
                "header": "LIFETIME FREE",
                "row6": "Weekly",
                "cta": "Registration",
                "row1": "Built-in dashboard",
                "row14": "$ 9",
                "row15": "na"
            },
            "col3": {
                "header": "PREMIUM",
                "row6": "Daily",
                "cta": "Registration",
                "row1": "Premium dashboard",
                "row13": "$ 300",
                "row14": "$ 9",
                "row15": "$ 1"
            },
            "col4": {
                "row6": "Real-time",
                "cta": "Registration",
                "row1": "VIP dashboard",
                "row10": "Private webinars",
                "header": "Early Bird Premium",
                "row13": "$1000",
                "row14": "$ 9",
                "row15": "$ 1"
            }
        },
        "agency": {
            "allin": {
                "li6": "Chatbot",
                "li9": "Moneyback guarantee*"
            }
        },
        "title": "Plans that fit your entire\nmarketing budget",
        "sec2": {
            "title": "Dominate your Online Growth",
            "cta": "GET FREE"
        },
        "sec6": {
            "title": "If you need technical support, visit our support dashboard.",
            "cta": "SUPPORT"
        },
        "header": {
            "title": "PRICING",
            "sub": "pay as you go"
        }
    },
    "testimonials": {
        "title": "What our users says",
        "1": {
            "desc": "We've been working together on Google Ad campaigns for almost a year now. We've always experienced quick and professional work from the agency, they are very competent, cooperative and looking to fully fulfill our needs. Besides bringing innovative solutions they are calm enough to deeply explain the solutions for complex problems and the results of campaings.",
            "name": "Adam Dallos",
            "pos": "PwC, Assistant Manager, Marketing, Communication Business Development"
        }
    },
    "footer": {
        "description": ""
    },
    "menu": {
        "navigation": "NAVIGATION",
        "signin": "Sign In",
        "features": "Features",
        "pricing": "Pricing",
        "blog": "Blog",
        "terms-conditions": "Terms & Conditions",
        "partners": "PARTNERSHIP",
        "affiliate": "Affiliate",
        "whitelabel": "White label",
        "introducing": "Introducing Partner",
        "findus": "Find Us On",
        "home": "Home",
        "dashboard": "Dashboard",
        "contact": "Contact"
    },
    "features": {
        "title": "Advertisement on Google<br\/>made easier than ever",
        "subtitle": "",
        "sec1": {
            "title": "Why advertise on Google?",
            "col1": {
                "subtitle": "Beat your Competition",
                "desc": "Every day people are searching for your brand or products, Google gives you the ability to get your business in front of your competition."
            },
            "col2": {
                "subtitle": "Reach ideal Customers",
                "desc": "Google is one of the best advertising platforms for targeting the right users at the right time across all engagement levels."
            },
            "col3": {
                "subtitle": "Grow your Sales",
                "desc": "Traffic to your website will increase greatly through Google with visitors that can easily turn into customers, growing your sales & online business."
            }
        },
        "sec2": {
            "title": "Dominate your Online Growth",
            "cta": "TRY FREE"
        }
    },
    "getstarted": {
        "title": "Get started with 3 easy steps",
        "col1": {
            "subtitle": "1. Download",
            "desc": ""
        },
        "col2": {
            "subtitle": "2. Activate",
            "desc": ""
        },
        "col3": {
            "subtitle": "3. Track",
            "desc": "\n"
        }
    },
    "dashboard": {
        "title": "Dashboard",
        "domainsettings": {
            "title": "DOMAIN SETTINGS",
            "subtitle1": "Add new domain",
            "input1": "Your new domain",
            "cta1": " Add",
            "subtitle2": "Your current domains",
            "currentdomains1": "Domain",
            "currentdomains2": "API Key"
        },
        "subscription": {
            "title": "SUBSCRIPTION",
            "subtitle1": "Balance",
            "remaining": "remaining"
        },
        "tab2": {
            "desc1": "You are currently using our",
            "desc2": "subscription",
            "cta": "SWITCH SUBSCRIPTION"
        },
        "billing": {
            "title": "Billing details",
            "input": {
                "name": "Billing name",
                "vat": "VAT number",
                "address": "Address",
                "city": "City",
                "zip": "ZIP code",
                "country": "Country"
            }
        },
        "user": {
            "title": "USER SETTINGS",
            "subtitle": "Your login details",
            "lastlogin": "Last login:"
        }
    },
    "cta": {
        "update": "UPDATE"
    },
    "input": {
        "email": "Email Address",
        "password": "Password",
        "passwordrepeat": "Repeat password"
    },
    "partners": {
        "affiliate": {
            "subtitle": "Exclusive Bonuses & Promotions<br>to boost Affiliates",
            "substitle2": "A fusion of affiliate marketing tools & payment plans to boost your profits!"
        },
        "affilieate": {
            "description": ""
        },
        "whitelabel": {
            "title": "White Label Partnership"
        },
        "introducing": {
            "subtitle": "\n",
            "desc2": "For more information please contact our specialised team at:"
        }
    },
    "url": {
        "features": "features",
        "pricing": "pricing",
        "partnership": {
            "affiliate": "patnership\/affiliate",
            "whitelabel": "patnership\/whitelabel",
            "introductory": "patnership\/introductory"
        },
        "contact": "contact",
        "login": "login"
    },
    "b2c": {
        "main": {
            "title": "<b>FEED<\/b>.MANAGEMENT"
        },
        "cta1": {
            "title": "EARLY BIRD PROMOTION",
            "description": "For a limited time every early bird user get $1000 welcome credit\u2028 to test Feed Studio Premium package.<br\/><br\/>No trial period, no credit card needed.",
            "button": "GET $1,000 CREDIT"
        },
        "sec1": {
            "title": "how can you use feed.studio",
            "col1": {
                "title": "sign up & give us<br\/>your raw feed",
                "desc": "give us your contact and feed data to set up your account"
            },
            "col2": {
                "title": "our managers set up<br\/>your export feed",
                "desc": "from your raw feed we create your export feed"
            },
            "col3": {
                "title": "Enjoy (the results)",
                "desc": "Sit back knowing our  experts are working hard to deliver you \u2028the best feed you need."
            }
        },
        "sec2": {
            "title": "Sign up for early bird tester",
            "infoList": {
                "item1": "No time limit for welcome credit",
                "item2": "we do not charge\/bill you in the end of welcome period",
                "item3": "No product or export limit",
                "item4": "Managed service - you do not have to learn the platform",
                "item5": "You can delete your account anytime",
                "item6": "You can ask custom export formats"
            },
            "form": {
                "name": "Name",
                "email": "Email",
                "phone": "Phone",
                "feedUrl": "Feed URL",
                "policyLabel": "I accept the Terms and Conditions",
                "marketingLabel": "I would like to subscribe to the Feed.Studio newsletter",
                "button": "get early access",
                "feedType": "Export feed type - format"
            }
        },
        "meta": {
            "title": "XML Product Feed Generator, Google Shopping & Facebook Feed",
            "desc": {
                "short": "Create and optimize your Google and Facebook feeds easily.",
                "long": "Google Shopping Feed, Product Feed Optimzation."
            }
        }
    },
    "b2b": {
        "main": {
            "title": "<b>FEED<\/b>.PARTNERS",
            "description": "Feed.Studio designed for ecommerce porfessionals and agencies. \u2028We are looking for regional partners to build a successful future.<\/br>Boost your revenue, sell more with Feed.Studio!",
            "button": "SELL MORE"
        },
        "sec1": {
            "title": "Why work with us?",
            "col1": {
                "title": "sell more",
                "desc": "sell more"
            },
            "col2": {
                "title": "beat your<\/br>competitors",
                "desc": "be the first"
            },
            "col3": {
                "title": "satisfy your<\/br>clients",
                "desc": "quality control"
            },
            "col4": {
                "title": "save time",
                "desc": "with our mamaged service"
            }
        },
        "secPartner": {
            "title": "partnerships",
            "header": {
                "affiliate": "Affiliate",
                "gold": "Gold",
                "exclusive": "Exclusive",
                "custom": "Custom"
            },
            "requirements": {
                "title": "Requirements",
                "newClient": "new clients \/ year",
                "certifiedusers": "certified active internal user"
            },
            "recognition": {
                "title": "Recognition",
                "visible": "visible on feed.studio",
                "trademark": "rights to use “feed studio” trademark"
            },
            "training": {
                "title": "Training benefits",
                "biweekly": "bi-weekly meetings",
                "salesSupport": "sales support and guidance"
            },
            "marketing": {
                "title": "marketing benefits",
                "brand": "brand on local campaigns",
                "customerStories": "customer success stories"
            },
            "sales": {
                "title": "sales benefits",
                "materials": "sales materials",
                "accountManagers": "dedicated account manager",
                "leads": "local leads",
                "affiliateBonus": "BONUS ON AFFILIATE CLIENTS",
                "feedStudioBonus": "bonus on Feed Studio"
            },
            "contactUs": "Contact Us",
            "signUp": "Sign Up"
        },
        "sec3": {
            "title": "how to be our partner?",
            "col1": {
                "title": "sign up",
                "desc": ""
            },
            "col2": {
                "title": "take part in our sales and platform trainings",
                "desc": ""
            },
            "col3": {
                "title": "manage our leads and clients",
                "desc": ""
            }
        },
        "sec4": {
            "title": "Sign up for PARTNER PROGRAM ",
            "infoList": {
                "item1": "access to Feed Studio discuss strategies, sales and services issues",
                "item2": "visibility & recognition by being a listed as an official partner on the Feed Studio partners page",
                "item3": "access to Sales dashboard"
            },
            "form": {
                "plan": {
                    "affiliate": "Affiliate",
                    "gold": "Gold",
                    "custom": "Custom"
                },
                "name": "Name",
                "email": "Email",
                "phone": "Phone",
                "message": "Message",
                "policyLabel": "I accept the Terms and Conditions",
                "marketingLabel": "I would like to subscribe to the Feed.Studio newsletter",
                "button": "Get Early Access"
            }
        }
    },
    "registration": {
        "meta": {
            "title": "Sign Up for Free to Feed Studio",
            "desc": {
                "short": "Sign up and get $1000 credit for Feed Studio.",
                "long": "Sign up and get $1000 welcome credit to use Feed Studio."
            }
        }
    },
    "earlybirdreg": {
        "form": {
            "infoList": {
                "desc1": "Get everything you need to build and run dynamic product ads.",
                "desc2": "$1000 credit for free » Sign up and get $1000 to use Feed Studio:",
                "item1": "No autocharge after free trial",
                "item2": "No time limit for welcome credit",
                "item3": "We do not charge\/bill you in the end of welcome period",
                "item4": "No product or export limit",
                "item5": "Managed service",
                "item6": "You can delete your account anytime",
                "item7": "You can ask custom export formats"
            }
        }
    },
    "login": {
        "title": "Login",
        "subtitle": "Use your Google account to sign into Feed Studio",
        "subtitle2": "Click on the Sign in button!",
        "googleButton": "Login with Google"
    }
};