export default {
    "home": {
        "sec1": {
            "title": "增加收入。毫不费力。",
            "cta": "免费下载",
            "col1": {
                "title": "增加您的<br\/>收入",
                "desc": "优化您的Feed结构并增加收入5-30％"
            },
            "col2": {
                "title": "打开新的销售<br\/>渠道",
                "desc": "通过新的销售渠道销售更多"
            },
            "col3": {
                "title": "免费试用，没有<br\/>时限",
                "desc": "我们随用随付的定价方法为您提供了市场上最灵活的解决方案"
            },
            "col4": {
                "title": "节省您的<br\/>时间",
                "desc": "我们的顾问建立并优化您的产品Feed"
            }
        },
        "sec2": {
            "title": "轻松进出口",
            "col1": {
                "title": "供应商提要<br\/>管理",
                "desc": "Feed Studio将不同的产品Feed合并为一个可导入的Feed"
            },
            "col2": {
                "title": "使用超过100种<br\/>其他格式",
                "desc": "需要其他Google购物和Instangram描述符吗？没问题"
            },
            "col3": {
                "title": "通过平台优化产品<br\/>数据",
                "desc": "您需要与Instagram商店有所不同的Google购物描述吗？没问题"
            },
            "col4": {
                "title": "变更记录",
                "desc": "为什么要宣传错误的价格？产品更新何时失败？我们知道答案！"
            },
            "col5": {
                "title": "SEO优化",
                "desc": "自动化的SEO元标记创建和优化。"
            },
            "col6": {
                "title": "托管服务",
                "desc": "Feed Studio将不同的产品Feed合并为一个可导入的Feed"
            }
        },
        "sec3": {
            "title": "想要在没有困难的情况下发展您的业务吗？"
        },
        "sec4": {
            "item2": {
                "subtitle": "饲料管理"
            },
            "item4": {
                "subtitle": "搜索控制台"
            }
        },
        "sec5": {
            "title": "精美精美的Google Ads广告系列",
            "col1": {
                "title": "品牌广告",
                "desc": "您是否知道40％的客户没有在搜索结果页面上点击您的网站？通过品牌宣传活动吸引他们！"
            },
            "col2": {
                "title": "动态搜索广告"
            },
            "col3": {
                "title": "购物广告"
            },
            "col4": {
                "title": "展示广告",
                "desc": "借助自适应再营销，动态再营销和智能广告，在Google展示广告网络上吸引目标受众。"
            }
        },
        "sec6": {
            "title": "准备自动化您的广告了吗？",
            "cta": "免费"
        },
        "sec7": {
            "title": "",
            "subtitle": "全面的服务，即使在免费计划中"
        },
        "premier-partner-desc": "我们是Google表现最好的代理机构，因此您可以确定自己的Google Ads策略是正确的。",
        "sec8": {
            "title": "",
            "cta": "免费下载"
        },
        "main": {
            "title": "<b>纸<\/b> .ME.BABY",
            "description": "申请早鸟帐户可试用专为专业人士和代理商设计的最新版Google Feed <br\/>管理器软件。 <br\/>在限定时间内，您将获得<s> $ 300 <\/s> $ 1,000的欢迎积分。",
            "button": "获得$ 1,000赠金"
        },
        "cta1": {
            "title": "早起的鸟儿",
            "description": "我们的第一批用户将获得1000美元的欢迎信用额来测试Feed Studio",
            "button": "获得$ 1,000的信用额",
            "subtitle": "无需信用卡！"
        },
        "secFeeds": {
            "title": "您应该在Feed中解决什么问题？",
            "header": {
                "channel": "渠道",
                "export": "出口",
                "description": "描述",
                "result": "结果"
            },
            "google": {
                "title": "谷歌",
                "feeds": {
                    "merchant": {
                        "name": "商家中心供稿",
                        "desc": "在Google购物上销售产品",
                        "result": "通过优化领域结构来增加收入"
                    },
                    "promotions": {
                        "name": "促销提要",
                        "desc": "向客户展示促销",
                        "result": "提高点击率并增加转化次数"
                    },
                    "localInventory": {
                        "name": "本地库存广告Feed",
                        "desc": "在Google地图上显示您的产品",
                        "result": "增加线下收入，吸引新客户进入您的商店"
                    },
                    "productRatings": {
                        "name": "产品评分供稿",
                        "desc": "向Google用户提供您的产品评分和评论",
                        "result": "更高的点击率"
                    },
                    "dynamicRemarketing": {
                        "name": "动态再营销Feed",
                        "desc": "向以前访问过您网站的用户展示广告",
                        "result": "增加收入"
                    },
                    "page": {
                        "name": "页面提要",
                        "desc": "动态搜索广告的页面供稿",
                        "result": "提高搜索广告系列的效果"
                    },
                    "sitemap": {
                        "name": "站点地图供稿",
                        "desc": "用于优化搜索引擎的URL提要",
                        "result": "提高您在Google搜索中的知名度"
                    },
                    "manufacturer": {
                        "name": "厂商资讯提供",
                        "desc": "向Google提供有关您产品的更好，更准确的信息",
                        "result": "获得全面的分析并改善您在Google上的形象"
                    }
                }
            },
            "facebook": {
                "title": "脸书",
                "feeds": {
                    "dynamicAds": {
                        "name": "动态广告Feed",
                        "desc": "向以前访问过您网站的用户展示广告",
                        "result": "增加收入"
                    },
                    "pageShop": {
                        "name": "网页商店供稿",
                        "desc": "在Facebook上向人们展示和销售产品",
                        "result": "增加收入"
                    },
                    "marketplace": {
                        "name": "市场饲料",
                        "desc": "在Facebook Marketplace上向人们展示和销售产品",
                        "result": "增加收入"
                    }
                }
            },
            "instagram": {
                "title": "instagram",
                "feeds": {
                    "shopping": {
                        "name": "购物提要",
                        "desc": "在Instagram上向人们展示和销售产品",
                        "result": "增加收入"
                    }
                }
            },
            "amazon": {
                "title": "亚马逊",
                "feeds": {
                    "product": {
                        "name": "产品资讯提供",
                        "desc": "向亚马逊上的人展示和销售产品",
                        "result": "增加收入"
                    }
                }
            },
            "ebay": {
                "title": "易趣",
                "feeds": {
                    "product": {
                        "name": "产品资讯提供",
                        "desc": "在Ebay上向人们展示和销售产品",
                        "result": "增加收入"
                    }
                }
            },
            "criteo": {
                "title": "Criteo",
                "feeds": {
                    "product": {
                        "name": "产品资讯提供",
                        "desc": "在线销售产品",
                        "result": "增加收入"
                    }
                }
            }
        },
        "cta2": {
            "title": "用FEED.STUDIO促进销售",
            "button": "获得$ 1,000的信用额",
            "subtitle": "无需信用卡！"
        },
        "meta": {
            "title": "Feed Studio»XML Feed Manager工具-XML产品Feed生成器-Google Shopping Feed",
            "desc": {
                "short": "Google Shopping Feed生成器工具。",
                "long": "Google Shopping Feed生成器"
            }
        }
    },
    "pricing": {
        "plugin": {
            "col1": {
                "header": "功能\/计划",
                "row1": "无限的产品",
                "row2": "Google Ads-购物Feed",
                "row3": "Google Ads-动态展示广告Feed",
                "row4": "Google Ads-DSA Pagefeed",
                "row5": "Facebook产品Feed",
                "row6": "Arukereso.hu产品Feed",
                "row7": "Criteo产品Feed",
                "row8": "实时出价自家产品Feed",
                "row9": "Google Ads自动化",
                "row10": "海关进出口",
                "row11": "网络研讨会",
                "row12": "专属客户经理",
                "row13": "欢迎券",
                "row14": "月租费",
                "row15": "每10,000次操作的价格"
            },
            "agency": "如果我们在3个月内没有达到预期的效果，您将全数退款。",
            "col2": {
                "header": "终身免费",
                "row6": "每周",
                "cta": "注册",
                "row1": "内置仪表板",
                "row14": "$ 9",
                "row15": "呐"
            },
            "col3": {
                "header": "优质",
                "row6": "日常",
                "cta": "注册",
                "row1": "高级仪表板",
                "row13": "$ 300",
                "row14": "$ 9",
                "row15": "1美元"
            },
            "col4": {
                "row6": "即时的",
                "cta": "注册",
                "row1": "VIP仪表板",
                "row10": "私人网络研讨会",
                "header": "早鸟价",
                "row13": "$ 1000",
                "row14": "$ 9",
                "row15": "1美元"
            }
        },
        "agency": {
            "allin": {
                "li6": "聊天机器人",
                "li9": "退款保证*"
            }
        },
        "title": "适合您整个计划\n行销预算",
        "sec2": {
            "title": "主导您的在线增长",
            "cta": "免费"
        },
        "sec6": {
            "title": "如果您需要技术支持，请访问我们的支持仪表板。",
            "cta": "支持"
        },
        "header": {
            "title": "价钱",
            "sub": "现收现付"
        }
    },
    "testimonials": {
        "title": "我们的用户怎么说",
        "1": {
            "desc": "我们已经在Google广告系列上开展了将近一年的合作。我们一直在代理机构中从事快速而专业的工作，他们非常称职，合作并希望完全满足我们的需求。除了带来创新的解决方案外，它们还足够冷静地深入解释复杂问题的解决方案和露营的结果。",
            "name": "亚当·达洛斯（Adam Dallos）",
            "pos": "普华永道，传播业务发展市场营销助理经理"
        }
    },
    "footer": {
        "description": ""
    },
    "menu": {
        "navigation": "导航",
        "signin": "登入",
        "features": "特征",
        "pricing": "价钱",
        "blog": "博客",
        "terms-conditions": "条款及细则",
        "partners": "合伙",
        "affiliate": "会员关系",
        "whitelabel": "白色标签",
        "introducing": "合作伙伴介绍",
        "findus": "在 - 找到我们",
        "home": "家",
        "dashboard": "仪表板",
        "contact": "联系"
    },
    "features": {
        "title": "Google <br\/>上的广告变得比以往更容易",
        "subtitle": "",
        "sec1": {
            "title": "为什么要在Google上刊登广告？",
            "col1": {
                "subtitle": "击败竞争对手",
                "desc": "人们每天都在搜索您的品牌或产品，因此Google可以使您的业务在竞争中脱颖而出。"
            },
            "col2": {
                "subtitle": "接触理想的客户",
                "desc": "Google是最好的广告平台之一，可以在所有参与级别的适当时间针对适当的用户。"
            },
            "col3": {
                "subtitle": "增加销售",
                "desc": "通过Google，访问者的网站访问量将大大增加，访问者可以轻松地转变为客户，从而促进您的销售和在线业务。"
            }
        },
        "sec2": {
            "title": "主导您的在线增长",
            "cta": "免费试用"
        }
    },
    "getstarted": {
        "title": "通过3个简单的步骤开始",
        "col1": {
            "subtitle": "1.下载",
            "desc": ""
        },
        "col2": {
            "subtitle": "2.激活",
            "desc": ""
        },
        "col3": {
            "subtitle": "3.追踪",
            "desc": ""
        }
    },
    "dashboard": {
        "title": "仪表板",
        "domainsettings": {
            "title": "域设置",
            "subtitle1": "新增网域",
            "input1": "您的新域名",
            "cta1": "加",
            "subtitle2": "您目前的网域",
            "currentdomains1": "域",
            "currentdomains2": "API密钥"
        },
        "subscription": {
            "title": "订阅",
            "subtitle1": "平衡",
            "remaining": "剩余的"
        },
        "tab2": {
            "desc1": "您目前正在使用我们的",
            "desc2": "订阅",
            "cta": "开关订阅"
        },
        "billing": {
            "title": "结算明细",
            "input": {
                "name": "开票名称",
                "vat": "增值税号",
                "address": "地址",
                "city": "市",
                "zip": "邮政编码",
                "country": "国家"
            }
        },
        "user": {
            "title": "用户设置",
            "subtitle": "您的登录详细信息",
            "lastlogin": "上次登录："
        }
    },
    "cta": {
        "update": "更新"
    },
    "input": {
        "email": "电子邮件地址",
        "password": "密码",
        "passwordrepeat": "重复输入密码"
    },
    "partners": {
        "affiliate": {
            "subtitle": "独家奖金和促销活动<br>提升会员",
            "substitle2": "联盟营销工具和付款计划的融合，以提高您的利润！"
        },
        "affilieate": {
            "description": ""
        },
        "whitelabel": {
            "title": "白标合作伙伴"
        },
        "introducing": {
            "subtitle": "",
            "desc2": "欲了解更多信息，请联系我们的专业团队："
        }
    },
    "url": {
        "features": "特征",
        "pricing": "价钱",
        "partnership": {
            "affiliate": "伙伴关系\/会员",
            "whitelabel": "伙伴关系\/白色标签",
            "introductory": "合作\/介绍"
        },
        "contact": "联系",
        "login": "登录"
    },
    "b2c": {
        "main": {
            "title": "<b>纸<\/b> .MANAGEMENT"
        },
        "cta1": {
            "title": "早起的鸟儿",
            "description": "在有限的时间内，每位早起的鸟儿用户均可获得1000美元的欢迎积分，以测试Feed Studio Premium套餐。 <br\/> <br\/>没有试用期，不需要信用卡。",
            "button": "获得$ 1,000的信用额"
        },
        "sec1": {
            "title": "您如何使用feed.studio",
            "col1": {
                "title": "注册并给我们<br\/>您的原始供稿",
                "desc": "给我们您的联系方式和供稿数据以设置您的帐户"
            },
            "col2": {
                "title": "我们的管理者建立<br\/>您的出口饲料",
                "desc": "从您的原始饲料中，我们创建您的出口饲料"
            },
            "col3": {
                "title": "享受（结果）",
                "desc": "请坐下来，知道我们的专家正在努力为您提供所需的最佳饲料。"
            }
        },
        "sec2": {
            "title": "报名参加早期鸟类测试仪",
            "infoList": {
                "item1": "没有欢迎信用的时间限制",
                "item2": "我们不会在欢迎期结束时向您收费\/向您收费",
                "item3": "没有产品或出口限制",
                "item4": "托管服务-您无需学习平台",
                "item5": "您可以随时删除帐户",
                "item6": "您可以询问自定义导出格式"
            },
            "form": {
                "name": "名称",
                "email": "电子邮件",
                "phone": "电话",
                "feedUrl": "资讯提供网址",
                "policyLabel": "我接受这些条款和条件",
                "marketingLabel": "我想订阅Feed.Studio时事通讯",
                "button": "抢先体验",
                "feedType": ""
            }
        },
        "meta": {
            "title": "XML产品Feed生成器，Google购物和Facebook Feed",
            "desc": {
                "short": "轻松创建和优化您的Google和Facebook feed。",
                "long": "Google Shopping Feed，产品Feed优化。"
            }
        }
    },
    "b2b": {
        "main": {
            "title": "<b>纸<\/b> .PARTNERS",
            "description": "Feed.Studio专为电子商务专业人士和代理商而设计。我们正在寻找区域合作伙伴，以建立成功的未来。 <\/br>提高收入，销售更多商品！",
            "button": "卖更多"
        },
        "sec1": {
            "title": "为什么与我们合作？",
            "col1": {
                "title": "卖更多",
                "desc": "卖更多"
            },
            "col2": {
                "title": "击败您的<\/br>竞争对手",
                "desc": "成为第一个"
            },
            "col3": {
                "title": "满足您的<\/br>客户",
                "desc": "质量控制"
            },
            "col4": {
                "title": "省时间",
                "desc": "我们的服务周到"
            }
        },
        "secPartner": {
            "title": "伙伴关系",
            "header": {
                "affiliate": "会员关系",
                "gold": "金",
                "exclusive": "独家",
                "custom": "自订"
            },
            "requirements": {
                "title": "要求",
                "newClient": "新客户\/年",
                "certifiedusers": "认证的活跃内部用户"
            },
            "recognition": {
                "title": "承认",
                "visible": "在feed.studio上可见",
                "trademark": "使用“ feed studio”商标的权利"
            },
            "training": {
                "title": "培训福利",
                "biweekly": "每两周一次的会议",
                "salesSupport": "销售支持和指导"
            },
            "marketing": {
                "title": "营销利益",
                "brand": "本地广告系列上的品牌",
                "customerStories": "客户成功案例"
            },
            "sales": {
                "title": "销售收益",
                "materials": "销售资料",
                "accountManagers": "专用客户经理",
                "leads": "当地线索",
                "affiliateBonus": "附属客户奖金",
                "feedStudioBonus": "Feed Studio上的奖金"
            },
            "contactUs": "联系我们",
            "signUp": "注册"
        },
        "sec3": {
            "title": "如何成为我们的合作伙伴？",
            "col1": {
                "title": "注册",
                "desc": ""
            },
            "col2": {
                "title": "参加我们的销售和平台培训",
                "desc": ""
            },
            "col3": {
                "title": "管理我们的潜在客户和客户",
                "desc": ""
            }
        },
        "sec4": {
            "title": "注册合作伙伴计划",
            "infoList": {
                "item1": "访问Feed Studio讨论战略，销售和服务问题",
                "item2": "在Feed Studio合作伙伴页面上被列为正式合作伙伴，从而提高知名度和知名度",
                "item3": "访问销售仪表板"
            },
            "form": {
                "plan": {
                    "affiliate": "会员关系",
                    "gold": "金",
                    "custom": "自订"
                },
                "name": "名称",
                "email": "电子邮件",
                "phone": "电话",
                "message": "信息",
                "policyLabel": "我接受这些条款和条件",
                "marketingLabel": "我想订阅Feed.Studio时事通讯",
                "button": "抢先体验"
            }
        }
    },
    "registration": {
        "meta": {
            "title": "免费注册Feed Studio",
            "desc": {
                "short": "注册并获得$ 1000的Feed Studio赠送金额。",
                "long": "注册并获得$ 1000的欢迎积分，即可使用Feed Studio。"
            }
        }
    },
    "earlybirdreg": {
        "form": {
            "infoList": {
                "desc1": "",
                "desc2": "",
                "item1": "",
                "item2": "",
                "item3": "",
                "item4": "",
                "item5": "",
                "item6": "",
                "item7": ""
            }
        }
    },
    "login": {
        "title": "",
        "subtitle": "",
        "subtitle2": "",
        "googleButton": ""
    }
};