export default {
    "home": {
        "sec1": {
            "title": "Tingkatkan pendapatan anda. Dengan bersungguh-sungguh.",
            "cta": "Percuma muat turun",
            "col1": {
                "title": "meningkatkan pendapatan <br\/> anda",
                "desc": "mengoptimumkan struktur suapan anda dan meningkatkan pendapatan anda sebanyak 5-30%"
            },
            "col2": {
                "title": "buka saluran <br\/> jualan baru",
                "desc": "menjual lebih banyak dengan saluran penjualan baru"
            },
            "col3": {
                "title": "cuba percuma tanpa had masa <br\/>",
                "desc": "kaedah pembayaran kami semasa anda memberi anda penyelesaian paling fleksibel di pasaran"
            },
            "col4": {
                "title": "menjimatkan masa anda <br\/>",
                "desc": "perunding kami membina dan mengoptimumkan suapan produk anda"
            }
        },
        "sec2": {
            "title": "import & eksport dengan senang",
            "col1": {
                "title": "pengurusan pembekal <br\/> suapan",
                "desc": "Feed Studio menggabungkan suapan produk yang berbeza menjadi satu suapan yang boleh diimport"
            },
            "col2": {
                "title": "gunakan lebih daripada 100 <br\/> format lain",
                "desc": "Perlukan deskripsi Google Shopping dan Instangram yang berbeza? Tidak menjadi masalah"
            },
            "col3": {
                "title": "mengoptimumkan data <br\/> produk anda mengikut platform",
                "desc": "Adakah anda memerlukan sedikit penerangan membeli-belah Google daripada kedai Instagram? Tiada masalah"
            },
            "col4": {
                "title": "sejarah perubahan",
                "desc": "Mengapa mengiklankan harga yang salah? Bila gagal kemas kini produk anda? Kami tahu jawapannya!"
            },
            "col5": {
                "title": "Pengoptimuman SEO",
                "desc": "Pembuatan dan pengoptimuman tag meta SEO automatik."
            },
            "col6": {
                "title": "perkhidmatan terurus",
                "desc": "Feed Studio menggabungkan suapan produk yang berbeza menjadi satu suapan yang boleh diimport"
            }
        },
        "sec3": {
            "title": "Ingin mengembangkan perniagaan anda tanpa perjuangan?"
        },
        "sec4": {
            "item2": {
                "subtitle": "Pengurusan Makanan"
            },
            "item4": {
                "subtitle": "Konsol carian"
            }
        },
        "sec5": {
            "title": "Kempen Google Ads automatik yang indah",
            "col1": {
                "title": "Iklan Jenama",
                "desc": "Adakah anda tahu bahawa 40% pelanggan anda tidak mengklik laman web anda di halaman hasil carian? Jangkau mereka dengan kempen jenama!"
            },
            "col2": {
                "title": "Iklan Carian Dinamik"
            },
            "col3": {
                "title": "Iklan Beli-Belah"
            },
            "col4": {
                "title": "Iklan Paparan",
                "desc": "Jangkau sasaran audiens anda di Rangkaian Paparan Google dengan pemasaran ulang responsif, pemasaran ulang dinamis dan iklan pintar."
            }
        },
        "sec6": {
            "title": "Bersedia untuk mengautomasikan iklan anda?",
            "cta": "Dapatkan Percuma"
        },
        "sec7": {
            "title": "",
            "subtitle": "Perkhidmatan lengkap walaupun dalam pelan percuma"
        },
        "premier-partner-desc": "Kami dibezakan oleh Google sebagai agensi berprestasi terbaik sehingga anda dapat memastikan strategi Google Ads anda berada di tangan yang baik.",
        "sec8": {
            "title": "",
            "cta": "PERCUMA MUAT TURUN"
        },
        "main": {
            "title": "<b> MAKANAN <\/b> .ME.BABY",
            "description": "Minta akaun awal untuk mencuba perisian pengurus suapan google terkini <br\/> direka untuk profesional dan agensi. <br\/> Untuk masa yang terhad, anda akan mendapat <s> $ 300 <\/s> kredit selamat datang $ 1,000.",
            "button": "DAPATKAN kredit $ 1,000"
        },
        "cta1": {
            "title": "PROMOSI AWAL AWAL",
            "description": "pengguna pertama kami mendapat kredit selamat datang $ 1000 untuk menguji Feed Studio",
            "button": "DAPATKAN KREDIT $ 1,000",
            "subtitle": "TIADA KAD KREDIT DIPERLUKAN!"
        },
        "secFeeds": {
            "title": "apa yang harus anda perbaiki dalam suapan anda?",
            "header": {
                "channel": "saluran",
                "export": "eksport",
                "description": "penerangan",
                "result": "hasil"
            },
            "google": {
                "title": "google",
                "feeds": {
                    "merchant": {
                        "name": "Suapan Merchant Center",
                        "desc": "menjual produk di Google Shopping",
                        "result": "meningkatkan pendapatan dengan struktur lapangan yang dioptimumkan"
                    },
                    "promotions": {
                        "name": "Suapan promosi",
                        "desc": "tunjukkan promosi kepada pelanggan anda",
                        "result": "tingkatkan kadar klik lalu & Tingkatkan penukaran"
                    },
                    "localInventory": {
                        "name": "Suapan Iklan Inventori Tempatan",
                        "desc": "tunjukkan produk anda di Peta Google",
                        "result": "meningkatkan pendapatan luar talian, bawa pelanggan baru ke kedai anda"
                    },
                    "productRatings": {
                        "name": "Suapan Penilaian Produk",
                        "desc": "sediakan penilaian dan ulasan produk anda untuk pengguna Google",
                        "result": "kadar klik lalu yang lebih tinggi"
                    },
                    "dynamicRemarketing": {
                        "name": "Suapan Pemasaran Semula Dinamik",
                        "desc": "tunjukkan iklan kepada orang yang pernah mengunjungi laman web anda sebelum ini",
                        "result": "meningkatkan pendapatan"
                    },
                    "page": {
                        "name": "Suapan halaman",
                        "desc": "Suapan halaman untuk Iklan Carian Dinamik",
                        "result": "meningkatkan prestasi kempen carian anda"
                    },
                    "sitemap": {
                        "name": "Suapan Peta Laman",
                        "desc": "Suapan URL untuk pengoptimuman enjin carian",
                        "result": "tingkatkan keterlihatan anda dalam Carian Google"
                    },
                    "manufacturer": {
                        "name": "Suapan pengeluar",
                        "desc": "berikan maklumat yang lebih baik dan tepat mengenai produk anda ke Google",
                        "result": "dapatkan analisis yang komprehensif & tingkatkan kehadiran anda di Google"
                    }
                }
            },
            "facebook": {
                "title": "facebook",
                "feeds": {
                    "dynamicAds": {
                        "name": "Suapan Iklan Dinamik",
                        "desc": "tunjukkan iklan kepada orang yang pernah mengunjungi laman web anda sebelum ini",
                        "result": "meningkatkan pendapatan"
                    },
                    "pageShop": {
                        "name": "Suapan Kedai Halaman",
                        "desc": "tunjukkan dan jual produk kepada orang di Facebook",
                        "result": "meningkatkan pendapatan"
                    },
                    "marketplace": {
                        "name": "Suapan pasar",
                        "desc": "tunjukkan dan jual produk kepada orang ramai di Facebook Marketplace",
                        "result": "meningkatkan pendapatan"
                    }
                }
            },
            "instagram": {
                "title": "instagram",
                "feeds": {
                    "shopping": {
                        "name": "Suapan membeli-belah",
                        "desc": "tunjukkan dan jual produk kepada orang di Instagram",
                        "result": "meningkatkan pendapatan"
                    }
                }
            },
            "amazon": {
                "title": "amazon",
                "feeds": {
                    "product": {
                        "name": "Suapan produk",
                        "desc": "tunjukkan dan jual produk kepada orang di Amazon",
                        "result": "meningkatkan pendapatan"
                    }
                }
            },
            "ebay": {
                "title": "ebay",
                "feeds": {
                    "product": {
                        "name": "Suapan produk",
                        "desc": "tunjukkan dan jual produk kepada orang di Ebay",
                        "result": "meningkatkan pendapatan"
                    }
                }
            },
            "criteo": {
                "title": "criteo",
                "feeds": {
                    "product": {
                        "name": "Suapan produk",
                        "desc": "menjual produk dalam talian",
                        "result": "meningkatkan pendapatan"
                    }
                }
            }
        },
        "cta2": {
            "title": "TINGKATKAN JUALAN ANDA DENGAN FEED.STUDIO",
            "button": "DAPATKAN KREDIT $ 1,000",
            "subtitle": "TIADA KAD KREDIT DIPERLUKAN!"
        },
        "meta": {
            "title": "Feed Studio »Alat Pengurus Suapan XML - Penjana Suapan Produk XML - Suapan Belanja Google",
            "desc": {
                "short": "Alat penjana Umpan Beli-belah Google.",
                "long": "Penjana Umpan Beli-belah Google"
            }
        }
    },
    "pricing": {
        "plugin": {
            "col1": {
                "header": "CIRI \/ PELAN",
                "row1": "Produk tanpa had",
                "row2": "Google Ads - Suapan Beli-belah",
                "row3": "Google Ads - Suapan Iklan Paparan Dinamik",
                "row4": "Google Ads - DSA Halaman",
                "row5": "Suapan Produk Facebook",
                "row6": "Suapan Produk Arukereso.hu",
                "row7": "Suapan Produk Criteo",
                "row8": "Suapan Produk RTB House",
                "row9": "Automasi Google Ads",
                "row10": "Import dan eksport tersuai",
                "row11": "Webinar",
                "row12": "Pengurus Akaun Khusus",
                "row13": "Baucar Selamat Datang",
                "row14": "Yuran bulanan",
                "row15": "Harga setiap 10,000 operasi"
            },
            "agency": "Semua wang anda dikembalikan sekiranya kami tidak memberikan hasil yang diharapkan dalam 3 bulan.",
            "col2": {
                "header": "HIDUP PERCUMA",
                "row6": "Setiap minggu",
                "cta": "Pendaftaran",
                "row1": "Papan pemuka terbina dalam",
                "row14": "$ 9",
                "row15": "na"
            },
            "col3": {
                "header": "PREMIUM",
                "row6": "Setiap hari",
                "cta": "Pendaftaran",
                "row1": "Papan pemuka premium",
                "row13": "$ 300",
                "row14": "$ 9",
                "row15": "$ 1"
            },
            "col4": {
                "row6": "Waktu sebenar",
                "cta": "Pendaftaran",
                "row1": "Papan pemuka VIP",
                "row10": "Webinar peribadi",
                "header": "Premium Awal Burung",
                "row13": "$ 1000",
                "row14": "$ 9",
                "row15": "$ 1"
            }
        },
        "agency": {
            "allin": {
                "li6": "Chatbot",
                "li9": "Jaminan wang dikembalikan *"
            }
        },
        "title": "Pelan yang sesuai dengan keseluruhan anda \n belanjawan pemasaran",
        "sec2": {
            "title": "Kuasai Pertumbuhan Dalam Talian anda",
            "cta": "DAPATKAN PERCUMA"
        },
        "sec6": {
            "title": "Sekiranya anda memerlukan sokongan teknikal, kunjungi papan pemuka sokongan kami.",
            "cta": "SOKONGAN"
        },
        "header": {
            "title": "HARGA",
            "sub": "bayar semasa awak hendak pergi"
        }
    },
    "testimonials": {
        "title": "Apa yang pengguna katakan",
        "1": {
            "desc": "Kami telah bekerjasama dalam kempen Iklan Google selama hampir setahun sekarang. Kami selalu mendapat pekerjaan yang cepat dan profesional dari agensi, mereka sangat kompeten, bekerjasama dan ingin memenuhi keperluan kami sepenuhnya. Selain membawa penyelesaian inovatif, mereka cukup tenang untuk menjelaskan secara mendalam penyelesaian untuk masalah yang kompleks dan hasil perkhemahan.",
            "name": "Adam Dallos",
            "pos": "PwC, Penolong Pengurus, Pemasaran, Pembangunan Perniagaan Komunikasi"
        }
    },
    "footer": {
        "description": ""
    },
    "menu": {
        "navigation": "NAVIGASI",
        "signin": "Log masuk",
        "features": "ciri-ciri",
        "pricing": "Harga",
        "blog": "Blog",
        "terms-conditions": "Terma & Syarat",
        "partners": "PERKONGSIAN",
        "affiliate": "Ahli gabungan",
        "whitelabel": "label putih",
        "introducing": "Memperkenalkan Rakan Kongsi",
        "findus": "Cari Kami di",
        "home": "Rumah",
        "dashboard": "Papan Pemuka",
        "contact": "Hubungi"
    },
    "features": {
        "title": "Iklan di Google <br\/> lebih mudah daripada sebelumnya",
        "subtitle": "",
        "sec1": {
            "title": "Mengapa beriklan di Google?",
            "col1": {
                "subtitle": "Kalahkan Pertandingan anda",
                "desc": "Setiap hari orang mencari jenama atau produk anda, Google memberi anda kemampuan untuk memajukan perniagaan anda di hadapan persaingan anda."
            },
            "col2": {
                "subtitle": "Jangkau Pelanggan yang ideal",
                "desc": "Google adalah salah satu platform pengiklanan terbaik untuk menargetkan pengguna yang tepat pada waktu yang tepat di semua peringkat penglibatan."
            },
            "col3": {
                "subtitle": "Tingkatkan Jualan anda",
                "desc": "Lalu lintas ke laman web anda akan meningkat dengan banyak melalui Google dengan pelawat yang dapat dengan mudah berubah menjadi pelanggan, mengembangkan penjualan & perniagaan dalam talian anda."
            }
        },
        "sec2": {
            "title": "Kuasai Pertumbuhan Dalam Talian anda",
            "cta": "CUBA PERCUMA"
        }
    },
    "getstarted": {
        "title": "Mulakan dengan 3 langkah mudah",
        "col1": {
            "subtitle": "1. Muat turun",
            "desc": ""
        },
        "col2": {
            "subtitle": "2. Aktifkan",
            "desc": ""
        },
        "col3": {
            "subtitle": "3. Jejak",
            "desc": ""
        }
    },
    "dashboard": {
        "title": "Papan Pemuka",
        "domainsettings": {
            "title": "PENGATURAN DOMAIN",
            "subtitle1": "Tambahkan domain baru",
            "input1": "Domain baru anda",
            "cta1": "Tambah",
            "subtitle2": "Domain semasa anda",
            "currentdomains1": "Domain",
            "currentdomains2": "Kunci API"
        },
        "subscription": {
            "title": "Langganan",
            "subtitle1": "Seimbang",
            "remaining": "baki"
        },
        "tab2": {
            "desc1": "Anda kini menggunakan kami",
            "desc2": "langganan",
            "cta": "LANGKAH LANGKAH"
        },
        "billing": {
            "title": "Maklumat Bil",
            "input": {
                "name": "Nama pengebilan",
                "vat": "Nombor VAT",
                "address": "Alamat",
                "city": "Bandar",
                "zip": "Poskod",
                "country": "Negara"
            }
        },
        "user": {
            "title": "PENGATURAN PENGGUNA",
            "subtitle": "Maklumat log masuk anda",
            "lastlogin": "Log masuk kali terakhir:"
        }
    },
    "cta": {
        "update": "KEMASKINI"
    },
    "input": {
        "email": "Alamat emel",
        "password": "Kata Laluan",
        "passwordrepeat": "Ulang kata laluan"
    },
    "partners": {
        "affiliate": {
            "subtitle": "Bonus & Promosi Eksklusif <br> untuk meningkatkan Ahli Gabungan",
            "substitle2": "Gabungan alat pemasaran & rancangan pembayaran gabungan untuk meningkatkan keuntungan anda!"
        },
        "affilieate": {
            "description": ""
        },
        "whitelabel": {
            "title": "Perkongsian Label Putih"
        },
        "introducing": {
            "subtitle": "",
            "desc2": "Untuk maklumat lebih lanjut, sila hubungi pasukan khusus kami di:"
        }
    },
    "url": {
        "features": "ciri-ciri",
        "pricing": "harga",
        "partnership": {
            "affiliate": "patnerhip \/ gabungan",
            "whitelabel": "kewarganegaraan \/ label putih",
            "introductory": "ikhlas \/ perkenalan"
        },
        "contact": "kenalan",
        "login": "log masuk"
    },
    "b2c": {
        "main": {
            "title": "<b> MAKANAN <\/b> .PENGURUSAN"
        },
        "cta1": {
            "title": "PROMOSI AWAL AWAL",
            "description": "Untuk masa yang terhad setiap pengguna awal burung mendapat kredit selamat datang $ 1000 untuk menguji pakej Feed Studio Premium. <br\/> <br\/> Tidak perlu tempoh percubaan, kad kredit tidak diperlukan.",
            "button": "DAPATKAN KREDIT $ 1,000"
        },
        "sec1": {
            "title": "bagaimana anda boleh menggunakan feed.studio",
            "col1": {
                "title": "daftar & beri kami <br\/> makanan mentah anda",
                "desc": "beri kami data hubungan dan suapan anda untuk menyiapkan akaun anda"
            },
            "col2": {
                "title": "pengurus kami menyediakan <br\/> suapan eksport anda",
                "desc": "dari suapan mentah anda, kami membuat suapan eksport anda"
            },
            "col3": {
                "title": "Nikmati (hasilnya)",
                "desc": "Harap maklum bahawa pakar kami bekerja keras untuk memberikan makanan terbaik yang anda perlukan."
            }
        },
        "sec2": {
            "title": "Daftar untuk penguji burung awal",
            "infoList": {
                "item1": "Tiada had masa untuk kredit alu-aluan",
                "item2": "kami tidak mengenakan bayaran \/ pembayaran kepada anda pada akhir tempoh sambutan",
                "item3": "Tiada had produk atau eksport",
                "item4": "Perkhidmatan terurus - anda tidak perlu mempelajari platform",
                "item5": "Anda boleh memadamkan akaun anda bila-bila masa",
                "item6": "Anda boleh meminta format eksport tersuai"
            },
            "form": {
                "name": "Nama",
                "email": "E-mel",
                "phone": "Telefon",
                "feedUrl": "URL suapan",
                "policyLabel": "Saya menerima Terma dan Syarat",
                "marketingLabel": "Saya ingin melanggan buletin Feed.Studio",
                "button": "dapatkan akses awal",
                "feedType": ""
            }
        },
        "meta": {
            "title": "Penjana Suapan Produk XML, Google Shopping & Facebook Feed",
            "desc": {
                "short": "Buat dan optimumkan suapan Google dan Facebook anda dengan mudah.",
                "long": "Suapan Beli-belah Google, Pengoptimuman Suapan Produk."
            }
        }
    },
    "b2b": {
        "main": {
            "title": "<b> MAKANAN <\/b> RAKAN",
            "description": "Feed.Studio direka untuk ahli e-dagang dan agensi. Kami mencari rakan serantau untuk membina masa depan yang berjaya. <\/br> Tingkatkan pendapatan anda, jual lebih banyak dengan Feed.Studio!",
            "button": "JUAL LEBIH LANJUT"
        },
        "sec1": {
            "title": "Mengapa bekerjasama dengan kami?",
            "col1": {
                "title": "jual lebih banyak",
                "desc": "jual lebih banyak"
            },
            "col2": {
                "title": "menewaskan pesaing <\/br> anda",
                "desc": "menjadi yang pertama"
            },
            "col3": {
                "title": "memuaskan pelanggan <\/br> anda",
                "desc": "kawalan kualiti"
            },
            "col4": {
                "title": "menjimatkan masa",
                "desc": "dengan perkhidmatan mamaged kami"
            }
        },
        "secPartner": {
            "title": "perkongsian",
            "header": {
                "affiliate": "Ahli gabungan",
                "gold": "Emas",
                "exclusive": "Eksklusif",
                "custom": "Adat"
            },
            "requirements": {
                "title": "Keperluan",
                "newClient": "pelanggan baru \/ tahun",
                "certifiedusers": "pengguna dalaman aktif yang diperakui"
            },
            "recognition": {
                "title": "Pengiktirafan",
                "visible": "kelihatan pada feed.studio",
                "trademark": "hak untuk menggunakan tanda dagangan \"feed studio\""
            },
            "training": {
                "title": "Faedah latihan",
                "biweekly": "perjumpaan dua minggu",
                "salesSupport": "sokongan dan bimbingan penjualan"
            },
            "marketing": {
                "title": "faedah pemasaran",
                "brand": "jenama pada kempen tempatan",
                "customerStories": "kisah kejayaan pelanggan"
            },
            "sales": {
                "title": "faedah penjualan",
                "materials": "bahan jualan",
                "accountManagers": "pengurus akaun yang berdedikasi",
                "leads": "petunjuk tempatan",
                "affiliateBonus": "BONUS PADA PELANGGAN AFFILIATE",
                "feedStudioBonus": "bonus di Feed Studio"
            },
            "contactUs": "Hubungi Kami",
            "signUp": "Mendaftar"
        },
        "sec3": {
            "title": "bagaimana menjadi pasangan kita?",
            "col1": {
                "title": "daftar",
                "desc": ""
            },
            "col2": {
                "title": "ambil bahagian dalam latihan penjualan dan platform kami",
                "desc": ""
            },
            "col3": {
                "title": "urus petunjuk dan pelanggan kami",
                "desc": ""
            }
        },
        "sec4": {
            "title": "Daftar untuk PROGRAM RAKAN RAKAN",
            "infoList": {
                "item1": "akses ke Feed Studio membincangkan strategi, penjualan dan masalah perkhidmatan",
                "item2": "keterlihatan & pengiktirafan dengan disenaraikan sebagai rakan kongsi rasmi di halaman rakan Feed Studio",
                "item3": "akses ke papan pemuka Jualan"
            },
            "form": {
                "plan": {
                    "affiliate": "Ahli gabungan",
                    "gold": "Emas",
                    "custom": "Adat"
                },
                "name": "Nama",
                "email": "E-mel",
                "phone": "Telefon",
                "message": "Mesej",
                "policyLabel": "Saya menerima Terma dan Syarat",
                "marketingLabel": "Saya ingin melanggan buletin Feed.Studio",
                "button": "Dapatkan Akses Awal"
            }
        }
    },
    "registration": {
        "meta": {
            "title": "Daftar percuma untuk memberi makan Studio",
            "desc": {
                "short": "Daftar dan dapatkan kredit $ 1000 untuk Feed Studio.",
                "long": "Daftar dan dapatkan kredit selamat datang $ 1000 untuk menggunakan Feed Studio."
            }
        }
    },
    "earlybirdreg": {
        "form": {
            "infoList": {
                "desc1": "",
                "desc2": "",
                "item1": "",
                "item2": "",
                "item3": "",
                "item4": "",
                "item5": "",
                "item6": "",
                "item7": ""
            }
        }
    },
    "login": {
        "title": "",
        "subtitle": "",
        "subtitle2": "",
        "googleButton": ""
    }
};