export default {
    "home": {
        "sec1": {
            "title": "Aumenta tus ingresos. Sin esfuerzo",
            "cta": "Descargar gratis",
            "col1": {
                "title": "aumenta tus <br\/> ingresos",
                "desc": "optimice su estructura de alimentación y aumente sus ingresos en un 5-30%"
            },
            "col2": {
                "title": "abrir nuevos canales de ventas <br\/>",
                "desc": "vender más con nuevos canales de venta"
            },
            "col3": {
                "title": "probar gratis sin <br\/> límite de tiempo",
                "desc": "Nuestro método de fijación de precios de pago por uso le brinda la solución más flexible del mercado"
            },
            "col4": {
                "title": "ahorre su <br\/> tiempo",
                "desc": "nuestros consultores construyen y optimizan sus feeds de productos"
            }
        },
        "sec2": {
            "title": "importa y exporta con facilidad",
            "col1": {
                "title": "gestión de alimentación de proveedores <br\/>",
                "desc": "Feed Studio combina diferentes feeds de productos en un feed importable"
            },
            "col2": {
                "title": "usa más de 100 <br\/> otros formatos",
                "desc": "¿Necesita diferentes descripciones de Google Shopping e Instangram? No es un problema"
            },
            "col3": {
                "title": "optimice los datos de su producto <br\/> por plataforma",
                "desc": "¿Necesita una descripción de compras de Google un poco diferente a la de Instagram? No hay problema"
            },
            "col4": {
                "title": "cambia la historia",
                "desc": "¿Por qué anunciar precio incorrecto? ¿Cuándo falló la actualización de su producto? ¡Sabemos la respuesta!"
            },
            "col5": {
                "title": "Optimización SEO",
                "desc": "Creación y optimización automatizada de metaetiquetas SEO."
            },
            "col6": {
                "title": "servicio gestionado",
                "desc": "Feed Studio combina diferentes feeds de productos en un feed importable"
            }
        },
        "sec3": {
            "title": "¿Quieres hacer crecer tu negocio sin la lucha?"
        },
        "sec4": {
            "item2": {
                "subtitle": "Manejo de alimento"
            },
            "item4": {
                "subtitle": "Consola de búsqueda"
            }
        },
        "sec5": {
            "title": "Campañas de Google Ads bellamente automatizadas",
            "col1": {
                "title": "Anuncios de marca",
                "desc": "¿Sabía que el 40% de sus clientes no hacen clic en su sitio en la página de resultados de búsqueda? ¡Alcanzarlos con campañas de marca!"
            },
            "col2": {
                "title": "Anuncios dinámicos de búsqueda"
            },
            "col3": {
                "title": "Anuncios de compras"
            },
            "col4": {
                "title": "Anuncios de display",
                "desc": "Llegue a su público objetivo en la Red de Display de Google con remarketing receptivo, remarketing dinámico y anuncios inteligentes."
            }
        },
        "sec6": {
            "title": "¿Listo para automatizar su anuncio?",
            "cta": "Liberarse"
        },
        "sec7": {
            "title": "",
            "subtitle": "Servicios integrales incluso en el plan gratuito."
        },
        "premier-partner-desc": "Google nos distingue como una agencia de alto rendimiento, por lo que puede estar seguro de que su estrategia de Google Ads está en buenas manos.",
        "sec8": {
            "title": "",
            "cta": "DESCARGAR GRATIS"
        },
        "main": {
            "title": "<b> ALIMENTACIÓN <\/b> .ME.BABY",
            "description": "Solicite una cuenta anticipada para probar el último software de administración de feeds de Google <br\/> diseñado para profesionales y agencias. <br\/> Por un tiempo limitado obtendrá <s> $ 300 <\/s> $ 1,000 de crédito de bienvenida.",
            "button": "OBTENGA $ 1,000 de crédito"
        },
        "cta1": {
            "title": "PROMOCIÓN DE AVES ANTICIPADAS",
            "description": "nuestros primeros usuarios reciben un crédito de bienvenida de $ 1000 para probar Feed Studio",
            "button": "OBTENGA CRÉDITO DE $ 1,000",
            "subtitle": "¡NO SE NECESITA TARJETA DE CRÉDITO!"
        },
        "secFeeds": {
            "title": "¿Qué debes arreglar en tus feeds?",
            "header": {
                "channel": "canal",
                "export": "exportar",
                "description": "descripción",
                "result": "resultado"
            },
            "google": {
                "title": "google",
                "feeds": {
                    "merchant": {
                        "name": "Feed de Merchant Center",
                        "desc": "vender productos en Google Shopping",
                        "result": "aumentar los ingresos mediante una estructura de campo optimizada"
                    },
                    "promotions": {
                        "name": "Feed de promociones",
                        "desc": "mostrar promociones a su cliente",
                        "result": "aumentar el porcentaje de clics y aumentar las conversiones"
                    },
                    "localInventory": {
                        "name": "Feed de anuncios de inventario local",
                        "desc": "muestra tus productos en Google Maps",
                        "result": "aumentar los ingresos fuera de línea, atraer nuevos clientes a su tienda"
                    },
                    "productRatings": {
                        "name": "Feed de clasificaciones de productos",
                        "desc": "poner a disposición de los usuarios de Google las calificaciones y opiniones de sus productos",
                        "result": "mayor porcentaje de clics"
                    },
                    "dynamicRemarketing": {
                        "name": "Feeds de remarketing dinámico",
                        "desc": "mostrar anuncios a personas que han visitado su sitio web anteriormente",
                        "result": "aumentar los ingresos"
                    },
                    "page": {
                        "name": "Feed de página",
                        "desc": "Feed de página para anuncios dinámicos de búsqueda",
                        "result": "aumentar el rendimiento de su campaña de búsqueda"
                    },
                    "sitemap": {
                        "name": "Feed del mapa del sitio",
                        "desc": "Feed de URL para la optimización del motor de búsqueda",
                        "result": "aumentar su visibilidad en la Búsqueda de Google"
                    },
                    "manufacturer": {
                        "name": "Alimentación del fabricante",
                        "desc": "proporcionar información mejor y más precisa sobre sus productos a Google",
                        "result": "obtenga análisis completos y mejore su presencia en Google"
                    }
                }
            },
            "facebook": {
                "title": "Facebook",
                "feeds": {
                    "dynamicAds": {
                        "name": "Feed de anuncios dinámicos",
                        "desc": "mostrar anuncios a personas que han visitado su sitio web anteriormente",
                        "result": "aumentar los ingresos"
                    },
                    "pageShop": {
                        "name": "Feed de página de tienda",
                        "desc": "mostrar y vender productos a personas en Facebook",
                        "result": "aumentar los ingresos"
                    },
                    "marketplace": {
                        "name": "Feed del mercado",
                        "desc": "mostrar y vender productos a personas en Facebook Marketplace",
                        "result": "aumentar los ingresos"
                    }
                }
            },
            "instagram": {
                "title": "instagram",
                "feeds": {
                    "shopping": {
                        "name": "Feed de compras",
                        "desc": "mostrar y vender productos a personas en Instagram",
                        "result": "aumentar los ingresos"
                    }
                }
            },
            "amazon": {
                "title": "Amazonas",
                "feeds": {
                    "product": {
                        "name": "Alimentación del producto",
                        "desc": "mostrar y vender productos a personas en Amazon",
                        "result": "aumentar los ingresos"
                    }
                }
            },
            "ebay": {
                "title": "eBay",
                "feeds": {
                    "product": {
                        "name": "Alimentación del producto",
                        "desc": "mostrar y vender productos a personas en Ebay",
                        "result": "aumentar los ingresos"
                    }
                }
            },
            "criteo": {
                "title": "criteo",
                "feeds": {
                    "product": {
                        "name": "Alimentación del producto",
                        "desc": "vender productos en línea",
                        "result": "aumentar los ingresos"
                    }
                }
            }
        },
        "cta2": {
            "title": "AUMENTA TUS VENTAS CON FEED.STUDIO",
            "button": "OBTENGA CRÉDITO DE $ 1,000",
            "subtitle": "¡NO SE NECESITA TARJETA DE CRÉDITO!"
        },
        "meta": {
            "title": "Feed Studio »Herramienta de administración de feeds XML - Generador de feeds de productos XML - Google Shopping Feed",
            "desc": {
                "short": "Herramienta generadora de Google Shopping Feed.",
                "long": "Generador de feeds de Google Shopping"
            }
        }
    },
    "pricing": {
        "plugin": {
            "col1": {
                "header": "CARACTERÍSTICAS \/ PLANES",
                "row1": "Productos ilimitados",
                "row2": "Google Ads: feed de compras",
                "row3": "Google Ads: feed dinámico de anuncios gráficos",
                "row4": "Anuncios de Google: avance de página DSA",
                "row5": "Feed de productos de Facebook",
                "row6": "Arukereso.hu Feed de productos",
                "row7": "Feed de productos Criteo",
                "row8": "Alimentación de productos RTB House",
                "row9": "Automatización de anuncios de Google",
                "row10": "Importaciones y exportaciones personalizadas",
                "row11": "Seminarios web",
                "row12": "Gerente de cuenta dedicado",
                "row13": "Bono de bienvenida",
                "row14": "Cuota mensual",
                "row15": "Precio por 10,000 operaciones"
            },
            "agency": "Le devolvemos todo su dinero si no entregamos los resultados esperados en 3 meses.",
            "col2": {
                "header": "SIN VIDA",
                "row6": "Semanal",
                "cta": "Registro",
                "row1": "Tablero de instrumentos incorporado",
                "row14": "$ 9",
                "row15": "n \/ A"
            },
            "col3": {
                "header": "PRIMA",
                "row6": "Diario",
                "cta": "Registro",
                "row1": "Tablero premium",
                "row13": "$ 300",
                "row14": "$ 9",
                "row15": "$ 1"
            },
            "col4": {
                "row6": "Tiempo real",
                "cta": "Registro",
                "row1": "Tablero VIP",
                "row10": "Seminarios web privados",
                "header": "Early Bird Premium",
                "row13": "$ 1000",
                "row14": "$ 9",
                "row15": "$ 1"
            }
        },
        "agency": {
            "allin": {
                "li6": "Chatbot",
                "li9": "Garantía de devolución del dinero*"
            }
        },
        "title": "Planes que se adaptan a todo \n presupuesto de marketing",
        "sec2": {
            "title": "Domina tu crecimiento en línea",
            "cta": "LIBERARSE"
        },
        "sec6": {
            "title": "Si necesita soporte técnico, visite nuestro panel de soporte.",
            "cta": "APOYO"
        },
        "header": {
            "title": "PRECIOS",
            "sub": "paga a medida que avanzas"
        }
    },
    "testimonials": {
        "title": "Lo que dicen nuestros usuarios",
        "1": {
            "desc": "Llevamos casi un año trabajando juntos en campañas publicitarias de Google. Siempre hemos experimentado un trabajo rápido y profesional de la agencia, son muy competentes, cooperativos y buscan satisfacer plenamente nuestras necesidades. Además de aportar soluciones innovadoras, son lo suficientemente tranquilos como para explicar en profundidad las soluciones para problemas complejos y los resultados de las campañas.",
            "name": "Adam Dallos",
            "pos": "PwC, Subgerente, Marketing, Comunicación Desarrollo de negocios"
        }
    },
    "footer": {
        "description": ""
    },
    "menu": {
        "navigation": "NAVEGACIÓN",
        "signin": "Registrarse",
        "features": "Caracteristicas",
        "pricing": "Precios",
        "blog": "Blog",
        "terms-conditions": "Términos y condiciones",
        "partners": "CAMARADERÍA",
        "affiliate": "Afiliado",
        "whitelabel": "etiqueta blanca",
        "introducing": "Partner introductorio",
        "findus": "Encuéntranos en",
        "home": "Casa",
        "dashboard": "Tablero",
        "contact": "Contacto"
    },
    "features": {
        "title": "Publicidad en Google <br\/> más fácil que nunca",
        "subtitle": "",
        "sec1": {
            "title": "¿Por qué anunciarse en Google?",
            "col1": {
                "subtitle": "Vence a tu competencia",
                "desc": "Cada día que las personas buscan su marca o productos, Google le brinda la posibilidad de poner su negocio frente a su competencia."
            },
            "col2": {
                "subtitle": "Llegar a clientes ideales",
                "desc": "Google es una de las mejores plataformas publicitarias para apuntar a los usuarios correctos en el momento adecuado en todos los niveles de interacción."
            },
            "col3": {
                "subtitle": "Haz crecer tus ventas",
                "desc": "El tráfico a su sitio web aumentará enormemente a través de Google con visitantes que pueden convertirse fácilmente en clientes, aumentando sus ventas y su negocio en línea."
            }
        },
        "sec2": {
            "title": "Domina tu crecimiento en línea",
            "cta": "PROBAR GRATIS"
        }
    },
    "getstarted": {
        "title": "Comience con 3 sencillos pasos",
        "col1": {
            "subtitle": "1. Descargar",
            "desc": ""
        },
        "col2": {
            "subtitle": "2. Activar",
            "desc": ""
        },
        "col3": {
            "subtitle": "3. Track",
            "desc": ""
        }
    },
    "dashboard": {
        "title": "Tablero",
        "domainsettings": {
            "title": "AJUSTES DE DOMINIO",
            "subtitle1": "Agregar nuevo dominio",
            "input1": "Tu nuevo dominio",
            "cta1": "Añadir",
            "subtitle2": "Tus dominios actuales",
            "currentdomains1": "Dominio",
            "currentdomains2": "Clave API"
        },
        "subscription": {
            "title": "SUSCRIPCIÓN",
            "subtitle1": "Equilibrar",
            "remaining": "restante"
        },
        "tab2": {
            "desc1": "Actualmente estás utilizando nuestro",
            "desc2": "suscripción",
            "cta": "SUSCRIPCIÓN DE INTERRUPTOR"
        },
        "billing": {
            "title": "Detalles de facturación",
            "input": {
                "name": "Nombre de facturación",
                "vat": "Número de valor agregado",
                "address": "Habla a",
                "city": "Ciudad",
                "zip": "código postal",
                "country": "País"
            }
        },
        "user": {
            "title": "AJUSTES DE USUARIO",
            "subtitle": "Sus detalles de inicio de sesión",
            "lastlogin": "Último acceso:"
        }
    },
    "cta": {
        "update": "ACTUALIZAR"
    },
    "input": {
        "email": "Dirección de correo electrónico",
        "password": "Contraseña",
        "passwordrepeat": "repite la contraseña"
    },
    "partners": {
        "affiliate": {
            "subtitle": "Bonos y promociones exclusivas <br> para impulsar a los afiliados",
            "substitle2": "¡Una fusión de herramientas de marketing de afiliación y planes de pago para aumentar sus ganancias!"
        },
        "affilieate": {
            "description": ""
        },
        "whitelabel": {
            "title": "Asociación de marca blanca"
        },
        "introducing": {
            "subtitle": "",
            "desc2": "Para obtener más información, póngase en contacto con nuestro equipo especializado en:"
        }
    },
    "url": {
        "features": "caracteristicas",
        "pricing": "fijación de precios",
        "partnership": {
            "affiliate": "asociación \/ afiliado",
            "whitelabel": "asociación \/ etiqueta blanca",
            "introductory": "asociación \/ introductoria"
        },
        "contact": "contacto",
        "login": "iniciar sesión"
    },
    "b2c": {
        "main": {
            "title": "<b> FEED <\/b> .MANAGEMENT"
        },
        "cta1": {
            "title": "PROMOCIÓN DE AVES ANTICIPADAS",
            "description": "Por un tiempo limitado, cada usuario anticipado recibe un crédito de bienvenida de $ 1000 para probar el paquete Premium de Feed Studio. <br\/> <br\/> Sin período de prueba, no se necesita tarjeta de crédito.",
            "button": "OBTENGA CRÉDITO DE $ 1,000"
        },
        "sec1": {
            "title": "¿Cómo puedes usar feed.studio?",
            "col1": {
                "title": "regístrate y danos <br\/> tu feed sin procesar",
                "desc": "Danos tu información de contacto y feed para configurar tu cuenta"
            },
            "col2": {
                "title": "nuestros gerentes configuraron <br\/> su feed de exportación",
                "desc": "a partir de su feed sin procesar creamos su feed de exportación"
            },
            "col3": {
                "title": "Disfruta (los resultados)",
                "desc": "Siéntese sabiendo que nuestros expertos están trabajando arduamente para brindarle la mejor alimentación que necesita."
            }
        },
        "sec2": {
            "title": "Regístrese para el probador de reserva anticipada",
            "infoList": {
                "item1": "Sin límite de tiempo para crédito de bienvenida",
                "item2": "no le cobramos \/ facturamos al final del período de bienvenida",
                "item3": "Sin producto o límite de exportación",
                "item4": "Servicio gestionado: no tiene que aprender la plataforma",
                "item5": "Puedes eliminar tu cuenta en cualquier momento",
                "item6": "Puede solicitar formatos de exportación personalizados."
            },
            "form": {
                "name": "Nombre",
                "email": "Correo electrónico",
                "phone": "Teléfono",
                "feedUrl": "URL para el canal",
                "policyLabel": "Acepto los términos y condiciones",
                "marketingLabel": "Me gustaría suscribirme al boletín Feed.Studio",
                "button": "obtener acceso temprano",
                "feedType": ""
            }
        },
        "meta": {
            "title": "Generador de feeds de productos XML, Google Shopping y Facebook Feed",
            "desc": {
                "short": "Crea y optimiza tus feeds de Google y Facebook fácilmente.",
                "long": "Google Shopping Feed, Product Feed Optimzation."
            }
        }
    },
    "b2b": {
        "main": {
            "title": "<b> FEED <\/b> .PARTNERS",
            "description": "Feed.Studio diseñado para profesionales y agencias de comercio electrónico. Estamos buscando socios regionales para construir un futuro exitoso. <\/br> Aumente sus ingresos, venda más con Feed.Studio!",
            "button": "VENDER MÁS"
        },
        "sec1": {
            "title": "¿Por qué trabajar con nosotros?",
            "col1": {
                "title": "vender más",
                "desc": "vender más"
            },
            "col2": {
                "title": "vencer a sus <\/br> competidores",
                "desc": "sé el primero"
            },
            "col3": {
                "title": "satisfacer a sus <\/br> clientes",
                "desc": "control de calidad"
            },
            "col4": {
                "title": "ahorrar tiempo",
                "desc": "con nuestro servicio dañado"
            }
        },
        "secPartner": {
            "title": "asociaciones",
            "header": {
                "affiliate": "Afiliado",
                "gold": "Oro",
                "exclusive": "Exclusivo",
                "custom": "Personalizado"
            },
            "requirements": {
                "title": "Requisitos",
                "newClient": "nuevos clientes \/ año",
                "certifiedusers": "usuario interno activo certificado"
            },
            "recognition": {
                "title": "Reconocimiento",
                "visible": "visible en feed.studio",
                "trademark": "derechos de uso de la marca registrada \"feed studio\""
            },
            "training": {
                "title": "Beneficios de entrenamiento",
                "biweekly": "reuniones quincenales",
                "salesSupport": "soporte de ventas y orientación"
            },
            "marketing": {
                "title": "beneficios de marketing",
                "brand": "marca en campañas locales",
                "customerStories": "historias de éxito de clientes"
            },
            "sales": {
                "title": "beneficios de ventas",
                "materials": "materiales de venta",
                "accountManagers": "gerente de cuenta dedicado",
                "leads": "leads locales",
                "affiliateBonus": "BONIFICACIÓN A CLIENTES AFILIADOS",
                "feedStudioBonus": "bonificación en Feed Studio"
            },
            "contactUs": "Contáctenos",
            "signUp": "Regístrate"
        },
        "sec3": {
            "title": "¿Cómo ser nuestro socio?",
            "col1": {
                "title": "Regístrate",
                "desc": ""
            },
            "col2": {
                "title": "participa en nuestros entrenamientos de ventas y plataformas",
                "desc": ""
            },
            "col3": {
                "title": "gestionar nuestros clientes potenciales y clientes",
                "desc": ""
            }
        },
        "sec4": {
            "title": "Regístrese para el PROGRAMA DE SOCIOS",
            "infoList": {
                "item1": "acceso a Feed Studio para discutir estrategias, ventas y problemas de servicios",
                "item2": "visibilidad y reconocimiento al figurar como socio oficial en la página de socios de Feed Studio",
                "item3": "acceso al panel de ventas"
            },
            "form": {
                "plan": {
                    "affiliate": "Afiliado",
                    "gold": "Oro",
                    "custom": "Personalizado"
                },
                "name": "Nombre",
                "email": "Correo electrónico",
                "phone": "Teléfono",
                "message": "Mensaje",
                "policyLabel": "Acepto los términos y condiciones",
                "marketingLabel": "Me gustaría suscribirme al boletín Feed.Studio",
                "button": "Obtenga acceso temprano"
            }
        }
    },
    "registration": {
        "meta": {
            "title": "Regístrese gratis para alimentar Studio",
            "desc": {
                "short": "Regístrese y obtenga un crédito de $ 1000 para Feed Studio.",
                "long": "Regístrese y obtenga un crédito de bienvenida de $ 1000 para usar Feed Studio."
            }
        }
    },
    "earlybirdreg": {
        "form": {
            "infoList": {
                "desc1": "",
                "desc2": "",
                "item1": "",
                "item2": "",
                "item3": "",
                "item4": "",
                "item5": "",
                "item6": "",
                "item7": ""
            }
        }
    },
    "login": {
        "title": "",
        "subtitle": "",
        "subtitle2": "",
        "googleButton": ""
    }
};