import ApiService from './ApiService';

export default class UserService extends ApiService {

    async postSettings(userId, settings) {
        return this.client.post(`/users/${userId}/settings`, {
            firstName: settings.firstname,
            lastName: settings.lastname,
            phone: settings.phone
        })
    }

    async getSettings(userId) {
        return this.client.get(`/users/${userId}/settings`)
    }

    async inviteClient(userId, customerId) {
        return this.client.get("/users/"+userId+"/adwordsAccounts/"+customerId+"/sendInvitation")
    }

    async getUserData(userId) {
        return this.client.get("/users/"+userId)
    }

    async getProjects(userId) {
        return this.client.get("/users/"+userId+"/projects")
    }

    async setGoal(userId, goalId) {
        return this.client.put("/users/"+userId+"/settings", {
            goal: goalId
        })
    }
}