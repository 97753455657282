
const countries = [
    {
        id: 0,
        name: "Hungary"
    },
    {
        id: 1,
        name: "United Kingdom"
    },
    {
        id: 2,
        name: "Finland"
    },
    {
        id: 3,
        name: "Sweden"
    }
]

const mockData = {
    users: [
        {
            id: 0,
            username: "test@eazy.digital",
            password: "test",
            token: "df2qvbwptl7gh59uuyybt",
            defaultProject: 0,
            firstLogin: false
        }
    ],
    projects: [
        {
            id: 0,
            name: "User Project: test@eazy.digital",
            apiKey: {
                id: 0,
                domain: "test1.eazy.digital",
                key: "0000000000"
            },
            subscription: {
                balance: 100,
                billingAccount: {
                    name: "Eazy Digital Kft.",
                    vatNumber: "00000000-0-00",
                    address: "Széll Kálmán tér 11.",
                    city: "Budapest",
                    zip: 1024,
                    country: countries[0].id
                },
                invoices: [
                    {
                        id: 0,
                        date: "2018-01-01",
                        type: "Balance Top-up",
                        amount: 2000
                    },
                    {
                        id: 1,
                        date: "2018-01-01",
                        type: "Subscription Fee",
                        amount: 300
                    },
                    {
                        id: 2,
                        date: "2018-01-01",
                        type: "Balance Top-up",
                        amount: 5000
                    }
                ]
            },
            hasAdsAccount: false
        }
    ],
    adsAccounts: [
        {
            id: 9876543210,
            customerId: 9876543210,
            name: "Test account",
            shouldError: false
        },
        {
            id: 1234567890,
            customerId: 1234567890,
            name: "Test account 2",
            shouldError: true
        }
    ],
    campaigns: [
        {
            id: 0,
            name: "Test campaign",
            googleId: 123456,
            status: "ENABLED"
        },
        {
            id: 1,
            name: "Test campaign 2",
            googleId: 123456,
            status: "PAUSED"
        }
    ],
    countries: countries
}

export default mockData