export default {
    "home": {
        "sec1": {
            "title": "Zvyšte své příjmy. Bez námahy.",
            "cta": "Ke stažení zdarma",
            "col1": {
                "title": "zvýšit své <br\/> příjmy",
                "desc": "optimalizujte strukturu zdroje a zvyšte své příjmy o 5-30%"
            },
            "col2": {
                "title": "otevřít nové prodejní kanály <br\/>",
                "desc": "prodávat více s novými prodejními kanály"
            },
            "col3": {
                "title": "vyzkoušejte zdarma bez <br\/> časového limitu",
                "desc": "Naše metoda stanovení ceny za běh vám poskytuje nejflexibilnější řešení na trhu"
            },
            "col4": {
                "title": "Ušetřete svůj <br\/> čas",
                "desc": "naši konzultanti vytvářejí a optimalizují zdroje vašich produktů"
            }
        },
        "sec2": {
            "title": "import a export s lehkostí",
            "col1": {
                "title": "správa zdrojů dodavatele <br\/>",
                "desc": "Feed Studio kombinuje různé zdroje produktů do jednoho importovatelného zdroje"
            },
            "col2": {
                "title": "používat více než 100 <br\/> dalších formátů",
                "desc": "Potřebujete různé popisy Nákupů Google a Instangramu? Není problem"
            },
            "col3": {
                "title": "optimalizujte data produktu <br\/> podle platformy",
                "desc": "Potřebujete trochu jiný nákupní popis Google než Instagram shop? Žádný problém"
            },
            "col4": {
                "title": "historie změn",
                "desc": "Proč inzerovat špatnou cenu? Kdy selhala aktualizace produktu? Známe odpověď!"
            },
            "col5": {
                "title": "SEO optimalizace",
                "desc": "Automatizované vytváření a optimalizace metaznaček SEO."
            },
            "col6": {
                "title": "řízená služba",
                "desc": "Feed Studio kombinuje různé zdroje produktů do jednoho importovatelného zdroje"
            }
        },
        "sec3": {
            "title": "Chcete růst vašeho podnikání bez boje?"
        },
        "sec4": {
            "item2": {
                "subtitle": "Správa krmiv"
            },
            "item4": {
                "subtitle": "Vyhledat konzoli"
            }
        },
        "sec5": {
            "title": "Krásně automatizované kampaně s reklamami Google",
            "col1": {
                "title": "Reklamy na značku",
                "desc": "Věděli jste, že 40% vašich zákazníků neklikne na váš web na stránce s výsledky vyhledávání? Oslovte je pomocí značkových kampaní!"
            },
            "col2": {
                "title": "Dynamické reklamy ve vyhledávání"
            },
            "col3": {
                "title": "Reklamy v Nákupech"
            },
            "col4": {
                "title": "Grafické reklamy",
                "desc": "Oslovte své cílové publikum v reklamní síti Google pomocí responzivního remarketingu, dynamického remarketingu a inteligentních reklam."
            }
        },
        "sec6": {
            "title": "Jste připraveni automatizovat vaši reklamu?",
            "cta": "Získat zdarma"
        },
        "sec7": {
            "title": "",
            "subtitle": "Komplexní služby i ve volném plánu"
        },
        "premier-partner-desc": "Společnost Google se od nás liší jako agentura s nejvyšším výkonem, takže si můžete být jisti, že vaše strategie reklam Google je v dobrých rukou.",
        "sec8": {
            "title": "",
            "cta": "KE STAŽENÍ ZDARMA"
        },
        "main": {
            "title": "<b> KRMIVO <\/b> .ME.BABY",
            "description": "Požádejte o účet s časnými ptáky a vyzkoušejte nejnovější software Google Feed <br\/> správce určený pro profesionály a agentury. <br\/> Na omezenou dobu získáte uvítací kredit <s> 300 <\/s> 1 000 $.",
            "button": "Získejte kredit 1 000 $"
        },
        "cta1": {
            "title": "DOČASNĚ PODPORA",
            "description": "naši první uživatelé získají uvítací kredit ve výši 1 000 $ na vyzkoušení Feed Studio",
            "button": "ZÍSKEJTE KREDIT 1 000 USD",
            "subtitle": "POTŘEBA ŽÁDNÉ KREDITNÍ KARTY!"
        },
        "secFeeds": {
            "title": "co byste měli opravit ve svých zdrojích?",
            "header": {
                "channel": "kanál",
                "export": "vývozní",
                "description": "popis",
                "result": "výsledek"
            },
            "google": {
                "title": "Google",
                "feeds": {
                    "merchant": {
                        "name": "Zdroj Merchant Center",
                        "desc": "prodávat produkty v Nákupech Google",
                        "result": "zvýšit výnosy optimalizovanou strukturou pole"
                    },
                    "promotions": {
                        "name": "Propagační zdroje",
                        "desc": "zobrazte propagační akce svému zákazníkovi",
                        "result": "zvýšit míru prokliku a zvýšit počet konverzí"
                    },
                    "localInventory": {
                        "name": "Zdroj reklam pro místní inventář",
                        "desc": "zobrazte své produkty v Mapách Google",
                        "result": "podpořte příjmy offline, získejte nové zákazníky do svého obchodu"
                    },
                    "productRatings": {
                        "name": "Zdroj hodnocení produktů",
                        "desc": "zpřístupněte uživatelům Google hodnocení produktů a recenze",
                        "result": "vyšší míra prokliku"
                    },
                    "dynamicRemarketing": {
                        "name": "Dynamické remarketingové zdroje",
                        "desc": "zobrazovat reklamy lidem, kteří dříve navštívili váš web",
                        "result": "zvýšit příjmy"
                    },
                    "page": {
                        "name": "Zdroj stránky",
                        "desc": "Zdroj stránky pro dynamické reklamy ve vyhledávání",
                        "result": "zvýšit výkon kampaně ve Vyhledávací síti"
                    },
                    "sitemap": {
                        "name": "Zdroj souboru Sitemap",
                        "desc": "Zdroj URL pro optimalizaci vyhledávače",
                        "result": "zvyšte viditelnost ve Vyhledávání Google"
                    },
                    "manufacturer": {
                        "name": "Krmivo pro výrobce",
                        "desc": "poskytovat společnosti Google lepší a přesnější informace o vašich produktech",
                        "result": "získejte komplexní analýzu a vylepšete svou přítomnost na Googlu"
                    }
                }
            },
            "facebook": {
                "title": "Facebook",
                "feeds": {
                    "dynamicAds": {
                        "name": "Zdroj dynamických reklam",
                        "desc": "zobrazovat reklamy lidem, kteří dříve navštívili váš web",
                        "result": "zvýšit příjmy"
                    },
                    "pageShop": {
                        "name": "Page Shop feed",
                        "desc": "zobrazovat a prodávat produkty lidem na Facebooku",
                        "result": "zvýšit příjmy"
                    },
                    "marketplace": {
                        "name": "Krmivo pro tržiště",
                        "desc": "zobrazovat a prodávat produkty lidem na Facebooku",
                        "result": "zvýšit příjmy"
                    }
                }
            },
            "instagram": {
                "title": "instagram",
                "feeds": {
                    "shopping": {
                        "name": "Nákupní zdroj",
                        "desc": "zobrazovat a prodávat produkty lidem na Instagramu",
                        "result": "zvýšit příjmy"
                    }
                }
            },
            "amazon": {
                "title": "Amazonka",
                "feeds": {
                    "product": {
                        "name": "Krmení produktu",
                        "desc": "ukazovat a prodávat produkty lidem na Amazonu",
                        "result": "zvýšit příjmy"
                    }
                }
            },
            "ebay": {
                "title": "ebay",
                "feeds": {
                    "product": {
                        "name": "Krmení produktu",
                        "desc": "ukazovat a prodávat produkty lidem na Ebay",
                        "result": "zvýšit příjmy"
                    }
                }
            },
            "criteo": {
                "title": "criteo",
                "feeds": {
                    "product": {
                        "name": "Krmení produktu",
                        "desc": "prodávat produkty online",
                        "result": "zvýšit příjmy"
                    }
                }
            }
        },
        "cta2": {
            "title": "REZERVUJTE VAŠE PRODEJE S FEED.STUDIO",
            "button": "ZÍSKEJTE KREDIT 1 000 USD",
            "subtitle": "POTŘEBA ŽÁDNÉ KREDITNÍ KARTY!"
        },
        "meta": {
            "title": "Studio Studio »Nástroj Správce zdrojů XML - Generátor zdroje produktů XML - Nákup Google",
            "desc": {
                "short": "Nástroj pro generování zdroje nákupů Google.",
                "long": "Generátor zdroje nákupů Google"
            }
        }
    },
    "pricing": {
        "plugin": {
            "col1": {
                "header": "VLASTNOSTI \/ PLÁNY",
                "row1": "Neomezené produkty",
                "row2": "Reklamy Google - informační kanál v Nákupech",
                "row3": "Reklamy Google - dynamický zdroj grafické reklamy",
                "row4": "Google Ads - DSA Pagefeed",
                "row5": "Informační kanál na Facebooku",
                "row6": "Arukereso.hu Krmení produktu",
                "row7": "Krmivo produktu Criteo",
                "row8": "Domácí produkt RTB",
                "row9": "Automatizace reklam Google",
                "row10": "Dovoz a vývoz na zakázku",
                "row11": "Webináře",
                "row12": "Dedicated Account Manager",
                "row13": "Vítejte voucher",
                "row14": "Měsíční poplatek",
                "row15": "Cena za 10 000 operací"
            },
            "agency": "Všechny vaše peníze zpět, pokud nedosáhneme očekávaných výsledků do 3 měsíců.",
            "col2": {
                "header": "ŽIVOT ZDARMA",
                "row6": "Týdně",
                "cta": "Registrace",
                "row1": "Vestavěná řídicí deska",
                "row14": "9 $",
                "row15": "na"
            },
            "col3": {
                "header": "POJISTNÉ",
                "row6": "Denně",
                "cta": "Registrace",
                "row1": "Prémiová řídicí deska",
                "row13": "300 $",
                "row14": "9 $",
                "row15": "$ 1"
            },
            "col4": {
                "row6": "Reálný čas",
                "cta": "Registrace",
                "row1": "VIP dashboard",
                "row10": "Soukromé webináře",
                "header": "Early Bird Premium",
                "row13": "1000 $",
                "row14": "9 $",
                "row15": "$ 1"
            }
        },
        "agency": {
            "allin": {
                "li6": "Chatbot",
                "li9": "Záruka vrácení peněz*"
            }
        },
        "title": "Plány, které vyhovují vašemu celku \n marketingový rozpočet",
        "sec2": {
            "title": "Dominujte svému online růstu",
            "cta": "ZÍSKAT ZDARMA"
        },
        "sec6": {
            "title": "Pokud potřebujete technickou podporu, navštivte náš panel podpory.",
            "cta": "PODPĚRA, PODPORA"
        },
        "header": {
            "title": "CENY",
            "sub": "platit, jak jdete"
        }
    },
    "testimonials": {
        "title": "Co říkají naši uživatelé",
        "1": {
            "desc": "Na reklamních kampaních Google spolupracujeme již téměř rok. Od agentury jsme vždy zažili rychlou a profesionální práci, jsou velmi kompetentní, spolupracující a snaží se plně vyhovět našim potřebám. Kromě toho, že přinášejí inovativní řešení, jsou dostatečně klidní, aby mohli důkladně vysvětlit řešení složitých problémů a výsledků táboření.",
            "name": "Adam Dallos",
            "pos": "PwC, asistent manažera, marketing, komunikační rozvoj podnikání"
        }
    },
    "footer": {
        "description": ""
    },
    "menu": {
        "navigation": "NAVIGACE",
        "signin": "Přihlásit se",
        "features": "Funkce",
        "pricing": "Ceny",
        "blog": "Blog",
        "terms-conditions": "Všeobecné obchodní podmínky",
        "partners": "PARTNERSTVÍ",
        "affiliate": "Přidružený",
        "whitelabel": "Bílý štítek",
        "introducing": "Představujeme partnera",
        "findus": "Naleznete nás na",
        "home": "Domov",
        "dashboard": "Přístrojová deska",
        "contact": "Kontakt"
    },
    "features": {
        "title": "Inzerce na Googlu <br\/> byla snazší než kdy jindy",
        "subtitle": "",
        "sec1": {
            "title": "Proč inzerovat na Googlu?",
            "col1": {
                "subtitle": "Porazte svou konkurenci",
                "desc": "Každý den lidé hledají vaši značku nebo produkty, Google vám dává možnost dostat svou firmu před svou konkurenci."
            },
            "col2": {
                "subtitle": "Oslovte ideální zákazníky",
                "desc": "Google je jednou z nejlepších reklamních platforem pro cílení správných uživatelů ve správný čas na všech úrovních interakce."
            },
            "col3": {
                "subtitle": "Růst prodeje",
                "desc": "Návštěvnost vašich webových stránek se prostřednictvím Googlu výrazně zvýší s návštěvníky, kteří se mohou snadno proměnit v zákazníky, což povede k růstu vašeho prodeje a online obchodu."
            }
        },
        "sec2": {
            "title": "Dominujte svému online růstu",
            "cta": "ZKUSTE ZADARMO"
        }
    },
    "getstarted": {
        "title": "Začněte se 3 snadnými kroky",
        "col1": {
            "subtitle": "1. Stáhnout",
            "desc": ""
        },
        "col2": {
            "subtitle": "2. Aktivujte",
            "desc": ""
        },
        "col3": {
            "subtitle": "3. Sledovat",
            "desc": ""
        }
    },
    "dashboard": {
        "title": "Přístrojová deska",
        "domainsettings": {
            "title": "NASTAVENÍ DOMÁNY",
            "subtitle1": "Přidat novou doménu",
            "input1": "Vaše nová doména",
            "cta1": "Přidat",
            "subtitle2": "Vaše současné domény",
            "currentdomains1": "Doména",
            "currentdomains2": "Klíč API"
        },
        "subscription": {
            "title": "PŘEDPLATNÉ",
            "subtitle1": "Zůstatek",
            "remaining": "zbývající"
        },
        "tab2": {
            "desc1": "Momentálně používáte náš",
            "desc2": "předplatné",
            "cta": "PŘEDLOŽENÍ PŘEPÍNAČE"
        },
        "billing": {
            "title": "Fakturační údaje",
            "input": {
                "name": "Fakturační jméno",
                "vat": "DIČ",
                "address": "Adresa",
                "city": "Město",
                "zip": "PSČ",
                "country": "Země"
            }
        },
        "user": {
            "title": "UŽIVATELSKÉ NASTAVENÍ",
            "subtitle": "Vaše přihlašovací údaje",
            "lastlogin": "Poslední přihlášení:"
        }
    },
    "cta": {
        "update": "AKTUALIZACE"
    },
    "input": {
        "email": "Emailová adresa",
        "password": "Heslo",
        "passwordrepeat": "Zopakovat heslo"
    },
    "partners": {
        "affiliate": {
            "subtitle": "Exkluzivní bonusy a propagační akce <br> pro posílení přidružených společností",
            "substitle2": "Spojení nástrojů affiliate marketingu a platebních plánů pro zvýšení vašich zisků!"
        },
        "affilieate": {
            "description": ""
        },
        "whitelabel": {
            "title": "Partnerství White Label"
        },
        "introducing": {
            "subtitle": "",
            "desc2": "Pro více informací kontaktujte náš specializovaný tým na:"
        }
    },
    "url": {
        "features": "funkce",
        "pricing": "ceny",
        "partnership": {
            "affiliate": "účastnictví \/ přidružená společnost",
            "whitelabel": "patnership \/ whitelabel",
            "introductory": "patership \/ úvodní"
        },
        "contact": "Kontakt",
        "login": "přihlásit se"
    },
    "b2c": {
        "main": {
            "title": "<b> KRMIVO <\/b> .ŘÍZENÍ"
        },
        "cta1": {
            "title": "DOČASNĚ PODPORA",
            "description": "Po omezenou dobu získává každý ranný pták uvítací kredit ve výši 1 000 $ za testování balíčku Feed Studio Premium. <br\/> <br\/> Žádné zkušební období, není potřeba žádná kreditní karta.",
            "button": "ZÍSKEJTE KREDIT 1 000 USD"
        },
        "sec1": {
            "title": "jak můžete použít feed.studio",
            "col1": {
                "title": "zaregistrujte se a dejte nám <br\/> své suroviny",
                "desc": "dejte nám svůj kontakt a údaje o zdroji pro nastavení vašeho účtu"
            },
            "col2": {
                "title": "naši manažeři nastavili <br\/> váš exportní kanál",
                "desc": "z vašeho surového zdroje vytvoříme exportní zdroj"
            },
            "col3": {
                "title": "Užijte si (výsledky)",
                "desc": "Posaďte se a věděl, že naši odborníci tvrdě pracují na tom, aby vám poskytli to nejlepší krmivo, které potřebujete."
            }
        },
        "sec2": {
            "title": "Přihlaste se k odběru testerů pro první ptáky",
            "infoList": {
                "item1": "Žádný časový limit pro uvítací kredit",
                "item2": "na konci uvítacího období vám nebudeme účtovat ani fakturovat",
                "item3": "Žádný produkt ani vývozní limit",
                "item4": "Spravovaná služba - nemusíte se učit platformu",
                "item5": "Svůj účet můžete kdykoli smazat",
                "item6": "Můžete požádat o vlastní formáty exportu"
            },
            "form": {
                "name": "název",
                "email": "E-mailem",
                "phone": "Telefon",
                "feedUrl": "Adresa URL zdroje",
                "policyLabel": "Souhlasím s pravidly a podmínkami",
                "marketingLabel": "Chtěl bych se přihlásit k odběru zpravodaje Feed.Studio",
                "button": "získat včasný přístup",
                "feedType": ""
            }
        },
        "meta": {
            "title": "Generátor zdroje produktů XML, Nákupy Google a zdroj RSS",
            "desc": {
                "short": "Vytvářejte a optimalizujte své kanály Google a Facebook snadno.",
                "long": "Google Shopping Feed, Optimalizace zdroje produktů."
            }
        }
    },
    "b2b": {
        "main": {
            "title": "<b> KRMIVO <\/b> .PARTNERS",
            "description": "Feed.Studio určené pro obchodníky a agentury v oblasti elektronického obchodování. Hledáme regionální partnery, kteří budou budovat úspěšnou budoucnost. <\/br> Zvyšte své příjmy a prodejte více pomocí Feed.Studio!",
            "button": "PRODEJ VÍCE"
        },
        "sec1": {
            "title": "Proč s námi pracovat?",
            "col1": {
                "title": "prodat více",
                "desc": "prodat více"
            },
            "col2": {
                "title": "porazit <\/br> konkurenty",
                "desc": "být první"
            },
            "col3": {
                "title": "uspokojit své <\/br> klienty",
                "desc": "kontrola kvality"
            },
            "col4": {
                "title": "ušetřit čas",
                "desc": "s naší chybnou službou"
            }
        },
        "secPartner": {
            "title": "partnerství",
            "header": {
                "affiliate": "Přidružený",
                "gold": "Zlato",
                "exclusive": "Výhradní",
                "custom": "Zvyk"
            },
            "requirements": {
                "title": "Požadavky",
                "newClient": "noví klienti \/ rok",
                "certifiedusers": "certifikovaný aktivní interní uživatel"
            },
            "recognition": {
                "title": "Uznání",
                "visible": "viditelné na feed.studio",
                "trademark": "práva na používání ochranné známky „feed studio“"
            },
            "training": {
                "title": "Výhody školení",
                "biweekly": "dvoutýdenní schůzky",
                "salesSupport": "podpora prodeje a poradenství"
            },
            "marketing": {
                "title": "marketingové výhody",
                "brand": "značky v místních kampaních",
                "customerStories": "příběhy o úspěchu zákazníka"
            },
            "sales": {
                "title": "prodejní výhody",
                "materials": "prodej materiálů",
                "accountManagers": "specializovaný správce účtu",
                "leads": "místní vedení",
                "affiliateBonus": "BONUS NA OSOBITNÝCH KLIENTECH",
                "feedStudioBonus": "bonus na Feed Studio"
            },
            "contactUs": "Kontaktujte nás",
            "signUp": "Přihlásit se"
        },
        "sec3": {
            "title": "jak být naším partnerem?",
            "col1": {
                "title": "Přihlásit se",
                "desc": ""
            },
            "col2": {
                "title": "účastněte se našich školení o prodeji a platformách",
                "desc": ""
            },
            "col3": {
                "title": "řídit naše potenciální zákazníky a klienty",
                "desc": ""
            }
        },
        "sec4": {
            "title": "Zaregistrujte se do PARTNERSKÉHO PROGRAMU",
            "infoList": {
                "item1": "přístup k Feed Studio diskutovat o strategiích, prodejích a problémech se službami",
                "item2": "viditelnost a uznání tím, že jsou uvedeny jako oficiální partneři na stránce Feed Studio partners",
                "item3": "přístup k prodejnímu panelu"
            },
            "form": {
                "plan": {
                    "affiliate": "Přidružený",
                    "gold": "Zlato",
                    "custom": "Zvyk"
                },
                "name": "název",
                "email": "E-mailem",
                "phone": "Telefon",
                "message": "Zpráva",
                "policyLabel": "Souhlasím s pravidly a podmínkami",
                "marketingLabel": "Chtěl bych se přihlásit k odběru zpravodaje Feed.Studio",
                "button": "Získejte včasný přístup"
            }
        }
    },
    "registration": {
        "meta": {
            "title": "Zaregistrujte se zdarma ke krmení Studio",
            "desc": {
                "short": "Zaregistrujte se a získejte kredit 1 000 $ pro Feed Studio.",
                "long": "Zaregistrujte se a získejte uvítací kredit ve výši 1 000 $, abyste mohli používat Feed Studio."
            }
        }
    },
    "earlybirdreg": {
        "form": {
            "infoList": {
                "desc1": "",
                "desc2": "",
                "item1": "",
                "item2": "",
                "item3": "",
                "item4": "",
                "item5": "",
                "item6": "",
                "item7": ""
            }
        }
    },
    "login": {
        "title": "",
        "subtitle": "",
        "subtitle2": "",
        "googleButton": ""
    }
};