export default {
    "home": {
        "sec1": {
            "title": "Збільшити дохід. Без зусиль.",
            "cta": "Завантажити безкоштовно",
            "col1": {
                "title": "збільшити ваші <br\/> доходи",
                "desc": "оптимізуйте структуру вашої стрічки та збільште дохід на 5-30%"
            },
            "col2": {
                "title": "відкрити нові канали продажів <br\/>",
                "desc": "продавати більше за допомогою нових каналів збуту"
            },
            "col3": {
                "title": "спробуйте безкоштовно без <br\/> часового обмеження",
                "desc": "наш метод ціноутворення за рахунок оплати дає вам найбільш гнучкі рішення на ринку"
            },
            "col4": {
                "title": "зберегти ваш <br\/> час",
                "desc": "наші консультанти будують та оптимізують ваші канали продуктів"
            }
        },
        "sec2": {
            "title": "імпорт та експорт легко",
            "col1": {
                "title": "управління кормом постачальників <br\/>",
                "desc": "Студія Feed поєднує різні канали продуктів в один важливий канал"
            },
            "col2": {
                "title": "використовувати більше 100 <br\/> інших форматів",
                "desc": "Потрібні різні описи Google Shopping і Instangram? Не проблема"
            },
            "col3": {
                "title": "оптимізуйте <br\/> дані вашого продукту за допомогою платформи",
                "desc": "Вам потрібен дещо інший опис покупок Google, ніж магазин Instagram? Нема проблем"
            },
            "col4": {
                "title": "змінити історію",
                "desc": "Чому рекламують неправильну ціну? Коли не вдалося оновити продукт? Ми знаємо відповідь!"
            },
            "col5": {
                "title": "SEO оптимізація",
                "desc": "Автоматизоване створення та оптимізація метаметок SEO."
            },
            "col6": {
                "title": "керована послуга",
                "desc": "Студія Feed поєднує різні канали продуктів в один важливий канал"
            }
        },
        "sec3": {
            "title": "Хочете розвивати свій бізнес без боротьби?"
        },
        "sec4": {
            "item2": {
                "subtitle": "Управління подачею"
            },
            "item4": {
                "subtitle": "Пошук консолі"
            }
        },
        "sec5": {
            "title": "Чудово автоматизовані кампанії Google Ads",
            "col1": {
                "title": "Оголошення бренда",
                "desc": "Чи знаєте ви, що 40% ваших клієнтів не натискають на ваш сайт на сторінці результатів пошуку? Досягніть їх за допомогою бренд-кампаній!"
            },
            "col2": {
                "title": "Динамічні оголошення в пошуковій мережі"
            },
            "col3": {
                "title": "Покупки оголошень"
            },
            "col4": {
                "title": "Медійна реклама",
                "desc": "Досягніть цільової аудиторії в Медійній мережі Google за допомогою чуйного ремаркетингу, динамічного ремаркетингу та розумних оголошень."
            }
        },
        "sec6": {
            "title": "Готові до автоматизації вашої реклами?",
            "cta": "Звільнятися"
        },
        "sec7": {
            "title": "",
            "subtitle": "Комплексні послуги навіть у вільному плані"
        },
        "premier-partner-desc": "Google нас відрізняє як агентство з найвищими показниками, щоб ви могли бути впевнені, що ваша стратегія Google Ads в хороших руках.",
        "sec8": {
            "title": "",
            "cta": "ЗАВАНТАЖИТИ БЕЗКОШТОВНО"
        },
        "main": {
            "title": "<b> FEED <\/b> .ME.BABY",
            "description": "Попросіть обліковий запис ранньої птиці, щоб випробувати останню програму менеджера google <br\/> програм та професіоналів та агентств. <br\/> Протягом обмеженого часу ви отримаєте <s> $ 300 <\/s> 1000 доларів США привітання.",
            "button": "Отримайте кредит у розмірі 1000 доларів"
        },
        "cta1": {
            "title": "ПРОМОЦІЯ РАННІХ ПТАРКІВ",
            "description": "наші перші користувачі отримують 1000 доларів привітання для тестування Feed Studio",
            "button": "Отримайте $ 1000 КРЕДИТів",
            "subtitle": "КРЕДИТНА КАРТА НЕ ПОТРІБНА!"
        },
        "secFeeds": {
            "title": "що слід зафіксувати у своїх каналах?",
            "header": {
                "channel": "канал",
                "export": "експорт",
                "description": "опис",
                "result": "результат"
            },
            "google": {
                "title": "google",
                "feeds": {
                    "merchant": {
                        "name": "Канал Merchant Center",
                        "desc": "продавати продукти в Google Shopping",
                        "result": "збільшити дохід за рахунок оптимізованої структури поля"
                    },
                    "promotions": {
                        "name": "Канал акцій",
                        "desc": "показувати акції своєму клієнту",
                        "result": "підвищити рейтинг кліків і збільшити конверсії"
                    },
                    "localInventory": {
                        "name": "Канал оголошень для місцевих рекламних ресурсів",
                        "desc": "показуйте свої продукти на Картах Google",
                        "result": "збільшити дохід в режимі офлайн, привернути нових клієнтів у ваш магазин"
                    },
                    "productRatings": {
                        "name": "Feed рейтингу продукту",
                        "desc": "зробіть рейтинги та відгуки про продукти доступними для користувачів Google",
                        "result": "більш високий показник кліків"
                    },
                    "dynamicRemarketing": {
                        "name": "Динамічні канали ремаркетингу",
                        "desc": "показувати рекламу людям, які раніше відвідували ваш веб-сайт",
                        "result": "збільшити дохід"
                    },
                    "page": {
                        "name": "Потік сторінки",
                        "desc": "Канал сторінки для динамічних оголошень у пошуковій мережі",
                        "result": "збільшити ефективність вашої пошукової кампанії"
                    },
                    "sitemap": {
                        "name": "Картка сайту",
                        "desc": "URL-канал для оптимізації пошукової системи",
                        "result": "збільшити видимість у Пошуку Google"
                    },
                    "manufacturer": {
                        "name": "Корми виробника",
                        "desc": "надайте Google кращу та точнішу інформацію про ваші продукти",
                        "result": "отримати комплексну аналітику та покращити свою присутність в Google"
                    }
                }
            },
            "facebook": {
                "title": "facebook",
                "feeds": {
                    "dynamicAds": {
                        "name": "Канал динамічних оголошень",
                        "desc": "показувати рекламу людям, які раніше відвідували ваш веб-сайт",
                        "result": "збільшити дохід"
                    },
                    "pageShop": {
                        "name": "Веб-канал",
                        "desc": "показувати та продавати продукти людям у Facebook",
                        "result": "збільшити дохід"
                    },
                    "marketplace": {
                        "name": "Корм для ринку",
                        "desc": "показувати та продавати продукти людям на Facebook Marketplace",
                        "result": "збільшити дохід"
                    }
                }
            },
            "instagram": {
                "title": "instagram",
                "feeds": {
                    "shopping": {
                        "name": "Канал для покупок",
                        "desc": "показувати та продавати продукти людям в Instagram",
                        "result": "збільшити дохід"
                    }
                }
            },
            "amazon": {
                "title": "амазонка",
                "feeds": {
                    "product": {
                        "name": "Корм для продуктів",
                        "desc": "показувати та продавати продукти людям на Amazon",
                        "result": "збільшити дохід"
                    }
                }
            },
            "ebay": {
                "title": "ebay",
                "feeds": {
                    "product": {
                        "name": "Корм для продуктів",
                        "desc": "показувати та продавати продукти людям на Ebay",
                        "result": "збільшити дохід"
                    }
                }
            },
            "criteo": {
                "title": "крито",
                "feeds": {
                    "product": {
                        "name": "Корм для продуктів",
                        "desc": "продавати продукти в Інтернеті",
                        "result": "збільшити дохід"
                    }
                }
            }
        },
        "cta2": {
            "title": "ПІДТРИМУЙТЕ СВОЇ ПРОДАЖИ З FEED.STUDIO",
            "button": "Отримайте $ 1000 КРЕДИТів",
            "subtitle": "КРЕДИТНА КАРТА НЕ ПОТРІБНА!"
        },
        "meta": {
            "title": "Студія каналів »Інструмент керування каналами XML - Генератор каналів продуктів XML - Покупки Google Feed",
            "desc": {
                "short": "Інструмент генератора каналів Google Shopping Feed.",
                "long": "Генератор каналів Google Shopping Feed"
            }
        }
    },
    "pricing": {
        "plugin": {
            "col1": {
                "header": "ОСОБЛИВОСТІ \/ ПЛАНИ",
                "row1": "Необмежена продукція",
                "row2": "Google Ads - канал покупок",
                "row3": "Google Ads - динамічна стрічка медійних оголошень",
                "row4": "Google Ads - DSA Pagefeed",
                "row5": "Потік продуктів Facebook",
                "row6": "Корм для продуктів Arukereso.hu",
                "row7": "Подача продуктів Criteo",
                "row8": "Подача продуктів RTB House",
                "row9": "Автоматизація оголошень Google",
                "row10": "Спеціальний імпорт та експорт",
                "row11": "Вебінари",
                "row12": "Виділений менеджер облікових записів",
                "row13": "Привітальний ваучер",
                "row14": "Щомісячна плата",
                "row15": "Ціна за 10000 операцій"
            },
            "agency": "Всі ваші гроші повертаються, якщо ми не забезпечимо очікуваних результатів за 3 місяці.",
            "col2": {
                "header": "ЖИТТЯ БЕЗКОШТОВНО",
                "row6": "Щотижня",
                "cta": "Реєстрація",
                "row1": "Вбудована приладова панель",
                "row14": "$ 9",
                "row15": "na"
            },
            "col3": {
                "header": "PREMIUM",
                "row6": "Щодня",
                "cta": "Реєстрація",
                "row1": "Преміальна панель приладів",
                "row13": "300 доларів США",
                "row14": "$ 9",
                "row15": "$ 1"
            },
            "col4": {
                "row6": "Реальний час",
                "cta": "Реєстрація",
                "row1": "VIP панель приладів",
                "row10": "Приватні вебінари",
                "header": "Early Bird Premium",
                "row13": "1000 доларів",
                "row14": "$ 9",
                "row15": "$ 1"
            }
        },
        "agency": {
            "allin": {
                "li6": "Чатбот",
                "li9": "Гарантія повернення грошей*"
            }
        },
        "title": "Плани, які відповідають вашому цілому \n маркетинговий бюджет",
        "sec2": {
            "title": "Домінуйте над своїм Інтернет-зростанням",
            "cta": "ЗВІЛЬНЯТИСЯ"
        },
        "sec6": {
            "title": "Якщо вам потрібна технічна підтримка, відвідайте нашу інформаційну панель підтримки.",
            "cta": "ПІДТРИМКА"
        },
        "header": {
            "title": "ЦІНА",
            "sub": "заплати, якщо ти йдеш"
        }
    },
    "testimonials": {
        "title": "Що кажуть наші користувачі",
        "1": {
            "desc": "Ми вже майже рік працюємо над рекламними кампаніями Google. Ми завжди відчували швидку та професійну роботу від агентства, вони дуже компетентні, співпрацюють і прагнуть повністю задовольнити наші потреби. Крім прийняття інноваційних рішень, вони досить спокійні, щоб глибоко пояснити рішення складних проблем та результати кампаній.",
            "name": "Адам Даллос",
            "pos": "PwC, помічник менеджера, маркетинг, розвиток комунікаційного бізнесу"
        }
    },
    "footer": {
        "description": ""
    },
    "menu": {
        "navigation": "НАВІГАЦІЯ",
        "signin": "Увійти",
        "features": "Особливості",
        "pricing": "Ціноутворення",
        "blog": "Блог",
        "terms-conditions": "Правила та умови",
        "partners": "ПАРТНЕРСТВО",
        "affiliate": "Партнерська компанія",
        "whitelabel": "Біла етикетка",
        "introducing": "Представляємо партнера",
        "findus": "Знайдіть нас",
        "home": "Головна",
        "dashboard": "Панель приладів",
        "contact": "Контактна інформація"
    },
    "features": {
        "title": "Реклама в Google <br\/> стала легшою, ніж будь-коли",
        "subtitle": "",
        "sec1": {
            "title": "Навіщо рекламувати в Google?",
            "col1": {
                "subtitle": "Перемогти свою конкуренцію",
                "desc": "Щодня люди шукають вашу марку чи продукцію, Google надає вам можливість переробити ваш бізнес перед вашою конкуренцією."
            },
            "col2": {
                "subtitle": "Досягніть ідеальних клієнтів",
                "desc": "Google - одна з найкращих рекламних платформ для орієнтації на потрібних користувачів у потрібний час на всіх рівнях взаємодії."
            },
            "col3": {
                "subtitle": "Зростайте свої продажі",
                "desc": "Трафік на ваш веб-сайт значно збільшиться через Google із відвідувачами, які легко перетворяться на клієнтів, зростаючи ваші продажі та Інтернет-бізнес."
            }
        },
        "sec2": {
            "title": "Домінуйте над своїм Інтернет-зростанням",
            "cta": "Спробуй БЕЗКОШТОВНО"
        }
    },
    "getstarted": {
        "title": "Почніть з 3 простих кроків",
        "col1": {
            "subtitle": "1. Завантажити",
            "desc": ""
        },
        "col2": {
            "subtitle": "2. Активуйте",
            "desc": ""
        },
        "col3": {
            "subtitle": "3. Слід",
            "desc": ""
        }
    },
    "dashboard": {
        "title": "Панель приладів",
        "domainsettings": {
            "title": "НАСТРОЙКИ ДОМЕНА",
            "subtitle1": "Додати новий домен",
            "input1": "Ваш новий домен",
            "cta1": "Додайте",
            "subtitle2": "Ваші поточні домени",
            "currentdomains1": "Домен",
            "currentdomains2": "Ключ API"
        },
        "subscription": {
            "title": "ПІДПИСАННЯ",
            "subtitle1": "Баланс",
            "remaining": "що залишився"
        },
        "tab2": {
            "desc1": "Ви зараз використовуєте наше",
            "desc2": "передплати",
            "cta": "ПІДПРИЄМСТВО"
        },
        "billing": {
            "title": "Платіжні дані",
            "input": {
                "name": "Назва рахунку",
                "vat": "ІПН",
                "address": "Адреса",
                "city": "Місто",
                "zip": "ЗІП код",
                "country": "Країна"
            }
        },
        "user": {
            "title": "НАСТРОЙКИ КОРИСТУВАЧА",
            "subtitle": "Ваші дані для входу",
            "lastlogin": "Остання реєстрація:"
        }
    },
    "cta": {
        "update": "ОНОВЛЕННЯ"
    },
    "input": {
        "email": "Адреса електронної пошти",
        "password": "Пароль",
        "passwordrepeat": "Повторіть пароль"
    },
    "partners": {
        "affiliate": {
            "subtitle": "Ексклюзивні бонуси та акції <br> для збільшення філій",
            "substitle2": "Злиття інструментів партнерського маркетингу та платіжних планів для збільшення прибутку!"
        },
        "affilieate": {
            "description": ""
        },
        "whitelabel": {
            "title": "Партнерство White Label"
        },
        "introducing": {
            "subtitle": "",
            "desc2": "За додатковою інформацією звертайтесь до нашої спеціалізованої команди за адресою:"
        }
    },
    "url": {
        "features": "особливості",
        "pricing": "ціноутворення",
        "partnership": {
            "affiliate": "власність \/ партнерство",
            "whitelabel": "паттернство \/ Whitelabel",
            "introductory": "власність \/ вступ"
        },
        "contact": "контактний",
        "login": "вхід"
    },
    "b2c": {
        "main": {
            "title": "<b> FEED <\/b> .MANAGEMENT"
        },
        "cta1": {
            "title": "ПРОМОЦІЯ РАННІХ ПТАРКІВ",
            "description": "Кожен користувач, який користується ранньою пташкою, протягом обмеженого часу отримує 1000 доларів вітання за тестування пакету Feed Studio Premium. <br\/> <br\/> Жоден пробний період, кредитна картка не потрібна.",
            "button": "Отримайте $ 1000 КРЕДИТів"
        },
        "sec1": {
            "title": "як можна використовувати feed.studio",
            "col1": {
                "title": "підпишіться та дайте нам <br\/> ваш сирий корм",
                "desc": "надайте нам свої контактні дані та дані каналу, щоб налаштувати ваш обліковий запис"
            },
            "col2": {
                "title": "наші менеджери налаштували <br\/> ваш канал експорту",
                "desc": "із ваших сировинних кормів ми створюємо ваш експортний канал"
            },
            "col3": {
                "title": "Насолоджуйтесь (результати)",
                "desc": "Будьте зручні, знаючи, що наші експерти наполегливо працюють, щоб доставити вам найкращий корм."
            }
        },
        "sec2": {
            "title": "Підпишіться на раннього тестеру на птахів",
            "infoList": {
                "item1": "Немає обмеження в часі для прийому кредитів",
                "item2": "ми не стягуємо плату з вас в кінці періоду прийому",
                "item3": "Без обмеження на товар чи експорт",
                "item4": "Керований сервіс - вам не потрібно вивчати платформу",
                "item5": "Ви можете будь-коли видалити свій рахунок",
                "item6": "Ви можете запитати власні формати експорту"
            },
            "form": {
                "name": "Ім'я",
                "email": "Електронна пошта",
                "phone": "Телефон",
                "feedUrl": "URL каналу",
                "policyLabel": "Я приймаю Загальні положення та умови",
                "marketingLabel": "Я хотів би підписатись на розсилку новин Feed.Studio",
                "button": "отримати ранній доступ",
                "feedType": ""
            }
        },
        "meta": {
            "title": "Генератор каналів продуктів XML, Покупки Google та канал Facebook",
            "desc": {
                "short": "Створюйте та оптимізуйте свої канали Google та Facebook легко.",
                "long": "Канал Google Shopping Shopping, Оптимізація подачі продуктів."
            }
        }
    },
    "b2b": {
        "main": {
            "title": "<b> FEED <\/b> .ПАРТНЕРИ",
            "description": "Feed.Studio розроблений для електронних комерцій та інших агентств. Ми шукаємо регіональних партнерів, щоб побудувати успішне майбутнє. <\/br> свій дохід, продайте більше за допомогою Feed.Studio!",
            "button": "ПРОДАЙТЕ БІЛЬШЕ"
        },
        "sec1": {
            "title": "Навіщо працювати з нами?",
            "col1": {
                "title": "продати більше",
                "desc": "продати більше"
            },
            "col2": {
                "title": "перемогти своїх <\/br> конкурентів",
                "desc": "бути першим"
            },
            "col3": {
                "title": "задовольнити своїх <\/br> клієнтів",
                "desc": "контроль якості"
            },
            "col4": {
                "title": "економити час",
                "desc": "за допомогою нашої служби з мамою"
            }
        },
        "secPartner": {
            "title": "партнерства",
            "header": {
                "affiliate": "Партнерська компанія",
                "gold": "Золото",
                "exclusive": "Ексклюзивний",
                "custom": "Користувальницькі"
            },
            "requirements": {
                "title": "Вимоги",
                "newClient": "нових клієнтів \/ рік",
                "certifiedusers": "сертифікований активний внутрішній користувач"
            },
            "recognition": {
                "title": "Визнання",
                "visible": "видно на feed.studio",
                "trademark": "права на використання торговельної марки \"студія каналів\""
            },
            "training": {
                "title": "Переваги на навчання",
                "biweekly": "двотижневі зустрічі",
                "salesSupport": "підтримка продажу та керівництво"
            },
            "marketing": {
                "title": "маркетингові переваги",
                "brand": "бренд на місцевих кампаніях",
                "customerStories": "історії успіху клієнтів"
            },
            "sales": {
                "title": "переваги від продажу",
                "materials": "матеріали продажу",
                "accountManagers": "спеціалізований менеджер облікових записів",
                "leads": "місцеві відводи",
                "affiliateBonus": "БОНУС НА НАДІЙНИХ КЛІЄНТІВ",
                "feedStudioBonus": "бонус на Feed Studio"
            },
            "contactUs": "Зв'яжіться з нами",
            "signUp": "Зареєструйтесь"
        },
        "sec3": {
            "title": "як бути нашим партнером?",
            "col1": {
                "title": "підпишіться",
                "desc": ""
            },
            "col2": {
                "title": "брати участь у наших тренінгах з продажів та платформ",
                "desc": ""
            },
            "col3": {
                "title": "керуйте нашими клієнтами та клієнтами",
                "desc": ""
            }
        },
        "sec4": {
            "title": "Підпишіться на ПРОГРАМУ ПАРТНЕРУ",
            "infoList": {
                "item1": "доступ до студії Feed обговорюють стратегії, питання продажу та послуг",
                "item2": "видимість та визнання, зазначивши, як офіційний партнер на сторінці партнерів Feed Studio",
                "item3": "доступ до інформаційної панелі продажів"
            },
            "form": {
                "plan": {
                    "affiliate": "Партнерська компанія",
                    "gold": "Золото",
                    "custom": "Користувальницькі"
                },
                "name": "Ім'я",
                "email": "Електронна пошта",
                "phone": "Телефон",
                "message": "повідомлення",
                "policyLabel": "Я приймаю Загальні положення та умови",
                "marketingLabel": "Я хотів би підписатись на розсилку новин Feed.Studio",
                "button": "Отримайте ранній доступ"
            }
        }
    },
    "registration": {
        "meta": {
            "title": "Зареєструйтесь безкоштовно для студії Feed",
            "desc": {
                "short": "Зареєструйтесь та отримайте кредит у розмірі 1000 доларів на Feed Studio.",
                "long": "Зареєструйтесь та отримайте 1000 доларів привітання, щоб користуватися Feed Studio."
            }
        }
    },
    "earlybirdreg": {
        "form": {
            "infoList": {
                "desc1": "",
                "desc2": "",
                "item1": "",
                "item2": "",
                "item3": "",
                "item4": "",
                "item5": "",
                "item6": "",
                "item7": ""
            }
        }
    },
    "login": {
        "title": "",
        "subtitle": "",
        "subtitle2": "",
        "googleButton": ""
    }
};