export default {
    "home": {
        "sec1": {
            "title": "زيادة الإيرادات الخاصة بك. بدون جهد.",
            "cta": "حمل مجانا",
            "col1": {
                "title": "زيادة أرباحك <br\/>",
                "desc": "تحسين هيكل خلاصتك وزيادة أرباحك بنسبة 5-30٪"
            },
            "col2": {
                "title": "فتح قنوات جديدة للمبيعات <br\/>",
                "desc": "بيع أكثر بقنوات مبيعات جديدة"
            },
            "col3": {
                "title": "جرب مجانا بدون وقت <br\/>",
                "desc": "تمنحك طريقة تسعير الدفع أثناء التنقل الحل الأكثر مرونة في السوق"
            },
            "col4": {
                "title": "وفر وقتك <br\/>",
                "desc": "يعمل مستشارونا على إنشاء خلاصات منتجك وتحسينها"
            }
        },
        "sec2": {
            "title": "الاستيراد والتصدير بكل سهولة",
            "col1": {
                "title": "تغذية الموردين <br\/> الإدارة",
                "desc": "يجمع Feed Studio بين خلاصات المنتجات المختلفة في خلاصة واحدة قابلة للاستيراد"
            },
            "col2": {
                "title": "استخدم أكثر من 100 <br\/> تنسيقات أخرى",
                "desc": "هل تحتاج إلى أوصاف Google Shopping و Instangram مختلفة؟ لا مشكلة"
            },
            "col3": {
                "title": "تحسين بيانات منتجك <br\/> حسب النظام الأساسي",
                "desc": "هل تحتاج إلى وصف تسوق Google مختلف قليلاً عن متجر Instagram؟ ليس هناك أى مشكلة"
            },
            "col4": {
                "title": "تغيير التاريخ",
                "desc": "لماذا الإعلان عن سعر خاطئ؟ متى فشل تحديث المنتج الخاص بك؟ نحن نعرف الجواب!"
            },
            "col5": {
                "title": "تحسين SEO",
                "desc": "إنشاء العلامة الوصفية لتحسين محركات البحث وتحسينها تلقائيًا."
            },
            "col6": {
                "title": "الخدمات المدارة",
                "desc": "يجمع Feed Studio بين خلاصات المنتجات المختلفة في خلاصة واحدة قابلة للاستيراد"
            }
        },
        "sec3": {
            "title": "تريد تنمية عملك دون النضال؟"
        },
        "sec4": {
            "item2": {
                "subtitle": "إدارة الأعلاف"
            },
            "item4": {
                "subtitle": "وحدة تحكم البحث"
            }
        },
        "sec5": {
            "title": "حملات إعلانات Google الآلية بشكل جميل",
            "col1": {
                "title": "إعلانات العلامة التجارية",
                "desc": "هل تعلم أن 40٪ من عملائك لا ينقرون على موقعك في صفحة نتائج البحث؟ الوصول إليهم من خلال حملات العلامة التجارية!"
            },
            "col2": {
                "title": "الإعلانات الديناميكية على شبكة البحث"
            },
            "col3": {
                "title": "إعلانات التسوق"
            },
            "col4": {
                "title": "اعرض الاعلان",
                "desc": "يمكنك الوصول إلى جمهورك المستهدف على شبكة Google الإعلانية من خلال تجديد النشاط التسويقي سريع الاستجابة وتجديد النشاط التسويقي الديناميكي والإعلانات الذكية."
            }
        },
        "sec6": {
            "title": "هل أنت جاهز لأتمتة إعلانك؟",
            "cta": "تحرر"
        },
        "sec7": {
            "title": "",
            "subtitle": "خدمات شاملة حتى في الخطة المجانية"
        },
        "premier-partner-desc": "نحن متميزون من قبل Google كوكالة عالية الأداء ، لذلك يمكنك التأكد من أن إستراتيجيتك في إعلانات Google في أيد أمينة.",
        "sec8": {
            "title": "",
            "cta": "حمل مجانا"
        },
        "main": {
            "title": "<b> علف <\/b> .ME.BABY",
            "description": "اطلب حسابًا مبكرًا للطيور لتجربة أحدث إصدار من برنامج إدارة موجز Google <br\/> المصمم للمحترفين والوكالات. <br\/> لفترة محدودة ستحصل على رصيد ترحيبي بقيمة <s> $ 300 <\/s> 1000 دولار.",
            "button": "احصل على رصيد بقيمة 1،000 دولار"
        },
        "cta1": {
            "title": "الترويج المبكر للطيور",
            "description": "يحصل مستخدمونا الأوائل على رصيد ترحيبي بقيمة 1000 دولار أمريكي لاختبار Feed Studio",
            "button": "احصل على رصيد بقيمة 1،000 دولار",
            "subtitle": "لا حاجة لبطاقة ائتمان!"
        },
        "secFeeds": {
            "title": "ما الذي يجب إصلاحه في خلاصاتك؟",
            "header": {
                "channel": "قناة",
                "export": "تصدير",
                "description": "وصف",
                "result": "نتيجة"
            },
            "google": {
                "title": "جوجل",
                "feeds": {
                    "merchant": {
                        "name": "خلاصة Merchant Center",
                        "desc": "بيع المنتجات على Google Shopping",
                        "result": "زيادة الإيرادات من خلال الهيكل الميداني الأمثل"
                    },
                    "promotions": {
                        "name": "تغذية الترقيات",
                        "desc": "عرض العروض الترويجية لعميلك",
                        "result": "زيادة نسبة النقر إلى الظهور وزيادة التحويلات"
                    },
                    "localInventory": {
                        "name": "خلاصة إعلانات المستودعات المحلية",
                        "desc": "عرض منتجاتك على خرائط Google",
                        "result": "تعزيز الإيرادات في وضع عدم الاتصال بالإنترنت ، وجذب عملاء جدد إلى متجرك"
                    },
                    "productRatings": {
                        "name": "خلاصة تقييمات المنتج",
                        "desc": "جعل تقييمات منتجك ومراجعاته متاحة لمستخدمي Google",
                        "result": "نسبة نقر إلى ظهور أعلى"
                    },
                    "dynamicRemarketing": {
                        "name": "خلاصات تجديد النشاط التسويقي الديناميكي",
                        "desc": "عرض الإعلانات على الأشخاص الذين زاروا موقعك على الويب من قبل",
                        "result": "زيادة الإيرادات"
                    },
                    "page": {
                        "name": "خلاصة الصفحة",
                        "desc": "خلاصة الصفحة للإعلانات الديناميكية على شبكة البحث",
                        "result": "زيادة أداء حملتك على شبكة البحث"
                    },
                    "sitemap": {
                        "name": "خلاصة خريطة الموقع",
                        "desc": "تغذية URL لتحسين محرك البحث",
                        "result": "زيادة ظهورك في بحث Google"
                    },
                    "manufacturer": {
                        "name": "تغذية الشركة المصنعة",
                        "desc": "تقديم معلومات أفضل وأكثر دقة حول منتجاتك إلى Google",
                        "result": "الحصول على تحليلات شاملة وتحسين تواجدك على Google"
                    }
                }
            },
            "facebook": {
                "title": "موقع التواصل الاجتماعي الفيسبوك",
                "feeds": {
                    "dynamicAds": {
                        "name": "خلاصة الإعلانات الديناميكية",
                        "desc": "عرض الإعلانات على الأشخاص الذين زاروا موقعك على الويب من قبل",
                        "result": "زيادة الإيرادات"
                    },
                    "pageShop": {
                        "name": "موجز متجر الصفحات",
                        "desc": "عرض وبيع المنتجات للناس على Facebook",
                        "result": "زيادة الإيرادات"
                    },
                    "marketplace": {
                        "name": "خلاصة السوق",
                        "desc": "عرض المنتجات وبيعها للأشخاص على Facebook Marketplace",
                        "result": "زيادة الإيرادات"
                    }
                }
            },
            "instagram": {
                "title": "الانستقرام",
                "feeds": {
                    "shopping": {
                        "name": "موجز التسوق",
                        "desc": "عرض وبيع المنتجات للناس على انستغرام",
                        "result": "زيادة الإيرادات"
                    }
                }
            },
            "amazon": {
                "title": "أمازون",
                "feeds": {
                    "product": {
                        "name": "تغذية المنتج",
                        "desc": "عرض وبيع المنتجات للناس على موقع أمازون",
                        "result": "زيادة الإيرادات"
                    }
                }
            },
            "ebay": {
                "title": "ايباي",
                "feeds": {
                    "product": {
                        "name": "تغذية المنتج",
                        "desc": "عرض وبيع المنتجات للناس على موقع ئي باي",
                        "result": "زيادة الإيرادات"
                    }
                }
            },
            "criteo": {
                "title": "كريتيو",
                "feeds": {
                    "product": {
                        "name": "تغذية المنتج",
                        "desc": "بيع المنتجات عبر الإنترنت",
                        "result": "زيادة الإيرادات"
                    }
                }
            }
        },
        "cta2": {
            "title": "عزز مبيعاتك مع التغذية",
            "button": "احصل على رصيد بقيمة 1،000 دولار",
            "subtitle": "لا حاجة لبطاقة ائتمان!"
        },
        "meta": {
            "title": "Feed Studio »أداة XML Feed Manager - XML Product Feed Generator - Google Shopping Feed",
            "desc": {
                "short": "أداة Google Feed Feed منشئ.",
                "long": "منشئ تغذية جوجل للتسوق"
            }
        }
    },
    "pricing": {
        "plugin": {
            "col1": {
                "header": "الميزات \/ الخطط",
                "row1": "منتجات غير محدودة",
                "row2": "إعلانات Google - خلاصة التسوق",
                "row3": "إعلانات Google - خلاصة الإعلانات الديناميكية على الشبكة الإعلانية",
                "row4": "إعلانات Google - DSA Pagefeed",
                "row5": "موجز منتج Facebook",
                "row6": "Arukereso.hu تغذية المنتج",
                "row7": "موجز منتج Criteo",
                "row8": "موجز منتج RTB House",
                "row9": "أتمتة إعلانات Google",
                "row10": "الواردات والصادرات المخصصة",
                "row11": "ندوات عبر الإنترنت",
                "row12": "مدير حساب مخصص",
                "row13": "قسيمة ترحيب",
                "row14": "رسوم شهرية",
                "row15": "السعر لكل 10000 عملية"
            },
            "agency": "كل أموالك تعود إذا لم نقدم النتائج المتوقعة في 3 أشهر.",
            "col2": {
                "header": "مجانية مدى الحياة",
                "row6": "أسبوعي",
                "cta": "التسجيل",
                "row1": "لوحة تحكم مدمجة",
                "row14": "9 دولارات",
                "row15": "غ"
            },
            "col3": {
                "header": "الممتازة",
                "row6": "اليومي",
                "cta": "التسجيل",
                "row1": "لوحة القيادة المميزة",
                "row13": "300 دولار",
                "row14": "9 دولارات",
                "row15": "$ 1"
            },
            "col4": {
                "row6": "في الوقت الحالى",
                "cta": "التسجيل",
                "row1": "لوحة القيادة لكبار الشخصيات",
                "row10": "ندوات خاصة",
                "header": "ايرلي بيرد بريميوم",
                "row13": "1000 دولار",
                "row14": "9 دولارات",
                "row15": "$ 1"
            }
        },
        "agency": {
            "allin": {
                "li6": "Chatbot",
                "li9": "ضمان استعادة الاموال*"
            }
        },
        "title": "الخطط التي تناسبك بالكامل \n ميزانية التسويق",
        "sec2": {
            "title": "تحكم في نموك عبر الإنترنت",
            "cta": "تحرر"
        },
        "sec6": {
            "title": "إذا كنت بحاجة إلى دعم فني ، فقم بزيارة لوحة الدعم الخاصة بنا.",
            "cta": "الدعم"
        },
        "header": {
            "title": "التسعير",
            "sub": "ادفع كما تذهب"
        }
    },
    "testimonials": {
        "title": "ماذا يقول مستخدمينا",
        "1": {
            "desc": "نحن نعمل معًا في حملات إعلانات Google منذ عام تقريبًا. لقد شهدنا دائمًا عملًا سريعًا ومهنيًا من الوكالة ، وهم أكفاء للغاية ومتعاونون ويتطلعون إلى تلبية احتياجاتنا بالكامل. بالإضافة إلى تقديم حلول مبتكرة ، فهي هادئة بما يكفي لتشرح بعمق الحلول للمشكلات المعقدة ونتائج المخيمات.",
            "name": "آدم دالوس",
            "pos": "برايس ووتر هاوس كوبرز ، مساعد مدير التسويق ، تطوير أعمال الاتصالات"
        }
    },
    "footer": {
        "description": ""
    },
    "menu": {
        "navigation": "التنقل",
        "signin": "تسجيل الدخول",
        "features": "الميزات",
        "pricing": "التسعير",
        "blog": "مدونة",
        "terms-conditions": "البنود و الظروف",
        "partners": "شراكة",
        "affiliate": "انضم",
        "whitelabel": "البطاقة البيضاء",
        "introducing": "الشريك المعرف",
        "findus": "تابعنا على",
        "home": "الصفحة الرئيسية",
        "dashboard": "لوحة القيادة",
        "contact": "اتصل"
    },
    "features": {
        "title": "أصبح الإعلان على Google <br\/> أسهل من أي وقت مضى",
        "subtitle": "",
        "sec1": {
            "title": "لماذا الإعلان على جوجل؟",
            "col1": {
                "subtitle": "تغلب على منافسيك",
                "desc": "في كل يوم يبحث الأشخاص عن علامتك التجارية أو منتجاتك ، تمنحك Google القدرة على عرض أعمالك أمام منافسيك."
            },
            "col2": {
                "subtitle": "الوصول إلى العملاء المثاليين",
                "desc": "تعد Google واحدة من أفضل المنصات الإعلانية لاستهداف المستخدمين المناسبين في الوقت المناسب عبر جميع مستويات المشاركة."
            },
            "col3": {
                "subtitle": "زيادة مبيعاتك",
                "desc": "سيزداد عدد الزيارات إلى موقعك على الويب بشكل كبير من خلال Google مع الزائرين الذين يمكن أن يتحولوا بسهولة إلى عملاء ، مما يزيد من مبيعاتك وأعمالك عبر الإنترنت."
            }
        },
        "sec2": {
            "title": "تحكم في نموك عبر الإنترنت",
            "cta": "حاول مجانا"
        }
    },
    "getstarted": {
        "title": "ابدأ بثلاث خطوات سهلة",
        "col1": {
            "subtitle": "1. تنزيل",
            "desc": ""
        },
        "col2": {
            "subtitle": "2. تنشيط",
            "desc": ""
        },
        "col3": {
            "subtitle": "3. المسار",
            "desc": ""
        }
    },
    "dashboard": {
        "title": "لوحة القيادة",
        "domainsettings": {
            "title": "إعدادات النطاق",
            "subtitle1": "إضافة مجال جديد",
            "input1": "المجال الجديد الخاص بك",
            "cta1": "أضف",
            "subtitle2": "المجالات الحالية الخاصة بك",
            "currentdomains1": "نطاق",
            "currentdomains2": "مفتاح API"
        },
        "subscription": {
            "title": "الالتحاق",
            "subtitle1": "توازن",
            "remaining": "المتبقي"
        },
        "tab2": {
            "desc1": "أنت تستخدم حاليا لدينا",
            "desc2": "اشتراك",
            "cta": "الالتحاق التبديل"
        },
        "billing": {
            "title": "تفاصيل الفاتورة",
            "input": {
                "name": "اسم الفاتورة",
                "vat": "ظريبه الشراء",
                "address": "عنوان",
                "city": "مدينة",
                "zip": "الرمز البريدي",
                "country": "بلد"
            }
        },
        "user": {
            "title": "إعدادات المستخدم",
            "subtitle": "تفاصيل تسجيل الدخول الخاصة بك",
            "lastlogin": "آخر تسجيل دخول:"
        }
    },
    "cta": {
        "update": "تحديث"
    },
    "input": {
        "email": "عنوان بريد الكتروني",
        "password": "كلمه السر",
        "passwordrepeat": "اعد كلمة السر"
    },
    "partners": {
        "affiliate": {
            "subtitle": "مكافآت وترقيات حصرية <br> لتعزيز الشركات التابعة",
            "substitle2": "مزيج من أدوات التسويق التابعة وخطط الدفع لزيادة أرباحك!"
        },
        "affilieate": {
            "description": ""
        },
        "whitelabel": {
            "title": "شراكة العلامة البيضاء"
        },
        "introducing": {
            "subtitle": "",
            "desc2": "لمزيد من المعلومات ، يرجى الاتصال بفريقنا المتخصص على:"
        }
    },
    "url": {
        "features": "الميزات",
        "pricing": "التسعير",
        "partnership": {
            "affiliate": "براءة \/ منتسب",
            "whitelabel": "براءة اختراع \/ whitelabel",
            "introductory": "براءات الاختراع \/ تمهيدي"
        },
        "contact": "اتصل",
        "login": "تسجيل الدخول"
    },
    "b2c": {
        "main": {
            "title": "<b> <\/b> تغذية <\/b> MANAGEMENT"
        },
        "cta1": {
            "title": "الترويج المبكر للطيور",
            "description": "لفترة محدودة ، يحصل كل مستخدم طائر مبكر على رصيد ترحيبي بقيمة 1000 دولار أمريكي لاختبار حزمة Feed Studio Premium. <br\/> <br\/> لا توجد فترة تجريبية ، ولا حاجة لبطاقة ائتمان.",
            "button": "احصل على رصيد بقيمة 1،000 دولار"
        },
        "sec1": {
            "title": "كيف يمكنك استخدام feed.studio",
            "col1": {
                "title": "اشترك <br\/> خلاصتك الخام",
                "desc": "قدم لنا بيانات الاتصال والخلاصة لإعداد حسابك"
            },
            "col2": {
                "title": "قام مدراؤنا بإعداد <br\/> موجز التصدير الخاص بك",
                "desc": "من خلاصتك الأولية ، نقوم بإنشاء خلاصة التصدير الخاصة بك"
            },
            "col3": {
                "title": "استمتع (النتائج)",
                "desc": "استرخِ مع العلم أن خبرائنا يعملون بجد لتقديم أفضل تغذية تحتاجها."
            }
        },
        "sec2": {
            "title": "قم بالتسجيل في اختبار الطيور المبكر",
            "infoList": {
                "item1": "لا يوجد حد زمني للائتمان الترحيبي",
                "item2": "نحن لا نفرض رسوم \/ نرسل لك فاتورة في نهاية فترة الترحيب",
                "item3": "لا يوجد حد المنتج أو التصدير",
                "item4": "الخدمة المدارة - ليس عليك تعلم المنصة",
                "item5": "يمكنك حذف حسابك في أي وقت",
                "item6": "يمكنك طلب تنسيقات تصدير مخصصة"
            },
            "form": {
                "name": "اسم",
                "email": "البريد الإلكتروني",
                "phone": "هاتف",
                "feedUrl": "URL تغذية",
                "policyLabel": "أوافق على الشروط والأحكام",
                "marketingLabel": "أود الاشتراك في النشرة الإخبارية Feed.Studio",
                "button": "الوصول المبكر",
                "feedType": ""
            }
        },
        "meta": {
            "title": "مولد منتج XML ، Google Shopping و Facebook Feed",
            "desc": {
                "short": "يمكنك إنشاء خلاصات Google و Facebook وتحسينها بسهولة.",
                "long": "خلاصة Google للتسوق ، تحسين خلاصة المنتج."
            }
        }
    },
    "b2b": {
        "main": {
            "title": "<b> FEED <\/b> .PARTNERS",
            "description": "تم تصميم Feed.Studio لمحترفي ووكالات التجارة الإلكترونية. نحن نبحث عن شركاء إقليميين لبناء مستقبل ناجح. <\/br> عزز أرباحك ، وقم ببيع المزيد مع Feed.Studio!",
            "button": "بيع أكثر"
        },
        "sec1": {
            "title": "لماذا تعمل معنا؟",
            "col1": {
                "title": "بيع أكثر",
                "desc": "بيع أكثر"
            },
            "col2": {
                "title": "تغلب على <\/br> منافسيك",
                "desc": "كن الأول"
            },
            "col3": {
                "title": "إرضاء عملائك <\/br>",
                "desc": "رقابة جودة"
            },
            "col4": {
                "title": "وفر الوقت",
                "desc": "مع خدمتنا التالفة"
            }
        },
        "secPartner": {
            "title": "الشراكه",
            "header": {
                "affiliate": "انضم",
                "gold": "ذهب",
                "exclusive": "حصرية",
                "custom": "مخصص"
            },
            "requirements": {
                "title": "المتطلبات",
                "newClient": "عملاء جدد \/ سنة",
                "certifiedusers": "مستخدم داخلي نشط معتمد"
            },
            "recognition": {
                "title": "التعرف على",
                "visible": "مرئية على feed.studio",
                "trademark": "حقوق استخدام العلامة التجارية \"feed studio\""
            },
            "training": {
                "title": "فوائد التدريب",
                "biweekly": "اجتماعات نصف أسبوعية",
                "salesSupport": "دعم المبيعات والتوجيه"
            },
            "marketing": {
                "title": "فوائد التسويق",
                "brand": "العلامة التجارية على الحملات المحلية",
                "customerStories": "قصص نجاح العملاء"
            },
            "sales": {
                "title": "فوائد البيع",
                "materials": "مواد البيع",
                "accountManagers": "مدير حساب مخصص",
                "leads": "الخيوط المحلية",
                "affiliateBonus": "مكافأة على العملاء التابعين",
                "feedStudioBonus": "مكافأة على Feed Studio"
            },
            "contactUs": "اتصل بنا",
            "signUp": "سجل"
        },
        "sec3": {
            "title": "كيف تكون شريكنا؟",
            "col1": {
                "title": "سجل",
                "desc": ""
            },
            "col2": {
                "title": "شارك في دوراتنا التدريبية الخاصة بالمبيعات والمنصات",
                "desc": ""
            },
            "col3": {
                "title": "إدارة العملاء المحتملين والعملاء",
                "desc": ""
            }
        },
        "sec4": {
            "title": "قم بالتسجيل في برنامج بارتنر",
            "infoList": {
                "item1": "الوصول إلى Feed Studio لمناقشة الاستراتيجيات والمبيعات والخدمات",
                "item2": "الرؤية والاعتراف من خلال كونك مدرجًا كشريك رسمي في صفحة شركاء Feed Studio",
                "item3": "الوصول إلى لوحة معلومات المبيعات"
            },
            "form": {
                "plan": {
                    "affiliate": "انضم",
                    "gold": "ذهب",
                    "custom": "مخصص"
                },
                "name": "اسم",
                "email": "البريد الإلكتروني",
                "phone": "هاتف",
                "message": "رسالة",
                "policyLabel": "أوافق على الشروط والأحكام",
                "marketingLabel": "أود الاشتراك في النشرة الإخبارية Feed.Studio",
                "button": "احصل على الوصول المبكر"
            }
        }
    },
    "registration": {
        "meta": {
            "title": "سجل مجانًا لتغذية الاستوديو",
            "desc": {
                "short": "اشترك واحصل على رصيد بقيمة 1000 دولار لـ Feed Studio.",
                "long": "اشترك واحصل على 1000 دولار ترحيبي لاستخدام Feed Studio."
            }
        }
    },
    "earlybirdreg": {
        "form": {
            "infoList": {
                "desc1": "",
                "desc2": "",
                "item1": "",
                "item2": "",
                "item3": "",
                "item4": "",
                "item5": "",
                "item6": "",
                "item7": ""
            }
        }
    },
    "login": {
        "title": "",
        "subtitle": "",
        "subtitle2": "",
        "googleButton": ""
    }
};