import { types } from 'redux/actions';

const initialState = {
    loading: true,
    user: null,
    selectedProject: {id: -1},
    projects: [],
    projectsLoaded: false,
    selectedCampaign: null,
    campaigns: [],
    campaignsLoaded: false
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case types.SIGN_IN:
            return Object.assign({}, state, {
                loading: true
            });
        case types.SIGN_IN_SUCCESSFUL:
            return Object.assign({}, state, {
                user: action.user,
                loading: false
            })
            case types.SIGN_IN_FAILED:
                return Object.assign({}, state, {
                    user: null,
                    loading: false
                })
        case types.SELECT_PROJECT:
            return Object.assign({}, state, {
                selectedProject: action.project,
                selectedCatalogue: null,
                cataloguesLoaded: false,
            })
        case types.REFRESH_USER:
            return Object.assign({}, state, {
                user: action.user,
                loading: false
            })
        case types.REFRESH_PROJECTS:
            let selectedProject = state.selectedProject
            const refreshedSelectedProject = action.projects.find((p) => p.id === selectedProject.id)
            if(refreshedSelectedProject !== undefined) selectedProject = refreshedSelectedProject

            return Object.assign({}, state, {
                projects: action.projects,
                projectsLoaded: true,
                selectedProject: selectedProject
            })
        case types.FETCH_CAMPAIGNS:
            return Object.assign({}, state, {
                selectedCampaign: null,
                campaigns: action.campaigns,
                campaignsLoaded: true
            })
        case types.SELECT_CAMPAIGNS:
            return Object.assign({}, state, {
                selectedCampaign: action.campaign
            })

        default:
            return state;
    };
}

export default rootReducer;